import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { AppState, FlatList, View } from "react-native"
import { CButton, CLoadingSpinner, CPressable, CText, CTextInput } from "../../../components"
import { LocalizationContext } from "../../../contexts/LocalizationContext"
import { useCTheme } from "../../../hooks/useTheme"
import Feather from '@expo/vector-icons/Feather'
import Octicons from '@expo/vector-icons/Octicons'
import Entypo from '@expo/vector-icons/Entypo'
import AntDesign from '@expo/vector-icons/AntDesign'
import Ionicons from '@expo/vector-icons/Ionicons'
import { QuestionComponent, formatInputToNumber, formatNumberToInput, numberRegex } from "./question"
import { getTipoCasillaAPI, loginAeatAPI, requestFiscalDataAPI, requestPinAPI } from "../../../api/fiscal-data"
import { useAuthStore } from "../../../stores/auth"
import { showMessage } from "react-native-flash-message"
import dayjs from "dayjs"
import * as Clipboard from 'expo-clipboard'
import * as WebBrowser from 'expo-web-browser';

type FiscalRegisterTypeProps = 'pin' | 'reference' | 'casilla'
type FiscalRegisterClavePinMethod = 'app' | 'sms'
type FiscalRegisterCasillaType = '505' | 'iban'

const fiscalRegisterBoxes = [
	{
		id: 'pin',
		title: 'fiscal_register_pin',
	},
	{
		id: 'reference',
		title: 'fiscal_register_reference',
	},
	{
		id: 'casilla',
		title: 'fiscal_register_casilla',
	},
	{
		id: 'spacer',
		title: '',
	}
]

const FiscalRegisterType = ({
	selectedType,
	setSelectedType
}: {
	selectedType: FiscalRegisterTypeProps,
	setSelectedType: (type: FiscalRegisterTypeProps) => void
}) => {
	const theme = useCTheme()
	const { t } = useContext(LocalizationContext)

	return (
		<View style={{
			flex: 1,
			gap: 10,
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
			flexWrap: 'wrap',
		}}>
			<FlatList
				style = {{
					maxWidth: 350,
				}}
				contentContainerStyle={{
					padding: 15,
				}}
				data={fiscalRegisterBoxes}
				numColumns={2}
				ItemSeparatorComponent={() => <View style={{height: 20}}/>}
				renderItem={({item, index}) => {
					const selected = selectedType === item.id

					if (item.id === 'spacer') {
						return (
							<View style={{
								flex: 1,
								marginHorizontal: 7.5,
							}}/>
						)
					}
					return (
						<View style={{
							flex: 1,
							marginHorizontal: 7.5,
						}}>
							<CPressable onPress={() => setSelectedType(item.id as FiscalRegisterTypeProps)} key={item.title}>
								<View style={{
									backgroundColor: theme.colors.card,
									padding: 10,
									borderRadius: 15,
									borderWidth: 3,
									borderColor: selected ? theme.appColors.iosBlue : theme.colors.card,
									...(selected ? theme.appShadows.card : {}),
									...theme.appShadows.card,
									aspectRatio: 1,
									alignItems: 'center',

								}}>
									<View style={{ flex: 1, justifyContent: 'center'}}>
										{
											item.id === 'pin' && <Feather name="at-sign" size={65} color={
												selected ? theme.appColors.iosBlue : theme.colors.text
											} />
										}
										{
											item.id === 'reference' && <Octicons name="number" size={55} color={
												selected ? theme.appColors.iosBlue : theme.colors.text
											} />
										}
										{
											item.id === 'casilla' && <Entypo name="documents" size={55} color={
												selected ? theme.appColors.iosBlue : theme.colors.text
											} />
										}
									</View>
									<CText style={{
										fontSize: 14,
										textAlign: 'center',
										color: selected ? theme.appColors.iosBlue: theme.colors.text,
										fontWeight: selected ? 'bold' : 'normal'
									}}>{t(`${item.title}`)}</CText>
								</View>
							</CPressable>	
						</View>
					)
				}}
				keyExtractor={(item) => item.id}/>
		</View>
	)
}

const clavePinMethods = [
	{
		id: 'app',
		title: 'fiscal_register_clave_app',
	},
	{
		id: 'sms',
		title: 'fiscal_register_clave_sms',
	}
]

const ClavePin = ({
	method,
	setMethod,
	pin,
	setPin,
	yearId,
	yearDraftId,
	code,
	setCode
}: {
	method: FiscalRegisterClavePinMethod,
	setMethod: (method: FiscalRegisterClavePinMethod) => void
	pin: string,
	setPin: (pin: string) => void
	yearId: string,
	yearDraftId: string,
	code: string,
	setCode: (code: string) => void
}) => {
	const theme = useCTheme()
	const { t } = useContext(LocalizationContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [lastPinTimestamp, setLastPinTimestamp] = useState<number | null>(null)
	const [remainingTime, setRemainingTime] = useState<string>('')
	const authStore = useAuthStore()

	useEffect(() => {
		// Permitir enviar un nuevo pin después de 10 minutos
		if(!lastPinTimestamp) {
			return
		}
		const interval = setInterval(() => {
			if (lastPinTimestamp) {
				const currentTime = dayjs()
				const lastPinTime = dayjs(lastPinTimestamp)
				const diff = currentTime.diff(lastPinTime, 'seconds')
				const remaining = 600 - diff
				if (remaining <= 0) {
					setLastPinTimestamp(null)
					setRemainingTime('')
				} else {
					const minutes = Math.floor(remaining / 60)
					const seconds = remaining % 60
					const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
					setRemainingTime(`${minutes}:${formattedSeconds}`)
				}
			}
		}, 1000)

		return () => clearInterval(interval)
	}, [lastPinTimestamp])

	useEffect(() => {
		if (!code) {
			setLastPinTimestamp(null)
			return
		}
	}, [code])

	const requestPin = () => {
		setLoading(true)
		requestPinAPI(authStore)(yearId, yearDraftId, method).then((res) => {
		//Promise.resolve().then((res) => {
			setLastPinTimestamp(new Date().getTime())
			setCode(res.code)
			showMessage({
				message: t('fiscal_register_clave_pin_sent'),
				type: 'success'
			})
		}).catch((error) => {
			showMessage({
				message: error.message,
				type: 'danger'
			})
		}).finally(() => {
			setLoading(false)
		})
	}

	return (
		<>
			<View style={{
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 30,
			}}>
				<CText style={{
					fontSize: 18,
					fontWeight: '600',
					marginTop: 10,
					textAlign: 'center'
				}}>{t('fiscal_register_clave_pin_form')}</CText>
				<View style={{
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'center',
					gap: 10,
					maxWidth: 350,
					padding: 15
				}}>
					{clavePinMethods.map((item) => {
						const selected = method === item.id
						return (
							<CPressable onPress={() => setMethod(item.id as FiscalRegisterClavePinMethod)} key={item.id}>
								<View style={{
									backgroundColor: theme.colors.card,
											paddingHorizontal: 15,
											paddingVertical: 10,
											borderRadius: 15,
											borderWidth: 3,
											borderColor: selected ? theme.appColors.iosBlue : theme.colors.card,
											...(selected ? theme.appShadows.card : {}),
											...theme.appShadows.card,
											alignItems: 'center',
											flex: 1,
											flexDirection: 'row',
											justifyContent: 'center',
											gap: 10,
								}}>
									{
										item.id === 'sms' && <AntDesign name={'message1'} size={24} color={
											selected ? theme.appColors.iosBlue : theme.colors.text
										} />
									}
									{
										item.id === 'app' && <Ionicons name={'phone-portrait-outline'} size={24} color={
											selected ? theme.appColors.iosBlue : theme.colors.text
										} />
									}
									<CText style={{
										fontSize: 16,
										textAlign: 'center',
										color: selected ? theme.appColors.iosBlue: theme.colors.text,
										fontWeight: selected ? 'bold' : 'normal'
									}}>{t(`${item.title}`)}</CText>
								</View>
							</CPressable>
						)
					})}
				</View>
			</View>
			<View style={{
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 30,
				gap: 15,
				display: !!lastPinTimestamp ? 'flex' : 'none'
			}}>
				<CText style={{
					fontSize: 18,
					fontWeight: '600',
					marginTop: 10,
					textAlign: 'center'
				}}>{t('fiscal_register_clave_enter_pin')}</CText>
				<CTextInput
					style={{
						textAlign: 'center',
						fontSize: 24,
						fontWeight: '600',
						maxWidth: 200,
					}}
					keyboardType='default'
					placeholder={t('fiscal_register_clave_enter_pin_placeholder')}
					onChangeText={(text) => {
						const formatted = text.toUpperCase()
						if (formatted.length > 3) {
							setPin(formatted.slice(0, 3))
							return
						}

						setPin(formatted)
					}}
					value={pin}/>
			</View>
			<View style={{
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 30,
				gap: 15,
			}}>
				{!!remainingTime && (
					<CText style={{
						fontSize: 16,
						fontWeight: '300',
						textAlign: 'center',
						color: theme.colors.text
					}}>{t('fiscal_register_clave_remaining_time', {time: remainingTime})}</CText>
				)}
				<CButton disabled={loading || !!lastPinTimestamp} loading={loading} variant={!!lastPinTimestamp ? 'outline' : 'filled'} color={theme.appColors.primary1} textColor={!!lastPinTimestamp ? theme.colors.text : theme.appColors.textWithBackground} onPress={() => {
					requestPin()
				}} >{t('fiscal_register_clave_request')}</CButton>
			</View>
		</>
	)
}


const Referencia = ({
	referencia,
	setReferencia
}: {
	referencia: string,
	setReferencia: (referencia: string) => void
}) => {
	const {t} = useContext(LocalizationContext)

	return (
		<View style={{
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 30,
			gap: 15,
		}}>
			<CText style={{
				fontSize: 18,
				fontWeight: '600',
				marginTop: 10,
				textAlign: 'center'
			}}>{t('fiscal_register_reference_form')}</CText>
			<CTextInput
				style={{
					textAlign: 'center',
					fontSize: 24,
					fontWeight: '600',
					maxWidth: 200,
				}}
				keyboardType='default'
				placeholder={t('fiscal_register_reference_placeholder')}
				onChangeText={(text) => {
					const formatted = text.toUpperCase()
					if (formatted.length > 6) {
						setReferencia(formatted.slice(0, 6))
						return
					}

					setReferencia(formatted)
				}}
				value={referencia}/>
		</View>
	)
}

const Casilla = ({
	yearId,
	yearDraftId,
	casilla,
	setCasilla,
	tipoCasilla,
	setTipoCasilla
}: {
	yearId: string,
	yearDraftId: string,
	casilla: string,
	setCasilla: (casilla: string) => void
	tipoCasilla?: FiscalRegisterCasillaType,
	setTipoCasilla: (tipoCasilla: FiscalRegisterCasillaType) => void
}) => {
	const {t} = useContext(LocalizationContext)
	const theme = useCTheme()
	const authStore = useAuthStore()
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>('')

	const getTipoCasilla = () => {
		setLoading(true)
		getTipoCasillaAPI(authStore)(yearId, yearDraftId).then((res) => {
			setTipoCasilla(res.tipoCasilla)
		}).catch((error) => {
			showMessage({
				message: error.message,
				type: 'danger'
			})
		}).finally(() => {
			setLoading(false)
		})
	}

	useEffect(() => {
		getTipoCasilla()
	}, [])

	return (
		<View style={{
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 30,
			gap: 15,
		}}>
			<CText style={{
						fontSize: 18,
						fontWeight: '600',
						marginTop: 10,
						textAlign: 'center'
					}}>{
						t('fiscal_register_casilla_form')
					}</CText>
			{loading && (
				<CLoadingSpinner/>
			)}
			{!!tipoCasilla && (
				<>
					<CText style={{
						fontSize: 14,
						fontWeight: '300',
						marginTop: 10,
						textAlign: 'center',
						marginHorizontal: 20,
						lineHeight: 24
					}}>{
						tipoCasilla === '505' ? t('fiscal_register_casilla_detected_505') : t('fiscal_register_casilla_detected_iban')
					}</CText>
					<CTextInput
					style={{
						textAlign: 'center',
						fontSize: 24,
						fontWeight: '600',
						maxWidth: 200,
					}}
					errors={error ? [error] : []}
					keyboardType='decimal-pad'
					placeholder={tipoCasilla === '505' ? t('fiscal_register_casilla_505_placeholder') : t('fiscal_register_casilla_iban_placeholder')}
					onChangeText={(text) => {
						if (tipoCasilla === '505') {
							const formatedText = formatInputToNumber(text, true)
							const isNumber = numberRegex.test(formatedText)
							if (!isNumber && formatedText !== '') {
								setCasilla(formatedText)
								setError(t('no_valid_number'))
								return
							} 
							setCasilla(formatedText)
							setError(null)
						} else {
							const formatted = text.toUpperCase().replace(/[^0-9]/g, '')
							if (formatted.length > 5) {
								setCasilla(formatted.slice(0, 5))
								return
							}

							setCasilla(formatted)
						}
					}}
					value={formatNumberToInput(casilla)}/>
				</>
			)}
		</View>
	)
}

const LoginScreen = forwardRef(({
	loading,
	setLoading,
	loginAeat,
	hasBackButton,
	onBack,
	yearId,
	yearDraftId,
	showAttemptsWarning,
}: {
	loading: boolean,
	setLoading: (loading: boolean) => void,
	showAttemptsWarning: boolean,
	loginAeat: (data: {
		selectedType: FiscalRegisterTypeProps,
		pin: string,
		code: string,
		reference: string,
		casilla: string,
		tipoCasilla?: FiscalRegisterCasillaType
	}) => void,
	hasBackButton: boolean,
	onNext: () => void,
	onBack: () => void,
	yearId: string,
	yearDraftId: string,
}, ref) => {
	const {t} = useContext(LocalizationContext)
	const theme = useCTheme()
	const [selectedType, setSelectedType] = useState<FiscalRegisterTypeProps>('pin')
	const [clavePinMethod, setClavePinMethod] = useState<FiscalRegisterClavePinMethod>('app')
	const [pin, setPin] = useState<string>('')
	const [code, setCode] = useState<string>('')
	const [reference, setReference] = useState<string>('')
	const [tipoCasilla, setTipoCasilla] = useState<FiscalRegisterCasillaType>()
	const [casilla, setCasilla] = useState<string>('')

	const isFormComplete = (selectedType === 'pin' && clavePinMethod && pin.length === 3 && !!code) || (selectedType === 'reference' && reference) || (selectedType === 'casilla' && casilla)

	useImperativeHandle(ref, () => ({
		resetTemporaryData: () => {
			setCode('')
			setPin('')
		}
	}))


	return (
		<View style={{
			flex: 1,
			padding: 20,
			justifyContent: 'center'
		
		}}>
			<CText style={{
				fontSize: 24,
				fontWeight: '600',
				marginTop: 10,
				textAlign: 'center'
			}}>{t('fiscal_data_form')}</CText>
			<CText style={{
				fontSize: 16,
				fontWeight: '300',
				marginTop: 10,
				textAlign: 'center',
				marginHorizontal: 20,
				lineHeight: 24
			}}>{t('fiscal_data_form_description')}</CText>
			<FiscalRegisterType selectedType={selectedType} setSelectedType={setSelectedType}/>
			{selectedType === 'pin' && <ClavePin method={clavePinMethod} setMethod={setClavePinMethod} pin={pin} setPin={setPin} yearId={yearId} yearDraftId={yearDraftId} code={code} setCode={setCode}/>}
			{selectedType === 'reference' && <Referencia referencia={reference} setReferencia={setReference}/>}
			{selectedType === 'casilla' && <Casilla yearId={yearId} yearDraftId={yearDraftId} casilla={casilla} setCasilla={setCasilla} tipoCasilla={tipoCasilla} setTipoCasilla={setTipoCasilla}/>}
			{showAttemptsWarning && (
				<View style={{
					marginHorizontal: 30,
					marginTop: 20,
					marginBottom: 10,
					borderRadius: 10,
					padding: 10,
					gap: 10,
					alignItems: 'center',
					backgroundColor: theme.appColors.warning,
					...theme.appShadows.card,
					flexDirection: 'row',
				}}>
					<AntDesign name="warning" size={24} color={theme.appColors.textWithBackground} />
					<CText style={{
						color: theme.appColors.textWithBackground,
						fontSize: 16,
					}}>{t('fiscal_data_attempts_warning')}</CText>
				</View>
			)}
			<View style={{
				marginTop: 10
			}}>
				<View style={{
					flexDirection: 'row',
					marginTop: 10,
					marginHorizontal: 30,
					justifyContent: 'center',
					alignItems: 'flex-end',
					marginBottom: 20,
				}}>
					<View style={{
						flex: 1
					}}/>
					{ hasBackButton && (<CButton variant='filled' onPress={() => { onBack() }}>{
						t('previous')
					}</CButton>)}
					<View style={{
						width: 20
					}}/>
					<CButton disabled={!isFormComplete || loading} loading={loading} variant='filled' color={theme.appColors.primary1} textColor={theme.appColors.textWithBackground} onPress={() => {
						loginAeat({
							selectedType,
							pin,
							code,
							reference,
							casilla,
							tipoCasilla
						})
					}}>{t('next')}</CButton>
				</View> 
			</View>
		</View>
	)
})

export const RatifyScreen = ({
	loading,
	referenceNumber,
	requestFiscalData
}: {
	loading: boolean
	referenceNumber: string
	requestFiscalData: () => Promise<void>
}) => {
	const {t} = useContext(LocalizationContext)
	const theme = useCTheme()
	const [clicked, setClicked] = useState<boolean>(false)

	return (
		<View style={{
			flex: 1,
			padding: 20,
			justifyContent: 'center',
		}}>
			<CText style={{
				fontSize: 24,
				fontWeight: '600',
				marginTop: 10,
				textAlign: 'center'
			}}>{t('ratify_address_form')}</CText>
			<CText style={{
				fontSize: 16,
				fontWeight: '300',
				marginTop: 10,
				textAlign: 'center',
				marginHorizontal: 20,
				lineHeight: 24
			}}>{t('ratify_address_form_description')}</CText>
			<CText style={{
				fontSize: 16,
				fontWeight: '300',
				marginTop: 20,
				textAlign: 'center',
				marginHorizontal: 20,
				lineHeight: 24
			}}>{t('ratify_address_form_description2')}</CText>
			<CText style={{
				fontSize: 34,
				fontWeight: '600',
				marginTop: 30,
				textAlign: 'center'
			}} selectable>{referenceNumber}</CText>
			<View style={{
				flexDirection: 'column',
				marginTop: 20,
				marginHorizontal: 30,
				justifyContent: 'center',
				alignItems: 'center',
				marginBottom: 20,
				gap: 20
			}}>
				<CButton disabled={loading} loading={loading} variant='filled' color={theme.appColors.primary1} textColor={theme.appColors.textWithBackground} onPress={() => {
					Clipboard.setStringAsync(referenceNumber).then(() => {
						showMessage({
							message: t('copied_to_clipboard'),
							type: 'success',
							icon: 'success'
						})

						WebBrowser.openBrowserAsync('https://www2.agenciatributaria.gob.es/wlpl/DABJ-REN0/ValidacionReferenciaServlet?ref=%2Fwlpl%2FDFPA-D182%2FSvVisDF23Net')
					}).catch(() => {})
					setClicked(true)
				}}>{t('copy_and_go_to_aeat')}</CButton>
				{clicked && (
				<CButton disabled={loading} variant='filled' onPress={() => {
					requestFiscalData().then(() => {
						setClicked(false)
					})
				}}>{t('already_ratified_address')}</CButton>
			)}
			</View> 
			
		</View>
	)
}


export const AeatLoginScreen = ({
	hasBackButton,
	onNext,
	onBack,
	yearId,
	yearDraftId,
}: {
	hasBackButton: boolean,
	onNext: () => void,
	onBack: () => void,
	yearId: string,
	yearDraftId: string,
}) => {
	const authStore = useAuthStore()
	const [loading, setLoading] = useState<boolean>(false)
	const [showRatify, setShowRatify] = useState<boolean>(false)
	const [ratifyReferenceNumber, setRatifyReferenceNumber] = useState<string>()
	const [showAttemptsWarning, setShowAttemptsWarning] = useState<boolean>(false)
	const loginRef = useRef<any>(null)

	const loginAeat = ({
		selectedType,
		pin,
		code,
		reference,
		casilla,
		tipoCasilla
	}: {
		selectedType: FiscalRegisterTypeProps,
		pin: string,
		code: string,
		reference: string,
		casilla: string,
		tipoCasilla?: FiscalRegisterCasillaType
	}) => {
		setLoading(true)
		const params: Record<string, string> = {
			method: selectedType,
			pin,
			code,
			reference,
		}

		if(tipoCasilla === '505'){
			params.casilla = casilla
		} else if (tipoCasilla === 'iban') {
			params.iban = casilla
		}

		loginAeatAPI(authStore)(yearId, yearDraftId, selectedType, params).then(() => {
			return requestFiscalData()
		}).catch((error) => {
			showMessage({
				message: error.message,
				type: 'danger'
			})
			if (error.showAttemptsWarning) {
				setShowAttemptsWarning(true)
			}
		}).finally(() => {
			setLoading(false)
		})
	}

	const requestFiscalData = () => {
		setLoading(true)
		return requestFiscalDataAPI(authStore)(yearId, yearDraftId).then(() => {
			onNext()
		}).catch((data) => {
			if(data.dataAlreadyParsed) {
				onNext()
				return
			}

			if(data.domicilioRatificado === false) {
				setShowRatify(true)
				setRatifyReferenceNumber(data.referenceNumber)
				return
			}

			if(data.retryLogin) {
				loginRef.current?.resetTemporaryData()
				setShowRatify(false)
				return
			}


		}).finally(() => {
			setLoading(false)
		})

	}

	if (showRatify) {
		return (
			<RatifyScreen
				loading={loading}
				requestFiscalData={requestFiscalData}
				referenceNumber={ratifyReferenceNumber!}
			/>
		)
	}

	return (
		<LoginScreen
			ref={loginRef}
			loading={loading}
			setLoading={setLoading}
			loginAeat={loginAeat}
			hasBackButton={hasBackButton}
			onNext={onNext}
			onBack={onBack}
			yearId={yearId}
			showAttemptsWarning={showAttemptsWarning}
			yearDraftId={yearDraftId}
		/>
	)
}