export const getNavigationIcon = (routeName: string, focused: boolean) => {
	switch (routeName) {
	case 'HomeNavigation':
		return focused ? 'file-tray-full' : 'file-tray-full-outline'
	case 'DocumentsNavigation':
		return focused ? 'folder-open' : 'folder-outline'
	case 'PlansNavigation':
		return focused ? 'rocket' : 'rocket-outline'
	case 'ProfileNavigation':
		return focused ? 'person-circle' : 'person-outline'
	default:
		return 'home'
	}
}
