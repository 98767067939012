import { type BottomTabBarProps } from '@react-navigation/bottom-tabs'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withSpring,
	withTiming
} from 'react-native-reanimated'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { useReduceMotion } from '../../hooks/useReduceMotion'
import { useCTheme } from '../../hooks/useTheme'
import { CPressable } from '../CPressable'
import { CText } from '../CText'
import Ionicon from '@expo/vector-icons/Ionicons'
import { getNavigationIcon } from '../../helpers/icons'

export const CBottomTabBar = (props: BottomTabBarProps) => {
	const { state, descriptors, navigation } = props
	const { t } = React.useContext(LocalizationContext)
	const theme = useCTheme()
	const { reduceMotionEnabled } = useReduceMotion()

	const tabIndicatorWidth = 60

	const visibleRoutes = useMemo(() => {
		return state.routes.filter((route) => {
			const { options } = descriptors[route.key]
			return (options as any).tabBarVisible !== false
		})
	}, [state])

	const visibleRouteIndex = useMemo(() => {
		return visibleRoutes.findIndex(
			(route) => route.key === state.routes[state.index].key
		)
	}, [state.index])

	const selectedIndex = useSharedValue(visibleRouteIndex)
	const itemWidth = useSharedValue(0)

	React.useEffect(() => {
		selectedIndex.value = visibleRouteIndex
	}, [visibleRouteIndex])

	const tabIndicatorStyle = useAnimatedStyle(() => {
		const options =
			descriptors[visibleRoutes[selectedIndex.value]?.key]?.options
		if (reduceMotionEnabled) {
			return {
				opacity: selectedIndex.value === -1 ? 0 : 1,
				transform: [
					{
						translateX:
							selectedIndex.value !== -1
								? itemWidth.value * selectedIndex.value +
								  itemWidth.value / 2 -
								  tabIndicatorWidth / 2
								: 0
					}
				],
				backgroundColor: (options as any)?.color || theme.colors.text,
				width: tabIndicatorWidth
			}
		} else {
			return {
				opacity: withSpring(selectedIndex.value === -1 ? 0 : 1, {
					overshootClamping: false
				}),
				transform: [
					{
						translateX: withSpring(
							selectedIndex.value !== -1
								? itemWidth.value * selectedIndex.value +
										itemWidth.value / 2 -
										tabIndicatorWidth / 2
								: 0,
							{ damping: 10 }
						)
					}
				],
				backgroundColor: withTiming(
					(options as any)?.color || theme.colors.text,
					{}
				),
				width: tabIndicatorWidth
			}
		}
	})

	return (
		<View
			{...props}
			style={{
				backgroundColor: theme.colors.card,
				borderTopColor: theme.colors.border,
				borderTopWidth: StyleSheet.hairlineWidth,
				paddingBottom: props.insets.bottom
			}}
		>
			<View
				style={{
					flexDirection: 'row'
				}}
			>
				<Animated.View
					style={[
						tabIndicatorStyle,
						{
							position: 'absolute',
							left: 0,
							top: 0,
							height: 5,
							borderBottomRightRadius: 100,
							borderBottomLeftRadius: 100
						}
					]}
				/>
				{visibleRoutes.map((route, index) => {
					const { options } = descriptors[route.key]

					const isFocused = state.index === index

					const onPress = () => {
						navigation.emit({
							type: 'tabPress',
							target: route.key,
							canPreventDefault: true
						})
						navigation.navigate(route.name)
					}

					const onLongPress = () => {
						navigation.emit({
							type: 'tabLongPress',
							target: route.key
						})
					}

					const element = (
						<View
							onLayout={(e) => {
								if (index === 0) {
									itemWidth.value = e.nativeEvent.layout.width
								}
							}}
							style={{
								paddingHorizontal: 5,
								paddingVertical: 5,
								paddingTop: 10,
								width: `${100 / visibleRoutes.length}%`
							}}
							key={route.name}
						>
							<CPressable
								style={[
									(options as any)?.drawerItemStyle,
									{
										paddingVertical: 5,
										paddingHorizontal: 2,
										borderRadius: 5
									}
								]}
								accessibilityRole="button"
								accessibilityState={
									isFocused ? { selected: true } : { selected: false }
								}
								accessibilityLabel={options.tabBarAccessibilityLabel}
								testID={options.tabBarTestID}
								onPress={onPress}
								onLongPress={onLongPress}
							>
								<View
									key={index}
									style={{ width: '100%', justifyContent: 'center' }}
								>
									<Ionicon
										name={getNavigationIcon(route.name, isFocused)}
										size={24}
										color={
											isFocused
												? (options as any).color || theme.colors.text
												: theme.colors.text
										}
										style={{ textAlign: 'center' }}
									/>
									<CText
										style={{
											fontSize: 12,
											textAlign: 'center',
											fontWeight: isFocused ? '700' : 'normal',
											color: isFocused
												? (options as any).color || theme.colors.text
												: theme.colors.text
										}}
										numberOfLines={1}
									>
										{t(route.name)}
									</CText>
								</View>
							</CPressable>
						</View>
					)

					return element
				})}
			</View>
		</View>
	)
}
