import { View } from 'react-native'
import { useCTheme } from '../../hooks/useTheme'
import { CPressable } from '../CPressable'
import { CText } from '../CText'
import { AppleLogo, GoogleLogo, MeAsesoranLogo } from '../Logos'
import { type CButtonProps } from './types'

const icons = {
	google: GoogleLogo,
	apple: AppleLogo,
	measesoran: MeAsesoranLogo
}

export const CLoginButton = (props: CButtonProps) => {
	const theme = useCTheme()
	const {
		children,
		disabled,
		onPress,
		type,
		...rest
	} = props

	const textColorStyle = {
		measesoran: theme.appColors.textWithBackground,
		google: theme.appColors.white,
		apple: theme.appColors.white
	}[type]
	const backgroundColor = {
		measesoran: theme.appColors.primary1,
		google: theme.appColors.black,
		apple: theme.appColors.black
	}[type]

	const Logo = icons[type]

	return (
		<CPressable
			{...rest}
			disabled={disabled}
			onPress={onPress}
			style={[
				{
					paddingVertical: 14,
					paddingHorizontal: 20,
					borderRadius: 100,
					backgroundColor,
					...theme.appShadows.card
				},
				disabled && {
					opacity: 0.5
				}
			]}
		>
			<View
				style={{
					flexDirection: 'row'
				}}
			>
				<View
					style={{
						flex: 1
					}}
				>
					<Logo width={20} height={20} fill={textColorStyle} />
				</View>
				<CText
					style={{
						color: textColorStyle,
						alignSelf: 'center',
						fontWeight: '600'
					}}
				>
					{children}
				</CText>
				<View style={{ flex: 1 }} />
			</View>
		</CPressable>
	)
}
