import React, { useEffect } from 'react'
import { Platform, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import {
	CButton,
	CHeaderText,
	CPressable,
	CText,
	CTextInput
} from '../../../components'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { useResponsive } from '../../../hooks/useResponsive'
import { useCTheme } from '../../../hooks/useTheme'
import {jwtDecode} from 'jwt-decode'
import { useAuthStore } from '../../../stores/auth'


const ProfileAccount =
	() => {
		const { t } = React.useContext(LocalizationContext)
		const { breakpoints } = useResponsive()
		const authStore = useAuthStore()
		const theme = useCTheme()


		const [email, setEmail] = React.useState<string | undefined>('')
		//const [phone, setPhone] = React.useState<string | undefined>('')

		useEffect(() => {
			try{
				const userData = jwtDecode(authStore.accessToken ?? '')
				setEmail((userData as any)?.email ?? '')
			}catch(e){
				console.log(e)
			}
		}, [])

		return (
			<ScrollView
				contentContainerStyle={{
					padding: 20,
					maxWidth: breakpoints.sm,
					flex: 1
				}}
			>
				{/* <CHeaderText>{t('update_account')}</CHeaderText> */}
				<CTextInput
					containerStyle={{
						marginTop: 20
					}}
					editable={false}
					keyboardType="email-address"
					headerText={t('email')}
					value={email}
				/>
				{/* <CTextInput
					containerStyle={{
						marginTop: 10
					}}
					onChangeText={setPhone}
					textContentType="telephoneNumber"
					dataDetectorTypes={['phoneNumber']}
					keyboardType="phone-pad"
					headerText={t('phone')}
					value={phone}
				/> */}
				{/* <CButton
					disabled={originalPhone === phone}
					style={{
						marginHorizontal: 20
					}}
					onPress={() => {
						alert('Sin implementar') // TODO: Implementar
					}}
				> 
					{t('save')}
				</CButton>
				*/}
				{Platform.OS === 'ios' && (
					<View
						style={{
							marginTop: 20,
							flex: 1,
							justifyContent: 'flex-end'
						}}
					>
						<CPressable
							onPress={() => {
								alert('Sin implementar') // TODO: Implementar
							}}
						>
							<CText
								style={{
									textAlign: 'center',
									color: theme.appColors.alert
								}}
							>
								{t('delete_account')}
							</CText>
						</CPressable>
					</View>
				)}
			</ScrollView>
		)
	}

export default ProfileAccount
