import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'

export const getAdminSectionsAPI = (authStore: AuthStore) =>
	async (yearId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/admin/${yearId}/sections`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}


	export const getAdminFormsAPI = (authStore: AuthStore) =>
	async (yearId: string, sectionId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/admin/${yearId}/sections/${sectionId}/forms`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

	export const getAdminFormAPI = (authStore: AuthStore) =>
	async (yearId: string, formId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/admin/${yearId}/forms/${formId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}


	export const postAdminSectionFormAPI = (authStore: AuthStore) =>
	async (yearId: string, sectionId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/admin/${yearId}/sections/${sectionId}/forms`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			},
		})
			.then(processResponse)
	}

	export const postAdminFormAPI = (authStore: AuthStore) =>
	async (yearId: string, formId: string, data: any) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/admin/${yearId}/forms/${formId}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(processResponse)
	}

	export const deleteAdminFormAPI = (authStore: AuthStore) =>
	async (yearId: string, formId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/admin/${yearId}/forms/${formId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}