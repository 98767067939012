import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'

export const getYearsAPI = (authStore: AuthStore) =>
	async () => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const postYearDraftsAPI = (authStore: AuthStore) =>
	async (yearId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const deleteYearDraftsAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

	export const getYearsXMLAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string, type: 'conjunta' | 'declarante' | 'conyuge', calculate: boolean, fillStrategy: 'aeat' | 'measesoran') => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/xml` + (type ? `?type=${type}&calculate=${calculate}&fillStrategy=${fillStrategy}` : ''), {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
		// encode latin1
		.then(response => response.blob())
        .then(blob => {
			const filename = calculate ? 'calculo' : 'borrador'
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${filename}-${type}.${calculate ? 'xml' : 'json'}`; // set the file name
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });
	}


	export const postYearsSendAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/send`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}