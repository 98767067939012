import React, { useEffect, useMemo, useState } from 'react'
import { Platform, View } from 'react-native'
import type { PlansTabScreenProps } from '../../../routes/types'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { CLoadingSpinner, CText, StripePaymentSheet } from '../../../components'
import { useCTheme } from '../../../hooks/useTheme'
import { PlansData } from '..'
import { PaymentSheet } from '../../../components/PaymentForm/types'
import { getPlanPurchaseAPI } from '../../../api/purchases'
import { useNavigation } from '@react-navigation/native'
import { useAuthStore } from '../../../stores/auth'
import { useResponsive } from '../../../hooks/useResponsive'
import { showMessage } from 'react-native-flash-message'
import e from 'connect-flash'
import dayjs from 'dayjs'

const PlansCheckout = ({
	route,
	navigation
}: PlansTabScreenProps<'PlansCheckoutNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [loading, setLoading] = useState(true)
	const [paymentSheetData, setPaymentSheetData] = useState<PaymentSheet>()
	const theme = useCTheme()
	const authStore = useAuthStore()
	const {breakpoints} = useResponsive()
	const plan = useMemo(() => {
		return PlansData.find(p => p.id === route?.params?.id)
	}, [route?.params?.id])
	const yearId = route?.params?.yearId


	const onPaid = () => {
		
	}

	const onRejected = () => {
		setPaymentSheetData(undefined)
	}

	const getPaymentSheetData = () => {
		if(!plan || !yearId) {
			return
		}
		setLoading(true)
		getPlanPurchaseAPI(authStore)(plan.id, yearId)
			.then((data) => {
				setPaymentSheetData(data)
			})
			.catch((err) => {
				showMessage({
					message: err.message,
					type: 'danger'
				})
				navigation.navigate('HomeNavigation' as any)
			}).finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if(!plan || !yearId) {
			navigation.navigate('HomeNavigation' as any)
			return 
		}
		getPaymentSheetData()

	}, [plan, yearId])

	useEffect(() => {
		if (plan && plan.availableFrom && dayjs().isBefore(dayjs(plan.availableFrom))) 
			navigation.navigate('HomeNavigation' as any)
	}, [plan])

	if (!plan || !yearId) {
		return null
	}

	return (
		<View style={{ 
			flex: 1,
			maxWidth: breakpoints.sm,
			alignSelf: 'center',
			width: '100%',
			}}>
			<CText
				style={{
					fontSize: 18,
					fontWeight: '900',
					textAlign: 'center',
					marginTop: 20,
					color: theme.colors.text
				}}
			>
				{plan.title}
			</CText>

				{loading && (
					<CLoadingSpinner />
				)}
			{!!paymentSheetData && (
				<StripePaymentSheet
					planId={plan.id}
					yearId={yearId}
					paymentSheet={paymentSheetData}
					onPaid={onPaid}
					onRejected={onRejected}
				/>
			)}
		</View>
	)
}

export default PlansCheckout
