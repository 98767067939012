import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg'
import { type LogoProps } from '../types'

export const MeAsesoranLogo = (props: LogoProps) => {
	return (
		<Svg
			{...props}
			viewBox="0 0 588.37451 589.22029">
			<Defs>
				<ClipPath
					id="clipPath34">
					<Path
						d="M 0,1115.777 H 1805.639 V 0 H 0 Z"
						id="path32" />
				</ClipPath>
			</Defs>
			<G
				id="g10"
				transform="matrix(1.3333333,0,0,-1.3333333,-900.98731,1184.2177)">
				<G
					id="g12"
					transform="translate(865.2925,888.1633)">
					<Path
						d="m 0,0 h -161.702 -27.849 -0.001 v -189.551 h 27.85 V -27.849 H 0 Z"
						fill={props.fill ?? '#f9b100'}
						id="path14" />
				</G>
				<G
					id="g16"
					transform="translate(1089.1714,888.1633)">
					<Path
						d="M 0,0 H -161.702 V -27.849 H 0 V -189.551 H 27.85 V -27.849 0 Z"
						fill={props.fill ?? '#357abe'}
						id="path18" />
				</G>
				<G
					id="g20"
					transform="translate(703.5905,474.0978)">
					<Path
						d="M 0,0 V 161.702 H -27.85 V -27.849 H -27.849 0 161.702 V 0 Z"
						fill={props.fill ?? '#14a56d'}
						id="path22" />
				</G>
				<G
					id="g24"
					transform="translate(1089.1714,635.8001)">
					<Path
						d="m 0,0 v -161.702 h -161.702 v -27.85 H 0 27.85 v 27.85 V 0 Z"
						fill={props.fill ?? '#36bde6'}
						id="path26" />
				</G>
				<G
					id="g28">
					<G
						id="g30"
						clipPath="url(#clipPath34)">
						<G
							id="g36"
							transform="translate(935.86,757.7266)">
							<Path
								d="M 0,0 H -4.708 C -20.917,0 -31.664,-5.192 -38.794,-11.714 -45.924,-5.192 -56.671,0 -72.88,0 h -4.708 c -43.69,0 -47.833,-37.664 -47.833,-37.664 v -138.602 h 31.59 v 128.527 c 0,0 1.083,15.254 18.597,15.254 17.513,0 18.596,-15.254 18.596,-15.254 v -128.527 h 4.097 27.494 4.097 v 128.527 c 0,0 1.083,15.254 18.596,15.254 17.514,0 18.596,-15.254 18.596,-15.254 V -176.266 H 47.833 V -37.664 C 47.833,-37.664 43.69,0 0,0"
								fill={props.fill ?? '#1e4388'}
								id="path38" />
						</G>
					</G>
				</G>
			</G>
		</Svg>

	)
}
