import { type WebViewProps } from 'react-native-webview'

export const CWebView = (props: WebViewProps) => {
	return (
		<iframe
			src={(props.source as any)?.uri}
			style={{
				border: 'none',
				height: (props.style as any)?.flex === 1 ? '100%' : undefined,
				...(props.style as any)?.style
			}}
			onLoad={(e) => {
				props.onLoadEnd?.(e as any)
			}}
		/>
	)
}
