import type { NativeStackHeaderProps } from '@react-navigation/native-stack'
import type { StackHeaderProps } from '@react-navigation/stack'
import { CText } from '../../CText'
import { View } from 'react-native'
import { useCTheme } from '../../../hooks/useTheme'
import { CPressable } from '../../CPressable'
import React from 'react'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { getHeaderTitle } from '@react-navigation/elements'

export const CModalHeader = (
	props: NativeStackHeaderProps | StackHeaderProps
) => {
	const { t } = React.useContext(LocalizationContext)
	const theme = useCTheme()
	const title = getHeaderTitle(props.options, props.route.name)

	return (
		<View
			style={{
				backgroundColor: theme.colors.card,
				padding: 16,
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottomWidth: 1,
				borderBottomColor: theme.colors.border
			}}
		>
			<View
				style={{
					flex: 1,
					alignSelf: 'flex-start',
					alignItems: 'flex-start'
				}}
			>
				<CPressable
					onPress={() => {
						props.navigation.canGoBack() && props.navigation.goBack()
					}}
				>
					<CText
						style={{
							fontSize: 18,
							textAlign: 'left',
							color: theme.colors.primary
						}}
					>
						{t('close')}
					</CText>
				</CPressable>
			</View>
			<CText
				style={{
					fontSize: 18,
					color: theme.colors.text,
					textAlign: 'center',
					fontWeight: '500'
				}}
			>
				{title}
			</CText>
			<View style={{ flex: 1 }} />
		</View>
	)
}
