import React from 'react'
import { Alert, Platform, View } from 'react-native'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { type HomeTabScreenProps } from '../../../routes/types'
import { CButton, CText } from '../../../components'
import { type YearDraft, type YearAPIResponse } from '../types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteYearDraftsAPI, postYearDraftsAPI } from '../../../api/years'
import { useAuthStore } from '../../../stores/auth'
import { showMessage } from 'react-native-flash-message'
import { useCTheme } from '../../../hooks/useTheme'
import { useResponsive } from '../../../hooks/useResponsive'
import ParticleBackground from "react-native-particle-background";
import { AntDesign } from '@expo/vector-icons'

const HomeYear = ({
	route,
	navigation
}: HomeTabScreenProps<'HomeYearNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)

	const yearId = route.params?.yearId
	const queryClient = useQueryClient()
	const authStore = useAuthStore(state => state)

	const theme = useCTheme()
	const { breakpoints } = useResponsive()

	const { data, isLoading, error } = useQuery({
		queryKey: ['years'],
		// Previene que se refresquen los datos al montarse el componente, los datos se cargan de la cache del listado de ejercicios
		refetchOnMount: false
	})

	const navigateToDraft = (yearId: string, yearDraftId: string) => {
		navigation.navigate('DeclarationNavigation' as any, { screen: 'DeclarationIndexNavigation', params: { yearId, yearDraftId } })
	}

	const yearDraftMutation = useMutation(
		async ({yearId, yearDraftId}: {yearId: string, yearDraftId?: string}) => {
			if (yearDraftId != null) {
				await deleteYearDraftsAPI(authStore)(yearId, yearDraftId)
			}
			
			return postYearDraftsAPI(authStore)(yearId)
		},
		{
			onSuccess: (data) => {
				const draft: YearDraft = data.draft
				queryClient.setQueryData(['years'], (oldData: any) => {
					return oldData.map((year: YearAPIResponse) => {
						if (year.year.id === draft.yearId) {
							return {
								year: year.year,
								draft
							}
						}
						return year
					})
				})

				navigateToDraft(yearId, draft.id)
			},
			onError: (err: any) => {
				console.error(err.next)
				if(err.next === 'purchase'){
					navigation.navigate('PlansNavigation' as any, { screen: 'PlansIndexNavigation' , params: { yearId } })
					return
				}
				showMessage({
					message: err.message,
					type: err.type || 'danger',
					icon: err.type || 'danger'
				})
			}
		}
	)

	const yearWithDraft: YearAPIResponse | undefined = (data as [YearAPIResponse])?.find((year: YearAPIResponse) => year.year.id === yearId)

	React.useEffect(() => {
		if (!yearId) {
			navigation.navigate('HomeIndexNavigation')
			return
		}

		navigation.setOptions({
			title: `${t('DeclarationNavigation')} ${yearId}`
		})
	}, [yearId])

	if (isLoading || error || !yearWithDraft) {
		return null
	}

	return (
		<View style={{
			flex: 1,
			alignItems: 'flex-start',
		}}>

			<View
				style={{
					backgroundColor: theme.appColors.primary1,
					padding: 15,
					flex: 1,
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<View style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}}>					
					<ParticleBackground
						particleColor={theme.colors.card}
						particleSize={4}
						particleDispersion={200}
						backgroundColor="transparent"
					/>
				</View>
				<View style={{
					flex: 1,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<CText
						numberOfLines={1}
						style={{
							alignSelf: 'center',
							color: theme.appColors.textWithBackground,
							fontSize: 80,
							fontWeight: '600'
						}}
					>
						{yearWithDraft.year.year}
					</CText>
					{yearWithDraft.draft && (
						<CText
							numberOfLines={1}
							style={{
								opacity: 0.5,
								alignSelf: 'center',
								color: theme.appColors.textWithBackground,
								fontSize: 30,
								fontWeight: '500',
								marginBottom: 60,
								marginTop: 20
							}}
						>
							{t(`declaration_status_${yearWithDraft.draft?.status}`) }
						</CText>
					)}
				</View>
				<View style={{
					flex: 1,
					justifyContent: 'flex-end',
					alignSelf: 'center',
					gap: 10,
					width: '100%',
					maxWidth: breakpoints.sm
				}}>
					{(yearWithDraft.draft == null || yearWithDraft.draft?.status === 'started') && yearWithDraft.year.outOfDate && (
						<View style={{
							padding: 10,
							borderRadius: 10,
							flexDirection: 'row',
							alignItems: 'center',
							gap: 10
						}}>
							<AntDesign name="exclamationcircle" size={20} color='white' />
							<CText style={{
								color: 'white',
							}}>{t('declaration_out_of_date')}</CText>
						</View>
					)}
					{(yearWithDraft.draft == null || yearWithDraft.draft?.status === 'started') && (
					<CButton
						onPress={() => {
							if(yearWithDraft.draft != null) {
								if(Platform.OS === 'web'){
									if(window.confirm(t('draft_are_you_sure_delete_old')) === true){
										yearDraftMutation.mutate({yearId: yearWithDraft.year.id, yearDraftId: yearWithDraft.draft?.id})
									}
								}else{
									Alert.alert(
										t('warning'),
										t('draft_are_you_sure_delete_old'),
										[
											{
												text: t('cancel'),
												style: 'cancel'
											},
											{
												text: t('ok'),
												onPress: () => {
													yearDraftMutation.mutate({yearId: yearWithDraft.year.id, yearDraftId: yearWithDraft.draft?.id})
												}
											}
										]
									)
								}
							}else{
								yearDraftMutation.mutate({yearId: yearWithDraft.year.id})
							}
						}}
						variant={'filled'}
					>
						Nueva
					</CButton>
					)}

					{(yearWithDraft.draft?.status === 'started') && (
						<CButton
							onPress={() => {
								navigateToDraft(yearWithDraft.year.id, yearWithDraft.draft!.id)
							}}
							variant={'filled'}
						>
					Continuar
						</CButton>
					)}

				</View>
			</View>
		</View>
	)
}

export default HomeYear
