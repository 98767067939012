import React, { useEffect, useRef } from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import type { DeclarationTabScreenProps, ReviewTabScreenProps } from '../../routes/types'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { CText, CPressable, CLoadingSpinner, CButton } from '../../components'
import { useCTheme } from '../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthStore } from '../../stores/auth'
import { showMessage } from 'react-native-flash-message'
import { type SectionAPIResponse } from './types'
import { StackActions } from '@react-navigation/native'
import { getYearsXMLAPI } from '../../api/years'
import { usePrevious } from '../../hooks/usePrevious'
import { AntDesign } from '@expo/vector-icons'
import { getReviewSectionsAPI, getYearsDraftReviewSummaryAPI, getYearsDraftReviewSummaryCsvAPI, postReviewStatusAPI } from '../../api/reviews'
import ResponsesSummary from './section/summary'

const Review = ({
	navigation,
	route
}: ReviewTabScreenProps<'ReviewIndexNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedId, setSelectedId] = React.useState<string | null>(null)
	const [showSummary, setShowSummary] = React.useState(false)
	const theme = useCTheme()
	const prevSectionId = route.params?.prevSectionId
	const lastPrevSectionId = usePrevious(prevSectionId)
	const yearId = route.params?.yearId
	const yearDraftId = route.params?.yearDraftId

	const queryClient = useQueryClient()

	const authStore = useAuthStore()

	const { data, error, isLoading } = useQuery({
		queryKey: ['review-sections', yearDraftId],
		queryFn: async () => await getReviewSectionsAPI(authStore)(yearId, yearDraftId),
	})
	const sectionsWithDrafts: [SectionAPIResponse] = data

	React.useEffect(() => {
		if (error) {
			showMessage({
				message: (error as any)?.message,
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [error])

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('state', (e) => {
			const state = e.data.state
			if (state.routes[state.index].name === 'ReviewSectionNavigation' && (state.routes[state.index].params as any)?.sectionId) {
				setSelectedId((state.routes[state.index]?.params as any)?.sectionId ?? null)
				return
			}

			if (state.routes[state.index].name === 'ReviewIndexNavigation') {
				setSelectedId(null)
				return
			}
		})
		return unsubscribe
	}, [navigation, sectionsWithDrafts])

	React.useEffect(() => {
		if( prevSectionId && lastPrevSectionId !== prevSectionId) {
			queryClient.removeQueries(['review-next-form'])
			const prevSectionIndex = sectionsWithDrafts?.findIndex((s) => s.section.id === prevSectionId)
			const nextSection = sectionsWithDrafts?.[prevSectionIndex + 1]
			if (!nextSection) {
				return
			}
			navigation.dispatch(StackActions.replace('ReviewSectionNavigation', { yearId, yearDraftId, sectionId: nextSection.section.id }))
			navigation.setParams({ prevSectionId: undefined })
		}
	}, [prevSectionId])

	if (isLoading) {
		return <CLoadingSpinner/>
	}

	if (error) {
		return null
	}


	return (
		<ScrollView>
			{showSummary && (
				<ResponsesSummary
					yearId={yearId}
					yearDraftId={yearDraftId}
					onHide={() => setShowSummary(false)}
					/>
			)}
			<FlatList
			extraData={[theme, selectedId]}
			ListHeaderComponent={() => (
				<View
					style={{
						marginTop: 30
					}}
				/>
			)}
			contentContainerStyle={{
				gap: 5,
			}}
			data={sectionsWithDrafts}
			keyExtractor={(item) => item.section.id}
			renderItem={({ item }) => {
				return (
					<View
						style={{
							flexDirection: 'row',
							backgroundColor:
							selectedId === item.section.id
								? theme.appColors.primary1
								: undefined,
							borderRadius: 10,
							paddingHorizontal: selectedId === item.section.id ? 0: 5,
							marginHorizontal: 10,
							overflow: 'hidden',
							opacity: item.enabled ? 1 : 0.4,
						}}
					>
						<CPressable
							disabled={!item.enabled}
							onPress={() => {
								if (selectedId === item.section.id) {
									return
								}
								queryClient.removeQueries(['review-next-form'])
								navigation.dispatch(StackActions.replace('ReviewSectionNavigation', { yearId, yearDraftId, sectionId: item.section.id }))
							}}
							style={{
								padding: 10,
								paddingVertical: selectedId === item.section.id ? 20 : 10,
								flex: 1,
							}}
						>
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}>
								<CText numberOfLines={1} style={{
									fontWeight: selectedId === item.section.id ? 'bold' : 'normal',
									color: selectedId === item.section.id ? theme.appColors.textWithBackground : theme.appColors.text,
								}}>
									{item.section.name}
								</CText>

								<View
									style={{
										flexDirection: 'row',
										alignSelf: 'center',
										justifyContent: 'center',
										marginHorizontal: 10,
									}}
								>
									<AntDesign
										name="right"
										size={18}
										color={selectedId === item.section.id ? theme.appColors.textWithBackground : theme.appColors.text}
									/>
								</View>
							</View>
						</CPressable>
					</View>
				)
			}}
		/>
		<View style={{
			flexDirection: 'row',
			gap: 10,
			margin: 10,
			justifyContent: 'center',
		}}>
			<CButton
				variant='filled'
				color={theme.appColors.primary2}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					setShowSummary(true)
				}}
				style={{
					margin: 20,
				}}>Ver resumen</CButton>
			<CButton
				variant='filled'
				color={theme.appColors.primary3}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsDraftReviewSummaryCsvAPI(authStore)(yearId, yearDraftId)
				}}
				style={{
					margin: 20,
				}}>
					<AntDesign name="download" size={16} color={theme.appColors.textWithBackground} />
				</CButton>
		</View>
		<View style={{
			flexDirection: 'row',
			gap: 10,
			marginTop: 10,
			alignSelf: 'center',
		}}>
			<CText style={{
					alignSelf: 'center',
				}}>Declarante:</CText>
			<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'declarante', true, 'aeat')
				}}
				style={{
					margin: 20,
				}}>XML</CButton>
				<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'declarante', false, 'measesoran')
				}}
				style={{
					margin: 20,
				}}>JSON</CButton>
		</View>
		<View style={{
			flexDirection: 'row',
			alignItems: 'center',
			gap: 10,
			marginTop: 10,
			alignSelf: 'center',
		}}> {/* Cónyuge */}
			<CText style={{
					alignSelf: 'center',
				}}>Cónyuge:</CText>
			<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conyuge', true, 'aeat')
				}}
				style={{
					margin: 20,
				}}>XML</CButton>
				<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conyuge', false, 'measesoran')
				}}
				style={{
					margin: 20,
				}}>JSON</CButton>
			
		</View>
		<View style={{
			flexDirection: 'row',
			alignItems: 'center',
			gap: 10,
			marginTop: 10,
			alignSelf: 'center',
		}}>{/* Conjunta */}

			<CText style={{
					alignSelf: 'center',
				}}>Conjunta:</CText>
			<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conjunta', true, 'aeat')
				}}
				style={{
					margin: 20,
				}}>XML</CButton>
				<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conjunta', false, 'measesoran')
				}}
				style={{
					margin: 20,
				}}>JSON</CButton>
			
		</View>
		<View style={{
			flexDirection: 'row',
			alignItems: 'center',
			gap: 10,
			marginTop: 30,
			alignSelf: 'center',
		}}>
			<CButton
				variant='filled'
				color={theme.appColors.primary3}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					if(!confirm('¿Estás seguro de que quieres habilitar el borrador? No podrás volver a editarlo hasta que el usuario lo envíe a revisión de nuevo.')) {
						return
					}
					postReviewStatusAPI(authStore)(yearId, yearDraftId, 'started').then((data) => {
						showMessage({
							message: data.message,
							type: 'success',
							icon: 'success'
						})
					}).catch((error) => {
						showMessage({
							message: (error as any)?.message,
							type: 'danger',
							icon: 'danger'
						})
					})

				}}
				style={{
					margin: 20,
				}}>Habilitar borrador</CButton>
		</View>

		
	</ScrollView>

	)
}

export default Review
