import { useContext, useState } from "react"
import { CButton, CLoadingSpinner, CText } from "../../../components"
import { LocalizationContext } from "../../../contexts/LocalizationContext"
import { View } from "react-native"
import { useCTheme } from "../../../hooks/useTheme"
import ConfettiCannon from 'react-native-confetti-cannon';
import { postYearsSendAPI } from "../../../api/years"
import { useAuthStore } from "../../../stores/auth"
import { showMessage } from "react-native-flash-message"
import { LegalText } from "../../../components/LegalText"

export const ResultsScreen = ({
	yearId,
	yearDraftId,
	onSend
}:{
	yearId: string,
	yearDraftId: string,
	onSend: () => void
}) => {
	const {t} = useContext(LocalizationContext)
	const theme = useCTheme()
	const authStore = useAuthStore()
	const [loading, setLoading] = useState(false)

	const sendResults = () => {
		setLoading(true)
		postYearsSendAPI(authStore)(yearId, yearDraftId).then((data) => {
			showMessage({
				message: data.message,
				type: 'success'
			})

			onSend()
		}).catch((error) => {
			showMessage({
				message: error.message,
				type: 'danger'
			})
		}).finally(() => {
			setLoading(false)
		})
	}

	return (
		<View style={{
			flex: 1,
			padding: 20,
			justifyContent: 'center',
			gap: 20,
			alignItems: 'center'
		}}>
			<View style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				overflow: 'hidden',
			}}>
				<ConfettiCannon count={100} origin={{x: 0, y: 750}} autoStartDelay={0} fadeOut={true} fallSpeed={5000} />
			</View>
			<CText style={{
				fontSize: 24,
				fontWeight: '600',
				marginTop: 10,
				textAlign: 'center'
			}}>
				{t('results_title')}
			</CText>
			<CText style={{
				fontSize: 16,
				fontWeight: '400',
				textAlign: 'center',
				marginHorizontal: 20,
				lineHeight: 24
			}}>
				{t('results_p1')}
			</CText>
			<LegalText text={t('results_legal')} urls={[
				'https://www.measesoran.com/terminos-servicio-renta',
			]} textStyle={{
				fontSize: 14,
				fontWeight: '300',
				textAlign: 'center',
			}}/>

			<CButton
				variant='filled'
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					sendResults()
				}}
				disabled={loading}
				loading={loading}
				style={{
					marginTop: 10,
				}}>
					{t('results_send')}
				</CButton>
		</View>
	)
}