export interface AppColor {
	dark: string
	light: string
}

export type ThemedColors = Record<string, string>

export interface ShadowParams {
	shadowColor: string
	shadowOffset: {
		width: number
		height: number
	}
	shadowOpacity: number
	shadowRadius: number
	elevation: number
}

export interface Shadow {
	dark: ShadowParams
	light: ShadowParams
}

export type ThemeShadows = Record<string, Shadow>

export type ThemedShadows = Record<string, ShadowParams>

export const AppColors = {
	background: {
		dark: '#000000',
		light: '#F0F0F0'
	},
	border: {
		dark: '#232324',
		light: '#D3D3D3'
	},
	card: {
		dark: '#000000',
		light: '#FFFFFF'
	},
	primary1: {
		dark: '#4475ba',
		light: '#4475ba'
	},
	primary2: {
		dark: '#59bee6',
		light: '#59bee6'
	},
	primary3: {
		dark: '#44a06f',
		light: '#44a06f'
	},
	primary4: {
		dark: '#f5b728',
		light: '#f5b728'
	},
	tint: {
		dark: '#007e73',
		light: '#007e73'
	},
	cardOff: { // Off white in light mode, dark grey in dark mode
		dark: 'rgb(12, 12, 12)',
		light: '#fcfcfc'
	},
	cardReverse: {
		dark: '#ffffff',
		light: '#000000'
	},
	unthemedText: {
		dark: '#2f2d45',
		light: '#2f2d45'
	},
	secondaryText: {
		dark: '#7a7a7a',
		light: '#7a7a7a'
	},
	unthemedBackground: {
		dark: '#ffffff',
		light: '#ffffff'
	},
	textWithBackground: {
		dark: '#000000',
		light: '#ffffff'
	},
	focus: {
		dark: '#ffffff',
		light: '#000000'
	},
	hoverBackground: {
		dark: 'rgba(0,0,0,0.1)',
		light: 'rgba(255,255,255,0.1)'
	},
	placeholderText: {
		dark: 'rgba(255,255,255,0.3)',
		light: 'rgba(0,0,0,0.3)'
	},
	pressUnderlay: {
		dark: 'rgba(0,0,0,0.1)',
		light: 'rgba(255,255,255,0.1)'
	},
	alert: {
		dark: '#EB3323',
		light: '#EB3323'
	},
	warning: {
		dark: 'rgb(245,182,40)',
		light: 'rgb(245,182,40)'
	},
	error: {
		dark: '#ff4747',
		light: '#ff4747'
	},
	star: {
		dark: '#F2C94C',
		light: '#F2C94C'
	},
	iosBlue: {
		dark: '#147EFB',
		light: '#147EFB'
	},
	white: {
		dark: '#ffffff',
		light: '#ffffff'
	},
	black: {
		dark: '#000000',
		light: '#000000'
	},
}

export const AppShadows: ThemeShadows = {
	card: {
		light: {
			shadowColor: '#7a7a7a',
			shadowOffset: {
				width: 0,
				height: 0
			},
			shadowOpacity: 0.2,
			shadowRadius: 20,
			elevation: 4
		},
		dark: {
			shadowColor: '#ffffff',
			shadowOffset: {
				width: 0,
				height: 0
			},
			shadowOpacity: 0,
			shadowRadius: 0,
			elevation: 0
		}
	}
}