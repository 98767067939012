import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'
import { downloadFileStorageAPI } from './files'

export const requestPinAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string, method: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/fiscal-data/request-pin?method=${method}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const getTipoCasillaAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/fiscal-data/tipo-casilla`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const loginAeatAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string, method: string, data: Record<string, string|undefined>) => {	
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/fiscal-data/aeat-login?${ new URLSearchParams({
			method,
			...data
		}).toString()}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const requestFiscalDataAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/fiscal-data`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}
	