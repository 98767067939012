import { useWindowDimensions } from 'react-native'

export const ResponsiveWidthBreakpoints = {
	xs: 410, // Phones
	sm: 600, // Large phones
	md: 900, // Tablets
	lg: 1024, // Laptops, large tablets
	xl: 1280 // Large laptops, Desktops
}

const getMasterDetailDrawerEnabled = (width: number, drawerSize: number) => {
	return width - drawerSize >= ResponsiveWidthBreakpoints.sm
}

const getResponsiveTabDrawerEnabled = (width: number) => {
	return width >= ResponsiveWidthBreakpoints.lg
}

export const useResponsive = () => {
	const { width } = useWindowDimensions()

	const isResponsiveTabDrawerEnabled = getResponsiveTabDrawerEnabled(width)
	const responsiveDrawerWidth = isResponsiveTabDrawerEnabled ? 180 : 0

	const isMasterDetailDrawerEnabled = getMasterDetailDrawerEnabled(
		width,
		responsiveDrawerWidth
	)

	return {
		isMasterDetailDrawerEnabled,
		isResponsiveTabDrawerEnabled,

		responsiveDrawerWidth,
		mainViewWidth: width - responsiveDrawerWidth,

		breakpoints: ResponsiveWidthBreakpoints
	}
}
