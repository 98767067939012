import { View } from 'react-native'
import { useCTheme } from '../../hooks/useTheme'
import { CLoadingSpinner } from '../CLoadingSpinner'
import { CPressable } from '../CPressable'
import { CText } from '../CText'
import { type CButtonProps } from './types'

export const CButton = (props: CButtonProps) => {
	const theme = useCTheme()
	const { children, variant, color, textColor, disabled, onPress, loading, ...rest } =
		props

	const backgroundColor =
		variant === 'filled' ? color ?? theme.colors.card : 'transparent'
	const borderColor =
		variant === 'filled' ? color ?? theme.colors.card : theme.colors.text
	const textColorStyle =
		textColor ?? theme.colors.text

	return (
		<CPressable
			{...rest}
			disabled={disabled}
			onPress={onPress}
			style={[
				{
					backgroundColor,
					borderColor,
					borderWidth: 1,
					paddingVertical: 8,
					paddingHorizontal: 16,
					borderRadius: 100,
				},
				disabled && {
					opacity: 0.5
				}
			]}
		>
			<View style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
			}}>
				{
					loading && (
						<CLoadingSpinner activityIndicatorProps={{ color: textColorStyle }} />
					)
				}
			</View>
			{
				typeof children === 'string' ? (
					<CText style={{ color: textColorStyle, alignSelf: 'center', fontWeight: '600', fontSize: 14, opacity: loading ? 0 : 1 }}>
						{children}
					</CText>
				) : children
			}
		</CPressable>
	)
}
