import React, { useEffect, useMemo, useState } from 'react'
import { Platform, View } from 'react-native'
import type { PlansTabScreenProps } from '../../../routes/types'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { CLoadingSpinner, CText, StripePaymentSheet } from '../../../components'
import { useCTheme } from '../../../hooks/useTheme'
import { PlansData } from '..'
import { PaymentSheet } from '../../../components/PaymentForm/types'
import { checkPlanPurchaseAPI, getPlanPurchaseAPI } from '../../../api/purchases'
import { useNavigation } from '@react-navigation/native'
import { useAuthStore } from '../../../stores/auth'
import { useResponsive } from '../../../hooks/useResponsive'
import { showMessage } from 'react-native-flash-message'
import e from 'connect-flash'

const PlansPurchaseConfirmation = ({
	route,
	navigation
}: PlansTabScreenProps<'PlansPurchaseConfirmationNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [paymentSheetData, setPaymentSheetData] = useState<PaymentSheet>()
	const theme = useCTheme()
	const authStore = useAuthStore()
	const {breakpoints} = useResponsive()
	const plan = useMemo(() => {
		return PlansData.find(p => p.id === route?.params?.id)
	}, [route?.params?.id])
	const yearId =route?.params?.yearId
	const intervalRef = React.useRef<NodeJS.Timeout>()


	const checkPaymentPurchase = () => {
		if(!plan || !yearId) {
			return
		}
		checkPlanPurchaseAPI(authStore)(plan.id, yearId)
			.then((data) => {
				navigation.navigate('HomeNavigation' as any, {
					screen: 'HomeIndexNavigation',
					params: {
						yearId
					}
				})
				showMessage({
					message: t('payment_success'),
					type: 'success'
				})
				clearInterval(intervalRef.current)
			})
			.catch((err) => {

			})
	}

	useEffect(() => {
		if(!plan || !yearId) {
			navigation.navigate('HomeNavigation' as any)
			return 
		}
		const interval = setInterval(() => {
			checkPaymentPurchase()
		}, 3000)

		intervalRef.current = interval
		return () => {
			clearInterval(interval)
		}

	}, [plan, yearId])

	if (!plan) {
		return null
	}

	return (
		<View style={{ 
			flex: 1,
			maxWidth: breakpoints.sm,
			alignSelf: 'center',
			width: '100%',
			}}>
			<CLoadingSpinner />
		</View>
	)
}

export default PlansPurchaseConfirmation
