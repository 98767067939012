import React from 'react'
import {
	StyleSheet,
	type TextInputProps,
	View,
	type ViewStyle
} from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { useCTheme } from '../../hooks/useTheme'
import { CText } from '../CText'

export const formatInputToCurrency = (value: string) => {
	// Remove all commas but the first one, and remove all decimals but the first two

	const filteredValue = value.replaceAll(/[^0-9,]/g, '').replaceAll(/\./g, '').replaceAll(/,/g, '.')

	let valueWithoutExtraCommas = filteredValue.split('.').reduce((prev, curr, i) => prev + (i == 1 ? '.' : '') + curr);
	const [integer, decimals] = valueWithoutExtraCommas.split('.')

	// Remove leading zeros
	if(filteredValue.startsWith('0') && filteredValue.length > 1) {
		valueWithoutExtraCommas = valueWithoutExtraCommas.replace(/^0+/, '')
	}

	// If value starts with ., add a 0 before it
	if (valueWithoutExtraCommas.startsWith('.')) {
		valueWithoutExtraCommas = '0' + valueWithoutExtraCommas
	}

	if (decimals?.length > 2) {
		//shorten string by decimals.length - 2	
		return valueWithoutExtraCommas.slice(0, filteredValue.length - (decimals.length - 2))
	}

	return valueWithoutExtraCommas
}

export const formatCurrencyToInput = (value?: string) => {

	if (value === undefined) {
		return ''
	}
	// Replace the dot with a comma
	const filteredValue = value.replaceAll('.', ',')

	// Add a dot each 3 digits
	let commaIndex = filteredValue.indexOf(',')
	if (commaIndex === -1) {
		commaIndex = filteredValue.length
	}
	const integer = filteredValue.slice(0, commaIndex)
	let decimals = filteredValue.slice(commaIndex, filteredValue.length)

	const integerWithDots = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	// Replace from 0 to commaIndex with integerWithDots in original string
	return integerWithDots + decimals

}

export const CCurrencyInput = React.forwardRef(
	(
		props: TextInputProps & { headerText?: string, containerStyle?: ViewStyle, errors?: string[] },
		ref: any
	) => {
		const { style, headerText, containerStyle, value, onChangeText, ...rest } = props
		const theme = useCTheme()

		const styles = StyleSheet.create({
			textInput: {
				borderTopWidth: StyleSheet.hairlineWidth,
				borderBottomWidth: StyleSheet.hairlineWidth,
				borderColor: theme.colors.border,
				paddingHorizontal: 10,
				paddingVertical: 5,
				backgroundColor: 'transparent', 
				outlineStyle: 'none',
				color: theme.colors.text,
				textAlign: 'left',
				fontSize: 24,
				fontWeight: '300',
				maxWidth: 200,
				flex: 1
			},
			textInputHeader: {
				color: theme.colors.text,
				marginBottom: 5,
				fontWeight: '600'
			},
		})

		return (
			<View style={[containerStyle]}>
				{headerText && (
					<CText style={styles.textInputHeader}>{headerText}</CText>
				)}
				<View style={{
					flexDirection: 'row',
					alignItems: 'center',
					gap: 10,
				}}>
					<CText style={{ alignSelf: 'center', fontSize: 28, color: theme.colors.text }}>€</CText>
					<TextInput
						placeholderTextColor={theme.appColors.placeholderText}
						ref={ref}
						keyboardType='decimal-pad'
						style={[styles.textInput, style, props.errors && props.errors.length > 0 && { borderColor: theme.appColors.error }]}
						value={formatCurrencyToInput(value)}
						onChangeText={(text) => {
							onChangeText && onChangeText(formatInputToCurrency(text))
						}}
						{...rest}
					/>
				</View>
				{props.errors && props.errors.map((error) => (
					<CText key={error} style={{ color: theme.appColors.error, marginTop: 10 }}>{error}</CText>
				))}
			</View>
		)
	}
)

CCurrencyInput.displayName = 'CCurrencyInput'
