import { type BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { DrawerContentScrollView } from '@react-navigation/drawer'
import React, { useMemo } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withSpring,
	withTiming
} from 'react-native-reanimated'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { useCTheme } from '../../hooks/useTheme'
import { CPressable } from '../CPressable'
import { CText } from '../CText'
import { useReduceMotion } from '../../hooks/useReduceMotion'
import Ionicon from '@expo/vector-icons/Ionicons'
import { getNavigationIcon } from '../../helpers/icons'

export const CDrawer = (props: BottomTabBarProps) => {
	const { state, descriptors, navigation } = props
	const { t } = React.useContext(LocalizationContext)
	const theme = useCTheme()
	const { reduceMotionEnabled } = useReduceMotion()

	const visibleRoutes = useMemo(() => {
		return state.routes.filter((route) => {
			const { options } = descriptors[route.key]
			return (options as any).tabBarVisible !== false
		})
	}, [state])

	const visibleRouteIndex = useMemo(() => {
		return visibleRoutes.findIndex(
			(route) => route.key === state.routes[state.index].key
		)
	}, [state.index])

	const selectedIndex = useSharedValue(visibleRouteIndex)
	const itemHeight = useSharedValue(0)

	React.useEffect(() => {
		selectedIndex.value = visibleRouteIndex
	}, [visibleRouteIndex])

	const animatedItemHeight = useAnimatedStyle(() => {
		if (reduceMotionEnabled) {
			return {
				height: itemHeight.value - 10
			}
		} else {
			return {
				height: withTiming(itemHeight.value - 10, { duration: 0 })
			}
		}
	})

	const tabIndicatorStyle = useAnimatedStyle(() => {
		const options =
			descriptors[visibleRoutes[selectedIndex.value]?.key]?.options
		if (reduceMotionEnabled) {
			return {
				opacity: selectedIndex.value === -1 ? 0 : 1,
				transform: [
					{
						translateY:
							selectedIndex.value !== -1
								? itemHeight.value * selectedIndex.value
								: 0
					}
				],
				backgroundColor: (options as any)?.color || theme.colors.text
			}
		} else {
			return {
				opacity: withSpring(selectedIndex.value === -1 ? 0 : 1, {
					overshootClamping: false
				}),
				transform: [
					{
						translateY: withSpring(
							selectedIndex.value !== -1
								? itemHeight.value * selectedIndex.value
								: 0,
							{ damping: 10 }
						)
					}
				],
				backgroundColor: withTiming(
					(options as any)?.color || theme.colors.text,
					{}
				)
			}
		}
	})

	const tabBackgroundStyle = useAnimatedStyle(() => {
		const options =
			descriptors[visibleRoutes[selectedIndex.value]?.key]?.options
		if (reduceMotionEnabled) {
			return {
				backgroundColor: (options as any)?.color || theme.colors.text,
				opacity: selectedIndex.value === -1 ? 0 : 0.5,
				transform: [
					{
						translateY:
							selectedIndex.value !== -1
								? itemHeight.value * selectedIndex.value
								: 0
					}
				]
			}
		} else {
			return {
				backgroundColor: withTiming(
					(options as any)?.color || theme.colors.text,
					{}
				),
				opacity: withSpring(selectedIndex.value === -1 ? 0 : 0.5, {
					overshootClamping: false
				}),
				transform: [
					{
						translateY: withSpring(
							selectedIndex.value !== -1
								? itemHeight.value * selectedIndex.value
								: 0,
							{ damping: 15 }
						)
					}
				]
			}
		}
	})

	return (
		<DrawerContentScrollView
			{...props}
			contentContainerStyle={{
				flex: 1
			}}
			style={{
				backgroundColor: theme.colors.card,
				borderRightColor: theme.colors.border,
				borderRightWidth: StyleSheet.hairlineWidth
			}}
		>
			<View
				style={{
					marginVertical: 20
				}}
			>
				<Image
					source={require('../../../assets/icon.png')}
					style={{
						width: '75%',
						height: '100%',
						aspectRatio: 1,
						alignSelf: 'center'
					}}
				/>
			</View>

			<View
				style={{
					flex: 1
				}}
			>
				<View>
					<Animated.View
						style={[
							tabBackgroundStyle,
							animatedItemHeight,
							{
								position: 'absolute',
								top: 0,
								left: 0,
								backgroundColor: theme.colors.primary,
								width: '90%',
								marginHorizontal: 5,
								borderRadius: 5,
								marginTop: 5
							}
						]}
					/>
					<Animated.View
						style={[
							tabIndicatorStyle,
							{
								position: 'absolute',
								right: 0,
								top: 0,
								backgroundColor: theme.colors.primary,
								height: `${100 / visibleRoutes.length}%`,
								width: 5,
								borderTopLeftRadius: 5,
								borderBottomLeftRadius: 5
							}
						]}
					/>
					{visibleRoutes.map((route, index) => {
						const { options } = descriptors[route.key]

						const isFocused = state.index === index

						const onPress = () => {
							navigation.emit({
								type: 'tabPress',
								target: route.key,
								canPreventDefault: true
							})
							navigation.navigate(route.name)
						}

						const onLongPress = () => {
							navigation.emit({
								type: 'tabLongPress',
								target: route.key
							})
						}

						const element = (
							<View
								onLayout={(e) => {
									if (index === 0) {
										itemHeight.value = e.nativeEvent.layout.height
									}
								}}
								style={{
									paddingHorizontal: 10,
									paddingVertical: 5
								}}
								key={route.name}
							>
								<CPressable
									style={[
										(options as any)?.drawerItemStyle,
										{
											paddingVertical: 15,
											paddingHorizontal: 5,
											borderRadius: 5
										}
									]}
									accessibilityRole="button"
									accessibilityState={
										isFocused ? { selected: true } : { selected: false }
									}
									accessibilityLabel={options.tabBarAccessibilityLabel}
									testID={options.tabBarTestID}
									onPress={onPress}
									onLongPress={onLongPress}
								>
									<View
										key={index}
										style={{
											width: '100%',
											alignItems: 'center',
											flexDirection: 'row'
										}}
									>
										<Ionicon
											name={getNavigationIcon(route.name, isFocused)}
											size={24}
											color={theme.colors.text}
											style={{ textAlign: 'center' }}
										/>
										<CText
											style={{
												fontWeight: isFocused ? '700' : 'normal',
												color: theme.colors.text,
												marginLeft: 15
											}}
											numberOfLines={1}
										>
											{t(route.name)}
										</CText>
									</View>
								</CPressable>
							</View>
						)

						return element
					})}
				</View>
			</View>
		</DrawerContentScrollView>
	)
}
