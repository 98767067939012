import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'

export const getFormsAPI = (authStore: AuthStore) =>
	async (formId: string, yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/forms/${formId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const postFormsAPI = (authStore: AuthStore) =>
	async (formId: string, yearId: string, yearDraftId: string, responses: any) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/forms/${formId}/drafts`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				responses
			})
		})
			.then(processResponse)
	}

export const deleteFormsAPI = (authStore: AuthStore) =>
	async (formId: string, yearId: string, yearDraftId: string, draftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/forms/${formId}/drafts/${draftId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}
