import Constants from 'expo-constants'

const extraConfigManifest = Constants.manifest?.extra as Record<string, string> | undefined
const extraConfig = Constants.expoConfig?.extra as Record<string, string> | undefined

const env = extraConfigManifest?.ENVIRONMENT || extraConfig?.ENVIRONMENT || 'development'

export const API_DOMAIN = env === 'production' ? 'https://rentas.measesoran.com/api' : 'http://localhost:3001'
export const APP_DOMAIN = env === 'production' ? 'https://rentas.measesoran.com' : 'http://localhost:19006'

