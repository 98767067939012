import React, { useContext, useRef, useState } from 'react'
import { Platform, View } from 'react-native'
import { ReviewTabScreenProps } from '../../../routes/types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { type SectionAPIResponse } from '../types'
import { FormScreen } from './form'
import { FlatList, ScrollView } from 'react-native-gesture-handler'
import { useResponsive } from '../../../hooks/useResponsive'
import { useAuthStore } from '../../../stores/auth'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { showMessage } from 'react-native-flash-message'
import { useCTheme } from '../../../hooks/useTheme'
import { CLoadingSpinner } from '../../../components'
import { getReviewSectionsNextFormAPI } from '../../../api/reviews'

const ReviewSection = ({
	route,
	navigation
}: ReviewTabScreenProps<'ReviewSectionNavigation'>) => {
	const sectionId = route.params?.sectionId
	const yearId = route.params?.yearId
	const yearDraftId = route.params?.yearDraftId
	const [formScreens, setFormScreens] = useState<Array<{id: string | null}>>([])
	const { breakpoints, isMasterDetailDrawerEnabled } = useResponsive()
	const theme = useCTheme()
	const paginatorRef = React.useRef<FlatList>(null)
	const [lastFormId, setLastFormId] = React.useState<string | undefined>(undefined)
	const [itemsWidth, setItemsWidth] = useState<number>(breakpoints.sm)

	const queryClient = useQueryClient()

	const authStore = useAuthStore()
	const {t} = useContext(LocalizationContext)

	const { data: sectionsData } = useQuery({
		queryKey: ['review-sections', yearDraftId],
		refetchOnMount: false
	})

	const sectionWithDraft = (sectionsData as [SectionAPIResponse] | null)?.find((s) => s.section.id === sectionId)

	React.useEffect(() => {
		if (!sectionWithDraft) {
			return
		}

		navigation.setOptions({
			title: `${sectionWithDraft?.section.name ?? ''}`
		})

	}, [sectionWithDraft])


	const { data: nextFormData, error: nextFormError, isLoading: isNextFormLoading } = useQuery({
		queryKey: ['review-next-form', lastFormId],
		queryFn: async () => await getReviewSectionsNextFormAPI(authStore)(yearId, yearDraftId, sectionId, lastFormId),
		refetchOnMount: false,
	})

	React.useEffect(() => {
		if (nextFormData) {
			setFormScreens((prev) => {
				const index = prev.findIndex((f) => f.id === nextFormData.nextFormId)
				if (index !== -1) {
					return prev.slice(0, index + 1)
				}	
				
				if(nextFormData.sectionDraft) {
					queryClient.setQueryData(['review-sections', yearDraftId], (prev: any) => {
						if (!prev) {
							return null
						}
						const index = prev.findIndex((s: any) => s.section.id === nextFormData.sectionDraft.sectionId)
						if (index === -1) {
							return prev
						}
						return [
							...prev.slice(0, index),
							{
								...prev[index],
								draft: nextFormData.sectionDraft,
								enabled: true
							},
							...prev.slice(index + 1)
						]
					})
				}

				if (nextFormData.refreshSections) {
					queryClient.invalidateQueries(['review-sections', yearDraftId])	
				}

				return [...prev, {
					id: nextFormData.nextFormId
				}]
			})
		}
	}, [nextFormData])

	React.useEffect(() => {
		if (nextFormError) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [nextFormError])

	React.useEffect(() => {
		if (formScreens.length > 1) {
			paginatorRef.current?.scrollToIndex({ index: formScreens.length - 1, animated: false })
		}
	}, [formScreens])



	if (!sectionId || !sectionWithDraft || formScreens.length === 0) {
		return null
	}


	return (
		<View onLayout={
			(event) => {
				setItemsWidth(event.nativeEvent.layout.width)
			}
		} style={{
			flex: 1,
			maxHeight: '100%',
			position: 'relative'
		}}>
			<FlatList
				ref={paginatorRef}
				data={formScreens}
				horizontal
				scrollEnabled={false}
				getItemLayout={(data, index) => ({
					length: itemsWidth ?? 0,
					offset: (itemsWidth ?? 0) * index,
					index
				})}
				showsHorizontalScrollIndicator={false}
				keyExtractor={(item, index) => item.id}
				renderItem={({ item, index }) => (
					<View style={{
						overflow: 'auto' as 'scroll',
					}}>
						<FormScreen
							width={itemsWidth ?? 0}
							hasBackButton={formScreens?.[index - 1] != null}
							sectionId={sectionId}
							yearId={yearId}
							yearDraftId={yearDraftId}
							isNextFormLoading={isNextFormLoading}
							formId={item.id}
							onNext={() => {
								if (formScreens.length > index + 1) {
									return
								}
								setLastFormId(item.id)
							}}
							onBack={() => {
								// remove all screens after the current one, including the current one
								paginatorRef.current?.scrollToIndex({ index: index - 1, animated: false })
								queryClient.removeQueries(['review-next-form', formScreens[index - 1].id])
								setLastFormId(formScreens?.[index - 2]?.id ?? undefined)
							}}
						/>
					</View>
				)}
			/>

			{isNextFormLoading && <View style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							height: '100%',
							backgroundColor: 'rgba(255, 255, 255, 0.5)',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
				<CLoadingSpinner/>
			</View>}
		</View>
	)
}

export default ReviewSection
