import React, { useContext, useEffect, useState } from 'react'
import { Button, FlatList, Modal, ScrollView, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import type { AdminTabScreenProps, DeclarationTabScreenProps } from '../../../routes/types'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { CText, CPressable, CLoadingSpinner, CButton } from '../../../components'
import { useCTheme } from '../../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSectionsAPI } from '../../../api/sections'
import { useAuthStore } from '../../../stores/auth'
import { showMessage } from 'react-native-flash-message'
import { Form, Section, type SectionAPIResponse } from '../types'
import { StackActions } from '@react-navigation/native'
import { getYearsXMLAPI } from '../../../api/years'
import { getAdminFormAPI, getAdminFormsAPI, getAdminSectionsAPI, postAdminSectionFormAPI } from '../../../api/admin'
import { useResponsive } from '../../../hooks/useResponsive'
import AntDesign from '@expo/vector-icons/AntDesign'

const EnumQuestionLocks = ({
	formId,
	blockedQuestions,
	neededQuestions,
	addItem,
	removeItem,
	yearId,
}: {
	yearId: string
	formId: string
	blockedQuestions: string[]
	neededQuestions: string[]
	addItem: (itemType: 'form' | 'question', logicType: 'needs' | 'blocks', itemId: string) => void
	removeItem: (itemType: 'form' | 'question', logicType: 'needs' | 'blocks', itemId: string) => void
}) => {
	const { t } = React.useContext(LocalizationContext)
	const theme = useCTheme()
	const { breakpoints } = useResponsive()
	const [form, setForm] = useState<Form>()
	const [selectedId, setSelectedId] = useState<string | null>(null)
	const authStore = useAuthStore()

	const { data: formData, error: formError, isLoading: isFormLoading } = useQuery({
		queryKey: ['admin-form-locks', formId],
		queryFn: async () => await getAdminFormAPI(authStore)(yearId, formId),
		enabled: !!formId
	})

	useEffect(() => {
		if(formData?.form){
			setForm(formData.form)
		}
	}, [formData])

	useEffect(() => {
		if (formError) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}
	, [formError])



	if (!form || isFormLoading) {
		return <View style={{
			maxWidth: breakpoints.md
		}}><CLoadingSpinner/></View>
	}


	return (
		<ScrollView>
			<FlatList
				extraData={[theme, form]}
				ListHeaderComponent={() => (
					<View
						style={{
							marginTop: 10
						}}
					/>
				)}
				data={form?.questions ?? []}
				keyExtractor={(item) => item.id}
				renderItem={({ item }) => {
					const isNeeded = neededQuestions.includes(item.id)
					const isBlocked = blockedQuestions.includes(item.id)
					return (
						<View
							style={{
								flexDirection: 'row',
								backgroundColor:
									// @ts-ignore
									selectedId === item.id
										? theme.colors.card
										: undefined,
								borderRadius: 10,
								marginHorizontal: 10 
							}}
						>
							<View
								style={{
									padding: 10,
									paddingVertical: 15,
									flex: 1
								}}
							>
								<CText numberOfLines={1} style={{}}>
									{item.title}
								</CText>
							</View>
							<CPressable onPress={() => {
								if(!isNeeded){
									addItem('question', 'needs', item.id)
									if(isBlocked){
										removeItem('question', 'blocks', item.id)
									}
								} else {
									removeItem('question', 'needs', item.id)
								}
							}}>
								<AntDesign name="check" size={24} color={isNeeded ? 'green' : theme.colors.text} />
							</CPressable>
							<CPressable onPress={() => {
								if(!isBlocked){
									addItem('question', 'blocks', item.id)
									if(isNeeded){
										removeItem('question', 'needs', item.id)
									}
								} else {
									removeItem('question', 'blocks', item.id)
								}

							}}>
								<AntDesign name="lock" size={24} color={isBlocked ? 'tomato' : theme.colors.text} />
							</CPressable>
							</View>
						)
					}
				}
			/>
		</ScrollView>
	)
}

const EnumFormLocks = ({
	sectionId,
	yearId,
	blockedForms,
	blockedQuestions,
	neededForms,
	neededQuestions,
	addItem,
	removeItem
}: {
	sectionId: string
	yearId: string
	blockedForms: string[]
	blockedQuestions: string[]
	neededForms: string[]
	neededQuestions: string[]
	addItem: (itemType: 'form' | 'question', logicType: 'needs' | 'blocks', itemId: string) => void
	removeItem: (itemType: 'form' | 'question', logicType: 'needs' | 'blocks', itemId: string) => void
}) => {
	const { breakpoints } = useResponsive()
	const paginatorRef = React.useRef<FlatList>(null)
	const [lastFormId, setLastFormId] = React.useState<string | undefined>(undefined)
	const [itemsWidth, setItemsWidth] = useState<number>(breakpoints.sm)
	const theme = useCTheme()
	const [selectedId, setSelectedId] = useState<string | null>(null)

	const queryClient = useQueryClient()

	const authStore = useAuthStore()
	const { t } = useContext(LocalizationContext)

	const { data: formsData, error: formsError, isLoading: formsLoading } = useQuery({
		queryKey: ['admin-section-forms-locks', sectionId],
		queryFn: async () => await getAdminFormsAPI(authStore)(yearId, sectionId),
	})

	React.useEffect(() => {
		if (formsError) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [formsError])




	if (!sectionId || !formsData) {
		return null
	}


	return (
		<View style={{
			flexDirection: 'row',
			flex: 1,
		}}>
			<View style={{
				maxWidth: 300,
				width: '100%',
				flex: 1,
			}}>
				<ScrollView>
					<FlatList
						extraData={[theme, sectionId]}
						ListHeaderComponent={() => (
							<View
								style={{
									marginTop: 10
								}}
							/>
						)}
						data={formsData?.forms ?? []}
						keyExtractor={(item) => item.id}
						renderItem={({ item }) => {
							const isNeeded = neededForms.includes(item.id)
							const isBlocked = blockedForms.includes(item.id)
							return (
								<View
									style={{
										flexDirection: 'row',
										backgroundColor:
											selectedId === item.id
												? theme.colors.card
												: undefined,
										borderRadius: 10,
										marginHorizontal: 10 
									}}
								>
									<CPressable
										onPress={() => {
											setSelectedId(item.id)
										}}
										style={{
											padding: 10,
											paddingVertical: 15,
											flex: 1
										}}
									>
										<CText numberOfLines={1} style={{}}>
											{item.title}
										</CText>
									</CPressable>
									<CPressable onPress={() => {
										if(!isNeeded){
											addItem('form', 'needs', item.id)
											if(isBlocked){
												removeItem('form', 'blocks', item.id)
											}
										} else {
											removeItem('form', 'needs', item.id)
										}
									}}>
										<AntDesign name="check" size={24} color={isNeeded ? 'green' : theme.colors.text} />
									</CPressable>
									<CPressable onPress={() => {
										if(!isBlocked){
											addItem('form', 'blocks', item.id)
											if(isNeeded){
												removeItem('form', 'needs', item.id)
											}
										} else {
											removeItem('form', 'blocks', item.id)
										}

									}}>
										<AntDesign name="lock" size={24} color={isBlocked ? 'tomato' : theme.colors.text} />
									</CPressable>
									<View
										style={{
											flexDirection: 'row',
											alignSelf: 'center',
											justifyContent: 'center',
											marginHorizontal: 10
										}}
									>
										{selectedId === item.id
											? (
												<Ionicons
													name="chevron-forward"
													size={18}
													color={theme.colors.text}
												/>
											)
											: null}
									</View>
								</View>
							)
						}}
					/>
				</ScrollView>
			</View>
			{(selectedId) && (
				<ScrollView onLayout={
					(event) => {
						setItemsWidth(event.nativeEvent.layout.width)
					}
				} style={{
					flex: 1,
					width: '100%',
					maxWidth: itemsWidth,
					maxHeight: '100%',
				}}>
					<EnumQuestionLocks
						yearId={yearId}
						formId={selectedId}
						blockedQuestions={blockedQuestions}
						neededQuestions={neededQuestions}
						addItem={addItem}
						removeItem={removeItem}
					/>
				</ScrollView>
			)}
		</View>
	)
}

const EnumSectionLocks = ({
	yearId,
	onHide,
	addItem,
	removeItem,
	neededForms,
	neededQuestions,
	blockedForms,
	blockedQuestions
}: {
	yearId: string
	onHide: () => void
	addItem: (itemType: 'form' | 'question', logicType: 'needs' | 'blocks', itemId: string) => void
	removeItem: (itemType: 'form' | 'question', logicType: 'needs' | 'blocks', itemId: string) => void
	neededForms: string[]
	neededQuestions: string[]
	blockedForms: string[]
	blockedQuestions: string[]
}) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedSectionId, setSelectedSectionId] = React.useState<string | null>(null)
	const theme = useCTheme()

	const queryClient = useQueryClient()

	const authStore = useAuthStore()

	const { data, error, isLoading } = useQuery({
		queryKey: ['admin-sections-locks'],
		queryFn: async () => await getAdminSectionsAPI(authStore)(yearId)
	})

	React.useEffect(() => {
		if (error) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [error])

	if (isLoading) {
		return <CLoadingSpinner/>
	}

	if (error) {
		return null
	}

	const sections: [Section] = data?.sections ?? []

	return (
		<Modal 
			transparent
			animationType='slide'>
				<View style={[
					StyleSheet.absoluteFill,
					{
						backgroundColor: theme.colors.background,
						opacity: 0.9
					}
				]}/>
			<View style={{
				backgroundColor: theme.colors.card,
				borderRadius: 10,
				margin: 100,
				flex: 1,
			}}>
				<CPressable onPress={onHide} style={{
					alignSelf: 'flex-start',
					padding: 10,
					paddingVertical: 15,
				}}>
					<AntDesign name="close" size={24} color={theme.colors.text} />
				</CPressable>
				<View style={{
					flexDirection: 'row',
					flex: 1,
				}}>
					<ScrollView style={{
						width: 250,
						maxWidth: 250,
					}}>
						<FlatList
						extraData={[theme]}
						ListHeaderComponent={() => (
							<View
								style={{
									marginTop: 10
								}}
							/>
						)}
						data={sections}
						keyExtractor={(item) => item.id}
						renderItem={({ item }) => {
							return (
								<View
									style={{
										flexDirection: 'row',
										borderRadius: 10,
										marginHorizontal: 10
									}}
								>
									<CPressable
										style={{
											padding: 10,
											paddingVertical: 15,
											flex: 1
										}}
										onPress={() => {
											setSelectedSectionId(item.id)
										}}
									>
										<CText numberOfLines={1} style={{}}>
											{item.name}
										</CText>
									</CPressable>
									<View
										style={{
											flexDirection: 'row',
											alignSelf: 'center',
											justifyContent: 'center',
											marginHorizontal: 10
										}}
									>
										{selectedSectionId === item.id
											? (
												<Ionicons
													name="chevron-forward"
													size={18}
													color={theme.colors.text}
												/>
											)
											: null}
									</View>
								</View>
							)
						}}
					/>
				</ScrollView>
				{(selectedSectionId) && (
					<EnumFormLocks
					sectionId={selectedSectionId}
					yearId={yearId}
					blockedForms={blockedForms}
					blockedQuestions={blockedQuestions}
					neededForms={neededForms}
					neededQuestions={neededQuestions}
					addItem={addItem}
					removeItem={removeItem}
				/>
				)}
			</View>
		</View>
	</Modal>
	)
}

export default EnumSectionLocks
