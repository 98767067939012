import React from 'react'
import Home from '../app/home'
import Documents from '../app/document'
import Plans from '../app/plan'
import Profile from '../app/profile'

import { LocalizationContext } from '../contexts/LocalizationContext'
import { CDrawer, CModalHeader } from '../components'
import HomeYear from '../app/home/year'
import { createMasterDetailNavigator } from '../navigation/master-detail-navigator'
import { createResponsiveTabNavigator } from '../navigation/responsive-tab-navigator'
import {
	createStackNavigator,
	HeaderStyleInterpolators
} from '@react-navigation/stack'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useResponsive } from '../hooks/useResponsive'
import { useCTheme } from '../hooks/useTheme'
import ProfileAccount from '../app/profile/account/index'
import ProfileFeedback from '../app/profile/feedback'
import ProfileHelp from '../app/profile/help'
import { CBottomTabBar } from '../components/CBottomTabBar'
import PlansCheckout from '../app/plan/checkout.tsx'
import PlansPurchaseConfirmation from '../app/plan/purchase-confirmation'
import AuthLogin from '../app/auth'
import Declaration from '../app/declaration'
import DeclarationSection from '../app/declaration/section'
import Admin from '../app/admin'
import AdminSection from '../app/admin/section'
import Review from '../app/review'
import ReviewSection from '../app/review/section'

const ResponsiveTabNavigator = createResponsiveTabNavigator()
const AuthStack = createStackNavigator()

const HomeNavigator = createMasterDetailNavigator()
const DocumentsNavigator = createStackNavigator()
const PlansNavigator = createNativeStackNavigator()
const ProfileNavigator = createMasterDetailNavigator()
const DeclarationNavigator = createMasterDetailNavigator()
const ReviewNavigator = createMasterDetailNavigator()
const AdminNavigator = createMasterDetailNavigator()

const HomeRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	const { isMasterDetailDrawerEnabled } = useResponsive()

	return (
		<HomeNavigator.Navigator
			fullScreenMasterWhenNoDetail={false}
			isDetailVisible={isMasterDetailDrawerEnabled}
			masterStyle={{
				width: 300
			}}
			screenOptions={{
				headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
				cardShadowEnabled: true,
				cardOverlayEnabled: true
			}}
		>
			<HomeNavigator.Screen
				options={{ title: t('HomeIndexNavigation') }}
				name="HomeIndexNavigation"
				component={Home as any}
			/>
			<HomeNavigator.Screen
				options={{ title: t('HomeYearNavigation') }}
				name="HomeYearNavigation"
				component={HomeYear as any}
			/>
		</HomeNavigator.Navigator>
	)
}

const DocumentsRoutes = () => {
	const { t } = React.useContext(LocalizationContext)

	return (
		<DocumentsNavigator.Navigator
			screenOptions={{
				headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
				cardShadowEnabled: true,
				cardOverlayEnabled: true
			}}
		>
			<DocumentsNavigator.Screen
				options={{ title: t('DocumentsIndexNavigation') }}
				name="DocumentsIndexNavigation"
				component={Documents as any}
			/>
		</DocumentsNavigator.Navigator>
	)
}

const PlansRoutes = () => {
	const { t } = React.useContext(LocalizationContext)

	return (
		<PlansNavigator.Navigator>
			<PlansNavigator.Screen
				options={{ title: t('PlansIndexNavigation') }}
				name="PlansIndexNavigation"
				component={Plans as any}
			/>
			<PlansNavigator.Group
				screenOptions={{
					presentation: 'formSheet',
					header: (props) => <CModalHeader {...props} />
				}}
			>
				<PlansNavigator.Screen
					options={{ title: t('PlansCheckoutNavigation') }}
					name="PlansCheckoutNavigation"
					component={PlansCheckout as any}
				/>
			</PlansNavigator.Group>
			<PlansNavigator.Screen
				options={{ title: t('PlansPurchaseConfirmationNavigation'), headerLeft: () => null}}
				name="PlansPurchaseConfirmationNavigation"
				component={PlansPurchaseConfirmation as any}
			/>
		</PlansNavigator.Navigator>
	)
}

const ProfileRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	const { isMasterDetailDrawerEnabled } = useResponsive()

	return (
		<ProfileNavigator.Navigator
			fullScreenMasterWhenNoDetail={false}
			isDetailVisible={isMasterDetailDrawerEnabled}
			masterStyle={{
				width: 300
			}}
			screenOptions={{
				headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
				animationEnabled: !isMasterDetailDrawerEnabled,
				cardShadowEnabled: true,
				cardOverlayEnabled: true
			}}
		>
			<ProfileNavigator.Screen
				options={{ title: t('ProfileIndexNavigation') }}
				name="ProfileIndexNavigation"
				component={Profile as any}
			/>
 			<ProfileNavigator.Screen
				options={{ title: t('ProfileAccountNavigation') }}
				name="ProfileAccountNavigation"
				component={ProfileAccount as any}
			/>
			{/* <ProfileNavigator.Screen
				options={{ title: t('ProfileFeedbackNavigation') }}
				name="ProfileFeedbackNavigation"
				component={ProfileFeedback as any}
			/> */}
			<ProfileNavigator.Screen
				options={{ title: t('ProfileHelpNavigation') }}
				name="ProfileHelpNavigation"
				component={ProfileHelp as any}
			/>
		</ProfileNavigator.Navigator>
	)
}

const DeclarationRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	const { isMasterDetailDrawerEnabled } = useResponsive()

	return (
		<DeclarationNavigator.Navigator
			fullScreenMasterWhenNoDetail={false}
			isDetailVisible={isMasterDetailDrawerEnabled}
			masterStyle={{
				width: 300
			}}
			screenOptions={{
				headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
				cardShadowEnabled: true,
				cardOverlayEnabled: true,
			}}
		>
			<DeclarationNavigator.Screen
				options={{ title: t('DeclarationIndexNavigation') }}
				name="DeclarationIndexNavigation"
				component={Declaration as any}
			/>
			<DeclarationNavigator.Screen
				options={{ title: t('DeclarationSectionNavigation') }}
				name="DeclarationSectionNavigation"
				component={DeclarationSection as any}
			/>
		</DeclarationNavigator.Navigator>
	)
}
const ReviewRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	const { isMasterDetailDrawerEnabled } = useResponsive()

	return (
		<ReviewNavigator.Navigator
			fullScreenMasterWhenNoDetail={false}
			isDetailVisible={isMasterDetailDrawerEnabled}
			masterStyle={{
				width: 300
			}}
			screenOptions={{
				headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
				cardShadowEnabled: true,
				cardOverlayEnabled: true
			}}
		>
			<ReviewNavigator.Screen
				options={{ title: t('ReviewIndexNavigation') }}
				name="ReviewIndexNavigation"
				component={Review as any}
			/>
			<ReviewNavigator.Screen
				options={{ title: t('ReviewSectionNavigation') }}
				name="ReviewSectionNavigation"
				component={ReviewSection as any}
			/>
		</ReviewNavigator.Navigator>
	)
}

const AdminRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	const { isMasterDetailDrawerEnabled } = useResponsive()

	return (
		<AdminNavigator.Navigator
			fullScreenMasterWhenNoDetail={false}
			isDetailVisible={isMasterDetailDrawerEnabled}
			masterStyle={{
				width: 220
			}}
			screenOptions={{
				headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
				cardShadowEnabled: true,
				cardOverlayEnabled: true
			}}
		>
			<AdminNavigator.Screen
				options={{ title: t('AdminIndexNavigation') }}
				name="AdminIndexNavigation"
				component={Admin as any}
			/>
			<AdminNavigator.Screen
				options={{ title: t('AdminSectionNavigation') }}
				name="AdminSectionNavigation"
				component={AdminSection as any}
			/>
		</AdminNavigator.Navigator>
	)
}

export const MainRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	const { isResponsiveTabDrawerEnabled, responsiveDrawerWidth } = useResponsive()
	const theme = useCTheme()
	return (
		<ResponsiveTabNavigator.Navigator
			initialRouteName="HomeNavigation"
			drawerContent={CDrawer}
			bottomTabMenu={CBottomTabBar}
			drawerStyle={{
				width: responsiveDrawerWidth
			}}
			responsiveMode={isResponsiveTabDrawerEnabled ? 'side' : 'bottom'}
			screenOptions={{
				headerShown: false
			}}
		>
			<ResponsiveTabNavigator.Screen
				options={
					{
						title: t('HomeNavigation'),
						color: theme.appColors.primary1
					} as any
				}
				name="HomeNavigation"
				component={HomeRoutes}
			/>
			{/* <ResponsiveTabNavigator.Screen
				options={
					{
						title: t('DocumentsNavigation'),
						color: theme.appColors.primary2
					} as any
				}
				name="DocumentsNavigation"
				component={DocumentsRoutes}
			/> */}
			<ResponsiveTabNavigator.Screen
				options={
					{
						title: t('PlansNavigation'),
						color: theme.appColors.primary3,
						tabBarVisible: false,
					} as any
				}
				name="PlansNavigation"
				component={PlansRoutes as any}
			/>
			<ResponsiveTabNavigator.Screen
				options={
					{
						title: t('ProfileNavigation'),
						color: theme.appColors.primary4
					} as any
				}
				name="ProfileNavigation"
				component={ProfileRoutes as any}
			/>

<ResponsiveTabNavigator.Screen
				options={
					{
						title: t('DeclarationNavigation'),
						tabBarVisible: false,
						bottomTabBarVisible: false,
						unmountOnBlur: true
					} as any
				}
				name="DeclarationNavigation"
				component={DeclarationRoutes}
			/>
			<ResponsiveTabNavigator.Screen
				options={
					{
						title: t('ReviewNavigation'),
						tabBarVisible: false,
						bottomTabBarVisible: false
					} as any
				}
				name="ReviewNavigation"
				component={ReviewRoutes}
			/>
			<ResponsiveTabNavigator.Screen
				options={
					{
						title: t('AdminNavigation'),
						tabBarVisible: false,
						bottomTabBarVisible: false
					} as any
				}
				name="AdminNavigation"
				component={AdminRoutes}
			/>
		</ResponsiveTabNavigator.Navigator>
	)
}

export const AuthRoutes = () => {
	const { t } = React.useContext(LocalizationContext)
	return (
		<AuthStack.Navigator
			screenOptions={{
				headerShown: false,
				cardShadowEnabled: true,
				cardOverlayEnabled: true
			}}
		>
			<AuthStack.Screen
				name="AuthLoginNavigation"
				options={{ title: t('AuthLoginNavigation') }}
				component={AuthLogin as any}
			/>
		</AuthStack.Navigator>
	)
}

export const linkingConfig = {
	screens: {
		AuthLoginNavigation: {
			path: 'login',
			initialRouteName: 'AuthLoginNavigation'
		},
		HomeNavigation: {
			path: 'home',
			initialRouteName: 'HomeIndexNavigation',
			screens: {
				HomeIndexNavigation: '',
				HomeYearNavigation: 'years/:yearId'
			}
		},
		DocumentsNavigation: {
			path: 'documents',
			initialRouteName: 'DocumentsIndexNavigation',
			screens: {
				DocumentsIndexNavigation: ''
			}
		},
		PlansNavigation: {
			path: 'plans',
			initialRouteName: 'PlansIndexNavigation',
			screens: {
				PlansIndexNavigation: ':yearId',
				PlansCheckoutNavigation: 'checkout',
				PlansPurchaseConfirmationNavigation: 'purchase-confirmation'
			}
		},
		ProfileNavigation: {
			path: 'profile',
			initialRouteName: 'ProfileIndexNavigation',
			screens: {
				ProfileIndexNavigation: '',
				ProfileAccountNavigation: 'account',
				ProfileFeedbackNavigation: 'feedback',
				ProfileHelpNavigation: 'help'
			}
		},
		DeclarationNavigation: {
			path: 'years',
			initialRouteName: 'DeclarationIndexNavigation',
			screens: {
				DeclarationIndexNavigation: ':yearId/drafts/:yearDraftId/sections',
				DeclarationSectionNavigation: ':yearId/drafts/:yearDraftId/sections/:sectionId'
			}
		},
		ReviewNavigation: {
			path: 'reviews/years',
			initialRouteName: 'ReviewIndexNavigation',
			screens: {
				ReviewIndexNavigation: ':yearId/drafts/:yearDraftId/sections',
				ReviewSectionNavigation: ':yearId/drafts/:yearDraftId/sections/:sectionId'
			}
		},
		AdminNavigation: {
			path: 'admin',
			initialRouteName: 'AdminIndexNavigation',
			screens: {
				AdminIndexNavigation: ':yearId',
				AdminSectionNavigation: ':yearId/sections/:sectionId'
			}
		}
	}
}
