import dayjs from "dayjs"
import React from "react"
import { StyleSheet, View } from "react-native"
import { CCurrencyInput, CDatePicker, CPressable, CText, CTextInput } from "../../../components"
import { LocalizationContext } from "../../../contexts/LocalizationContext"
import { useCTheme } from "../../../hooks/useTheme"
import { Question } from "../types"
import { spainIdType, validCIF, validDNI, validNIE, validateSpanishId } from "spain-id"
import { z } from "zod"
import validator from "validator"

interface QuestionProps {
	question: Question
	response: any
	error: any
	setResponse: (response: any) => void
	setError: (error: any) => void
}

export const numberRegex = /^-?[0-9]\d*(\.\d{1,2})?$/

export const formatInputToNumber = (value: string, allowNegative=false) => {
	// Remove all commas but the first one, and remove all decimals but the first two
	const filteredValue = value.replaceAll(/[^0-9,]/g, '').replaceAll(/\./g, '').replaceAll(/,/g, '.')

	const isNegative = value.includes('-')

	let valueWithoutExtraCommas = filteredValue.split('.').reduce((prev, curr, i) => prev + (i == 1 ? '.' : '') + curr);
	const [integer, decimals] = valueWithoutExtraCommas.split('.')

	// If negatives are allowed, and the value is negative, add a minus sign
	if (allowNegative && isNegative) {
		valueWithoutExtraCommas = '-' + valueWithoutExtraCommas
	}

	// Remove leading zeros
	if(filteredValue.startsWith('0') && filteredValue.length > 1) {
		valueWithoutExtraCommas = valueWithoutExtraCommas.replace(/^0+/, '')
	}

	// If value starts with ., add a 0 before it
	if (valueWithoutExtraCommas.startsWith('.')) {
		valueWithoutExtraCommas = '0' + valueWithoutExtraCommas
	}

	if (decimals?.length > 2) {
		//shorten string by decimals.length - 2	
		return valueWithoutExtraCommas.slice(0, filteredValue.length - (decimals.length - 2))
	}
	return valueWithoutExtraCommas
}

export const formatNumberToInput = (value: string) => {
	// Replace the dot with a comma
	const filteredValue = value.replaceAll('.', ',')

	// Add a dot each 3 digits
	let commaIndex = filteredValue.indexOf(',')
	if (commaIndex === -1) {
		commaIndex = filteredValue.length
	}
	const integer = filteredValue.slice(0, commaIndex)
	let decimals = filteredValue.slice(commaIndex, filteredValue.length)

	return integer + decimals

}

export const QuestionComponent = ({ question, response, error, setResponse, setError }: QuestionProps) => {
	const theme = useCTheme()
	const {t} = React.useContext(LocalizationContext)

	switch (question.datatype) {
	case 'text':
		return (
			<CTextInput
				keyboardType='default'
				textContentType='none'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text)
					setError(null)
				}}
				value={response}/>
		)
	case 'textarea':
		return (
			<CTextInput
				keyboardType='default'
				textContentType='none'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text)
					setError(null)
				}}
				multiline={true}
				numberOfLines={6}
				value={response}/>
		)
	case 'number':
		return (
			<CTextInput
				keyboardType='numeric'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					const formatedText = formatInputToNumber(text)
					const isNumber = numberRegex.test(formatedText)
					if (!isNumber && formatedText !== '') {
						setResponse(formatedText)
						setError(t('no_valid_number'))
						return
					} 
					setResponse(formatedText)
					setError(null)
				}}
				value={formatNumberToInput(response)}/>
		)
	case 'integer':
		return (
			<CTextInput
				keyboardType='numeric' // Disable comma in onChangeText
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					const number = parseInt(text, 10)
					if (isNaN(number) && text !== '') {
						return
					}
					setResponse(text)
					setError(null)
				}}
				value={response}/>
		)
	case 'currency':
		return (
			<CCurrencyInput
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					const isDecimal = numberRegex.test(text)
					if (!isDecimal && text !== '') {
						setResponse(text)
						setError(t('no_valid_currency'))
						return
					} 
					setResponse(text)
					setError(null)
				}}
				value={response}/>
		)
	case 'iban':
		return (
			<CTextInput
				keyboardType='default'
				textContentType='none'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text.toUpperCase()?.replace(/[^A-Z0-9]/g, ''))
					if(!validator.isIBAN(text)){
						setError(t('no_valid_iban'))
					} else {
						setError(null)
					}

				}}
				value={response}/>
		)
	case 'date':
		return (
			<CDatePicker
				errors={error ? [error] : []}
				value={dayjs(response).toDate().toString().toLocaleString()}
				onChange={(date) => {
					setResponse(date)
					if (!dayjs(date).isValid()) {
						setError(t('no_valid_date'))
					}else {
						setError(null)
					}
				}}/>
		)
	case 'boolean':
		return (
			<View style={{
				flex: 1,
				marginTop: 10
			}}>
				<CPressable onPress={() => { 
					setResponse(response === '1' ? '0' : '1')
					setError(null)
				 }}>
					<View style={{
						flexDirection: 'row',
						alignItems: 'center',
						gap: 10,
					}}>
						<View style={{
							height: 20,
							width: 20,
							borderRadius: 3,
							borderWidth: 1,
							borderColor: response === '1' ? theme.appColors.iosBlue : theme.colors.text,
							justifyContent: 'center',
							alignItems: 'center',
							aspectRatio: 1,
						}}>
							{response === '1' && (
								<View style={{
									height: 12,
									width: 12,
									borderRadius: 1,
									backgroundColor: theme.appColors.iosBlue
								}}/>
							)}
						</View>
						<CText>{question.title}</CText>
					</View>
				</CPressable>

			</View>
		)
	case 'radio':
			return (
				<View style={{
					gap: 10,
				}}>
					{question.responseEnums!.map((option) => {
						const isSelected = option.value === response
						const isOtherSelected = response != null

						return(
						<View key={option.id} style={{
							flex: 1,
						}}>
							<CPressable onPress={() => { 
								setResponse(option.value)
								setError(null)
								 }}>
								<View style={{
									flexDirection: 'row',
									gap: 10,
									width: '100%',
								}}>
									<View style={{
										justifyContent: 'center',
										alignItems: 'center',
										aspectRatio: 1,
										borderWidth: 1,
										height: 20,
										width: 20,
										borderRadius: 10,
										borderColor: isOtherSelected && !isSelected ? error ? theme.appColors.alert : theme.appColors.secondaryText : theme.appColors.iosBlue,		
									}}>
										{option.value === response && (
											<View style={{
												height: 10,
												width: 10,
												borderRadius: 5,
												backgroundColor: theme.appColors.iosBlue
											}}/>
										)}
									</View>


									<CText style={{
											fontWeight: isSelected ? '600' : 'normal',
											color: isOtherSelected && !isSelected ? theme.appColors.secondaryText : theme.colors.text
										}}>{option.text}</CText>
								</View>
							</CPressable>
						</View>
					)})}
				</View>
			)
	case 'checkbox':
		let responsesList: string[] = []
		try{
			const tmpResponses = JSON.parse(response ?? '[]')
			if(Array.isArray(tmpResponses)){
				responsesList = tmpResponses
			}
		} catch(e) {
			console.error(e)
		}
		return (
			<View style={{
				gap: 10,
			}}>
				{question.responseEnums!.map((option) => {
					const isSelected = responsesList?.includes(option.value)
					
					return (
					<View key={option.id} style={{
						flex: 1,
					}}>
						<CPressable onPress={() => { 
							if (isSelected) {
								responsesList.splice(responsesList.indexOf(option.value), 1)
							} else {
								responsesList?.push(option.value)
							}
							setResponse(JSON.stringify(responsesList))
							setError(null)
						 }}>
							<View style={{
								flexDirection: 'row',
								alignItems: 'center',	
								gap: 10,							
							}}>
								<View style={{
									justifyContent: 'center',
									alignItems: 'center',
									aspectRatio: 1,
									height: 20,
									width: 20,
									borderRadius: 3,
									borderWidth: 1,
									borderColor: isSelected ? theme.appColors.iosBlue : theme.colors.text,
								}}>
									{isSelected && (
										<View style={{
											height: 12,
											width: 12,
											borderRadius: 1,
											backgroundColor: theme.appColors.iosBlue
										}}/>
									)}
								</View>
								<CText style={{
									fontWeight: isSelected ? '600' : 'normal',
								}}>{option.text}</CText>
							</View>
						</CPressable>
					</View>
				)})}
			</View>
		)

	case 'email':
		return (
			<CTextInput
				keyboardType='email-address'
				textContentType='emailAddress'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text)

					if(!z.string().email().safeParse(text).success) {
						setError(t('no_valid_email'))
					}else {
						setError(null)
					}
				}}
				value={response}/>
		)
	case 'phone':
		return (
			<CTextInput
				keyboardType='phone-pad'
				textContentType='none'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text)

					if(!/^\+[1-9]{1}[0-9]{3,14}$/.test(text)) {
						setError(t('no_valid_phone'))
					}else {
						setError(null)
					}
				}}
				value={response}/>
		)
	case 'idnum':
			return (
				<CTextInput
					keyboardType='default'
					textContentType='none'
					placeholder={question.hint}
					errors={error ? [error] : []}
					onChangeText={(text) => {
						setResponse(text.toUpperCase())
	
						if(!validateSpanishId(text) || (spainIdType(text) !== 'dni' && spainIdType(text) !== 'nie')) {
							setError(t('no_valid_idnum'))
						}else {
							setError(null)
						}
					}}
					value={response}/>
			)
	case 'nifnum':
		return (
			<CTextInput
				keyboardType='default'
				textContentType='none'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text)

					if(!validCIF(text)) {
						setError(t('no_valid_nifnum'))
					}else {
						setError(null)
					}
				}}
				value={response}/>
		)
	case 'idnifnum':
		return (
			<CTextInput
				keyboardType='default'
				textContentType='none'
				placeholder={question.hint}
				errors={error ? [error] : []}
				onChangeText={(text) => {
					setResponse(text)

					if(!validateSpanishId(text)){
						setError(t('no_valid_idnifnum'))
					}else {
						setError(null)
					}
				}}
				value={response}/>
		)
	case 'url':
		return <CText>{question.hint}</CText>
	}
}
