import { AuthStore } from "../stores/auth"

export const fileRequestAPI = (authStore: AuthStore) =>
async (paramsObj = {}, headers = {}) => {
	const params = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`,
			...headers
		},
		...paramsObj
	}
	return params
}

export const downloadFileStorageAPI = (authStore: AuthStore) => 
	async (uri: string, headers = {}) => {
	const fileDetails: {
		filename?: string
		extension?: string
	} = {}
	return fetch(uri, await fileRequestAPI(authStore)({}, headers))
		.then(res => {
			const filename = res.headers.get('filename')
			fileDetails.filename = filename ?? 'file'
			fileDetails.extension = `.${filename?.split('.')?.slice?.(-1)?.[0]}`
			return res.blob()
		})
		.then((blob) => new Promise(resolve => {
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.href = url
			a.download = fileDetails.filename ?? 'file'
			document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click()
			a.remove() // afterwards we remove the element again
			resolve(fileDetails)
		})).catch((err) => {
			console.log('Error downloading file', err)
		})
}
