import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { Alert, FlatList, Platform, View } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import { deleteFormsAPI, getFormsAPI, postFormsAPI } from '../../../api/forms'
import { getSectionsNextFormAPI } from '../../../api/sections'
import { CButton, CDatePicker, CLoadingSpinner, CPressable, CText, CTextInput, formatCurrencyToInput } from '../../../components'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { useResponsive } from '../../../hooks/useResponsive'
import { useCTheme } from '../../../hooks/useTheme'
import { useAuthStore } from '../../../stores/auth'
import { type Question, type FormAPIResponse, FormDraft, Form, QuestionDraft } from '../types'
import dayjs from '../../../helpers/dayjs'
import { ThemeProvider, useNavigation } from '@react-navigation/native'
import z from 'zod'
import { ScrollView } from 'react-native-gesture-handler'
import AntDesign from '@expo/vector-icons/AntDesign'
import { validDNI, validNIE, validCIF, validateSpanishId } from 'spain-id'
import { AeatLoginScreen } from './aeat-login'
import { QuestionComponent } from './question'
import { ResultsScreen } from './results'

interface FormScreenProps {
	hasBackButton: boolean
	sectionId: string
	yearId: string
	yearDraftId: string
	isNextFormLoading: boolean
	formId: string
	width: number
	onNext: () => void
	onBack: () => void
}


export const FormComponent = ({
	form,
	draft,
	hasBackButton,
	buttonsType = 'next',
	sendResponses,
	onBack,
	loading
}: {
	form: FormAPIResponse['form']
	draft: FormAPIResponse['drafts'][0]
	hasBackButton: boolean
	buttonsType: 'next' | 'done' | 'none'
	sendResponses: (responses: Record<string, any>, removeResponses: () => void) => void
	onBack: () => void
	loading: boolean
}) => {

	const { t } = React.useContext(LocalizationContext)
	const theme = useCTheme()
	const { breakpoints } = useResponsive()

	const [responses, setResponses] = useState<Record<string, any> | null>(null)
	const [errors, setErrors] = useState<Record<string, any> | null>(null)
	const [showIsNotSubmittable, setShowIsNotSubmittable] = useState(false)

	const setInitialResponses = () => {
		const draftResponses = draft?.questions ?? {}
		const draftResponsesMap = Object.entries(draftResponses)?.reduce<Record<string, any>>((acc, [, questionDraft]) => {
			acc[questionDraft.questionId] = questionDraft.value
			return acc
		}, {})

		setResponses({
			...(form.questions?.reduce((acc, question) => {
				acc[question.id] = question.defaultValue ?? ''

				if (question.datatype === 'boolean' && acc[question.id] === '') {
					acc[question.id] = '0'
				}
				return acc
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			}, {} as Record<string, any>) ?? {}),
			...((draftResponses ? draftResponsesMap : {}) ?? {})
		})

		setErrors({
			...(form.questions?.reduce((acc, question) => {	
				acc[question.id] = null
				return acc
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			}, {} as Record<string, any>) ?? {})
		})
	}
	useEffect(() => {
		if (form) {
			setInitialResponses()
		}
	}, [form, draft])

	if (!form || !responses || !errors) {
		return <View style={{
			flex: 1,
			maxWidth: breakpoints.md
		}}><CLoadingSpinner/></View>
	}

	const blockedQuestions = Object.entries(responses).reduce((acc, [questionId, userResponse]) => {
		const question = form.questions?.find((question) => question.id === questionId)
		const responseEnum = question?.responseEnums?.find((response) => response.value === userResponse)
		if (responseEnum?.blocksQuestions) {
			acc = acc.concat(responseEnum?.blocksQuestions)
		}
		return acc
	}, [] as string[])

	const neededQuestions = Object.entries(responses).reduce((acc, [questionId, userResponse]) => {
		const question = form.questions?.find((question) => question.id === questionId)
		const responseEnum = question?.responseEnums?.find((response) => response.value === userResponse)
		if (responseEnum?.needsQuestions) {
			acc = acc.concat(responseEnum?.needsQuestions)
		}
		return acc
	}, [] as string[])

	// Función updateResponses: Actualiza las respuestas y limpia las respuestas de las preguntas bloqueadas
	const updateResponses = (updatedResponses: Record<string, any>) => {
		const clearedResponses = { ...updatedResponses }
		blockedQuestions.forEach(questionId => {
			const question = form.questions?.find((question) => question.id === questionId)
			const isNeeded = neededQuestions.includes(questionId)
			if (isNeeded || !question) {
				return
			}
			clearedResponses[questionId] = ''
		})
		setResponses(clearedResponses)
	}

	const checkIsFormSubmittable = () => {
		// Check that all questions have a response and no errors, questions that are in blockedQuestions and not in neededQuestions are not required
		const submitErrors = Object.entries(responses).reduce((acc: {
			error: boolean
			errors: Record<string, any>
		}, [questionId, userResponse]) => {
			const question = form.questions?.find((question) => question.id === questionId)
			if (!question) {
				return {error: true, errors: acc.errors}
			}

			const isNeeded = neededQuestions.includes(questionId)
			const isBlocked = blockedQuestions.includes(questionId)

			if(errors[questionId]) {
				acc.errors[questionId] = errors[questionId]
				return {error: true, errors: acc.errors}
			}

			if (userResponse === '' || userResponse == null) {
				if (!question.required) {
					return {error: acc.error, errors: acc.errors}
				}
				// En este punto es obligatoria y está vacía
				if (isBlocked && !isNeeded) {
					return {error: acc.error, errors: acc.errors}
				}

				if (!question.enabled && !isNeeded) {
					return {error: acc.error, errors: acc.errors}
				}

				acc.errors[questionId] = t('required_field')
				return {error: true, errors: acc.errors}
			}

			return {error: acc.error, errors: acc.errors}
			
		}, {error: false, errors: {}})

		return {
			isSubmittable: !submitErrors.error,
			errors: submitErrors.errors
		}
	}

	const {isSubmittable: isFormSubmittable} = checkIsFormSubmittable()

	return (
		<View style={{
			flex: 1,
		}}>
			<View style={{}}>
				<FlatList
					data={form.questions}
					keyExtractor={(item) => item.id}
					contentContainerStyle={{
						paddingTop: 10,
						paddingBottom: 10,
					}}
					renderItem={({ item }) => {
						const isNeeded = neededQuestions.includes(item.id)
						const isBlocked = blockedQuestions.includes(item.id)

						return (
						<View style={{
							paddingHorizontal: 25,
							marginHorizontal: 20,
							paddingTop: 15,
							paddingBottom: 25,
							borderRadius: 20,
							marginVertical: 10,
							backgroundColor: !responses[item.id] ? theme.colors.card : theme.appColors.cardOff,
							opacity: !responses[item.id] ? 1 : 0.8,
							...(!responses[item.id] ? theme.appShadows.card : {}),
							display : isNeeded ? 'flex' : isBlocked ? 'none' : item.enabled ? 'flex' : 'none',
							marginLeft: isNeeded ? 35 : 20,
						}}>
							<CText style={{
								fontSize: 18,
								fontWeight: '600',
								marginBottom: 10,
								display: item.datatype === 'boolean' ? 'none' : undefined,
							}}>{item.title} <CText style={{
								fontSize: 12,
								fontWeight: '600',
								marginBottom: 10,
								color: item.required ? theme.appColors.alert : theme.appColors.secondaryText
							}} selectable>({item.required ? t('form_question_required') : t('form_question_optional')})</CText></CText>
							{item.description != null && (
								<CText style={{
									fontSize: 15,
									fontWeight: '500',
									marginBottom: 10,
									marginLeft: 5,
									color: theme.appColors.secondaryText
								}}>{item.description}</CText>
							)}
							<QuestionComponent
							question={item} 
							response={responses[item.id]}
							error={errors[item.id]}
							setResponse={(res) => {
								// Asegura que las respuestas se limpien cuando se cambian.
								const updatedResponses = {
									...responses,
									[item.id]: res
								}
								updateResponses(updatedResponses)
							}
						}					
							setError={(err) => {
								setErrors({
									...errors,
									[item.id]: err
								})
							}}
							/>
						</View>
					)}}
				/>

			</View>
			{ showIsNotSubmittable && (
				<View style={{
					marginHorizontal: 30,
					marginTop: 10,
					marginBottom: 10,
					borderRadius: 10,
					padding: 10,
					gap: 10,
					alignItems: 'center',
					backgroundColor: theme.appColors.alert,
					...theme.appShadows.card,
					flexDirection: 'row',
				}}>
					<AntDesign name="warning" size={24} color={theme.appColors.textWithBackground} />
					<CText style={{
						color: theme.appColors.textWithBackground,
						fontSize: 16,
					}}>{t('form_not_submittable')}</CText>
				</View>
			)}
			<View style={{
				flexDirection: 'row',
				marginTop: 20,
				marginHorizontal: 30,
				justifyContent: 'center',
				alignItems: 'flex-end',
				flex: 1,
				marginBottom: 20,
				display: buttonsType === 'none' ? 'none' : 'flex'
			}}>
				<View style={{
					flex: 1
				}}/>
				{ hasBackButton && (<CButton variant='filled' disabled={loading} onPress={() => { onBack() }}>{
					buttonsType === 'next' ? t('previous') : t('cancel')
				}</CButton>)}
				<View style={{
					width: 20
				}}/>
				<CButton variant='filled' color={theme.appColors.primary1} textColor={theme.appColors.textWithBackground} disabled={showIsNotSubmittable || loading} loading={loading} onPress={() => {
					if (!isFormSubmittable) {
						const {errors} = checkIsFormSubmittable()
						setErrors(errors)
						setShowIsNotSubmittable(true)
						return
					}
					sendResponses(responses, () => {
						setInitialResponses()
					})
				}}>{buttonsType === 'next' ? t('next') : t('done')}</CButton>

			</View> 

		</View>
	)
}

export const FormScreen = ({ 
	hasBackButton, 
	sectionId, 
	yearId, 
	yearDraftId, 
	onNext, 
	onBack, 
	isNextFormLoading, 
	formId,
	width, 
	}: FormScreenProps) => {
	const { t } = React.useContext(LocalizationContext)
	const authStore = useAuthStore()
	const { breakpoints } = useResponsive()

	const queryClient = useQueryClient()
	const navigation = useNavigation()

	const theme = useCTheme()

	const [editingFormId, setEditingFormId] = React.useState<string | null | undefined>(undefined)


	const { data: formData, error: formError, isLoading: isFormLoading } = useQuery({
		queryKey: ['form', formId],
		queryFn: async () => await getFormsAPI(authStore)(formId, yearId, yearDraftId),
		enabled: !!formId
	})

	const formMutation = useMutation(
		async (data : {
			finalResponses: Record<string, any> | null
			oldDraftId: string | null
			jumpToNextForm: boolean
			removeResponses?: () => void
		}) => {
			
			const {
				finalResponses,
				oldDraftId,
				jumpToNextForm,
				removeResponses,
			} = data
			const draftsToDelete = formData.drafts?.filter((draft: any) => draft.id === oldDraftId)
			const newDrafts = formData.drafts?.filter((draft: any) => !draftsToDelete.includes(draft))
			await Promise.all(draftsToDelete.map(async (draft: any) => await deleteFormsAPI(authStore)(formData?.form.id, yearId, yearDraftId, draft.id))).catch(() => {})

			if(finalResponses != null){
				const responseDraft = await postFormsAPI(authStore)(formData?.form.id, yearId, yearDraftId, finalResponses)
				newDrafts?.push(responseDraft.draft)
			}

			queryClient.setQueryData(['form', formData?.form.id], {
				...formData,
				drafts: newDrafts
			})

			setEditingFormId(undefined)
			return data
		},
		{
			onSuccess: (data: any) => {
				//data.removeResponses?.()

				if(data.jumpToNextForm) {
					onNext()
				}

			},
			onError: (err: any) => {
				console.log('Errores')
				console.table(err?.errors)
			}
		}
	)

	useEffect(() => {
		if(!formId){
			navigation.navigate('DeclarationNavigation' as any, { 
				screen: 'DeclarationIndexNavigation', 
				params: { 
					yearId, 
					yearDraftId,
					prevSectionId: sectionId
				} })
		}
	}, [formId])

	if(!formId) {
		return null
	}

	const form = formData?.form as Form | undefined
	const drafts = formData?.drafts as FormDraft[]
	let formComponent
	
	if(form?.type === 'aeat-login' || form?.type === 'results'){
		return (
			<View style={{
				width,
				flex: 1,
			}} key={formId}>
				<View style={{
					maxWidth: breakpoints.sm,
					width: '100%',
					alignSelf: 'center',
				}}>
					{form?.type === 'results' && (
						<ResultsScreen yearId={yearId} yearDraftId={yearDraftId} onSend={() => {
							queryClient.invalidateQueries(['years'])
							navigation.navigate('HomeNavigation' as any, {
								screen: 'HomeIndexNavigation',
							})
						}}/>
					)}
					{form?.type === 'aeat-login' && (
						<AeatLoginScreen hasBackButton={hasBackButton} onNext={onNext} onBack={onBack} yearDraftId={yearDraftId} yearId={yearId} />
					)}
				</View>
			</View>
		)
	}

	if (form?.multiple) {
		formComponent = (
			<View style={{
				margin: 10,
				marginTop: 30,
			}}>
				<View style={{
					flex: 1,
					maxWidth: breakpoints.sm,
				}}>
				{editingFormId !== undefined && (
					<FormComponent form={formData?.form} draft={formData?.drafts?.find((draft: FormDraft) => draft?.id === editingFormId)} hasBackButton={true} sendResponses={(responses) => {
						formMutation.mutate({ finalResponses: responses, oldDraftId: editingFormId, jumpToNextForm: false })
					}} onBack={() => {
						setEditingFormId(undefined)
					}} buttonsType={'done'} loading={isFormLoading || isNextFormLoading || formMutation.isLoading}/>
				)}
				{editingFormId === undefined && (
					<>
						<FlatList
							data={drafts}
							keyExtractor={(item) => item.id}
							ListEmptyComponent={() => (
								<View style={{
									padding: 20,
									borderRadius: 10,
									marginHorizontal: 20,
									marginTop: 10,
									backgroundColor: theme.colors.card,
								}}>
									<CText style={{
										fontSize: 20,
										fontWeight: 'bold',
									}}>{t('multiple_form_no_answers')}</CText>
								</View>
							)}
							renderItem={({ item }) => {
								const hasPreview = form?.previewQuestions != null && form?.previewQuestions.length > 0
								let questionsToPreview
								if(hasPreview){
									questionsToPreview = form?.previewQuestions?.map((questionId) => {
										return {draft: item.questions[questionId], question: form.questions?.find((question) => question.id === questionId)}
									}).filter((question) => question.question != null && question.draft != null)
								} else {
									// Solo la primera pregunta
									questionsToPreview = [{
										draft: item.questions[form.questions?.[0].id ?? 0],
										question: form.questions?.[0]
									}].filter((question) => question.question != null && question.draft != null)
								}

								return (
								<View style={{
									backgroundColor: theme.colors.card,
									padding: 20,
									borderRadius: 10,
									marginHorizontal: 20,
									marginTop: 10,
									gap: 10
								}}>
									{ questionsToPreview.map((question) => {
										if(question.question?.datatype === 'currency'){
											return (
												<CText style={{
													fontSize: 20,
													fontWeight: 'bold',
												}} key={question.draft.id}>{formatCurrencyToInput(question.draft.value)} €</CText>
											)
										}
										return (
											<CText style={{
												fontSize: 20,
												fontWeight: 'bold',
											}} key={question.draft.id}>{question.draft.value}</CText>
										)
									})}

									{
										!item.isValid && (
											<View style={{
												backgroundColor: theme.appColors.warning,
												padding: 10,
												borderRadius: 10,
												flexDirection: 'row',
												alignItems: 'center',
												gap: 10
											}}>
												<AntDesign name="exclamationcircle" size={20} color='white' />
												<CText style={{
													color: 'white',
												}}>{t('multiple_form_incomplete')}</CText>
											</View>
										)
									}
									
									<View style={{
										flexDirection: 'row',
										gap: 10
									}}>
										<CButton onPress={() => {
											const action = () => formMutation.mutate({ finalResponses: null, oldDraftId: item.id, jumpToNextForm: false })
											if (Platform.OS === 'web') {
												window.confirm(t('multiple_form_delete_answer_confirmation')) && action()
											} else {
												Alert.alert(t('multiple_form_delete_answer'), t('multiple_form_delete_answer_confirmation'), [{
													text: t('next'),
													onPress: () => {action()}
												}, {
													text: t('cancel'),
													style: 'cancel'
												}])
											}
										}} variant='filled' color={theme.colors.background} textColor={theme.colors.text}>{t('multiple_form_delete_answer')}</CButton>
										<CButton onPress={() => {
											setEditingFormId(item.id)
										}} variant='filled' color={theme.appColors.primary1} textColor={theme.appColors.textWithBackground}>{t('multiple_form_edit_answer')}</CButton> 
									</View>
									
								</View>		
							)}}						
						/>
						<CPressable  onPress={() => {
							setEditingFormId(null)
						}} style={{
							padding: 10,
							borderRadius: 10,
							marginHorizontal: 20,
							backgroundColor: theme.colors.card,
						}}>
							<View style={{
								flexDirection: 'row',
								justifyContent: 'flex-start',
								alignItems: 'center',
							}}>
								<AntDesign name="plus" size={16} color={theme.colors.text} />
								<CText style={{
									marginLeft: 5, 
									fontSize: 16,
									textAlign: 'center',
									fontWeight: '500',
								}}>{t('multiple_form_add_answer')}</CText>
							</View>
						</CPressable>
						<View style={{
							flexDirection: 'row',
							marginTop: 30,
							marginHorizontal: 30,
							justifyContent: 'center',
							alignItems: 'flex-end',
							flex: 1,
							marginBottom: 20

						}}>
							<View style={{
								flex: 1
							}}/>
							{ hasBackButton && (<CButton variant='filled' onPress={() => { onBack() }}>{
								t('previous')
							}</CButton>)}
							<View style={{
								width: 20
							}}/>
							<CButton variant='filled' color={theme.appColors.primary1} textColor={theme.appColors.textWithBackground} onPress={() => {
								if(drafts.length === 0){
									if (Platform.OS === 'web') {
										window.confirm(t('multiple_form_no_answers_message')) && onNext()
									} else {
										Alert.alert(t('multiple_form_no_answers_title'), t('multiple_form_no_answers_message'), [{
											text: t('next'),
											onPress: () => {onNext()}
										}, {
											text: t('cancel'),
											style: 'cancel'
										}])
									}
								} else {
									onNext()
								}
							}} disabled={drafts.some((draft) => !draft.isValid)} loading={isFormLoading || isNextFormLoading || formMutation.isLoading}>
								{t('next')}
							</CButton>

						</View> 
					</>
				)}
				</View>
			</View>
		)
	}else{
		formComponent = (
			<FormComponent form={formData?.form} draft={formData?.drafts?.[0]} hasBackButton={hasBackButton} sendResponses={(responses, removeResponses) => {
				formMutation.mutate({ finalResponses: responses, oldDraftId: formData?.drafts?.[0]?.id, jumpToNextForm: true, removeResponses })
			}} onBack={onBack} buttonsType={'next'} loading={isFormLoading || isNextFormLoading || formMutation.isLoading}/>
		)
	}
	
	return (
		<View style={{
			width,
			flex: 1,
		}}>
			<View style={{
				maxWidth: breakpoints.sm,
				width: '100%',
				alignSelf: 'center',
			}}>
				<CText style={{
					fontSize: 24,
					fontWeight: '600',
					marginTop: 10,
					textAlign: 'center'
				}}>{form?.title}</CText>
				<CText style={{
					fontSize: 16,
					fontWeight: '300',
					marginTop: 10,
					textAlign: 'center',
					marginHorizontal: 20,
					lineHeight: 24
				}}>{form?.description}</CText>
				{formComponent}
			</View>
		</View>
	)
}
