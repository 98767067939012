import { type Theme, useTheme } from '@react-navigation/native'
import { AppColor, AppColors, AppShadows, Shadow, ThemedColors, ThemedShadows } from '../constants/colors'

type CustomTheme = Theme & {
	appColors: ThemedColors
	appShadows: ThemedShadows
}

export const getTheme = (theme: Theme): CustomTheme => {
	const colors = {
		...Object.entries(AppColors).reduce<ThemedColors>(
			(acc, [key, value]: [string, AppColor]) => {
				acc[key] = theme.dark ? value.dark : value.light
				return acc
			},
			{}
		)
	}

	const shadows = {
		...Object.entries(AppShadows).reduce<ThemedShadows>(
			(acc, [key, value]: [string, Shadow]) => {
				acc[key] = theme.dark ? value.dark : value.light
				return acc
			},
			{}
		)
	}

	return {
		dark: theme.dark,
		colors: theme.colors,
		appColors: colors,
		appShadows: shadows
	}
}

export const useCTheme = (): CustomTheme => {
	const theme = useTheme()
	return theme as CustomTheme
}
