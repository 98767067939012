import { useContext, useEffect, useRef, useState } from 'react'
import * as WebBrowser from 'expo-web-browser'
import { CPressable, CText, CTextInput } from '../../components'
import { Alert, Image, Platform, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useResponsive } from '../../hooks/useResponsive'
import { useCTheme } from '../../hooks/useTheme'
import { CLoginButton } from '../../components/'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { useAuthStore } from '../../stores/auth'
import { postAppleLoginAPI, postGoogleLoginAPI, postLoginAPI } from '../../api/auth'
import { showMessage } from 'react-native-flash-message'
import { useGoogleLogin } from '../../services/googleOauth'
import { doAppleLogin } from '../../services/appleOauth'
import { APPLE_OAUTH_CLIENT_ID, APPLE_OAUTH_REDIRECT_URI } from '../../constants/keys'
//import { AppleLoginWeb } from '../../services/appleOauth/index.web'
import * as Linking from 'expo-linking'
import { useRoute } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import React from 'react'

WebBrowser.maybeCompleteAuthSession()

const AuthLogin = () => {
	const { breakpoints } = useResponsive()
	const theme = useCTheme()
	const { t } = useContext(LocalizationContext)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const route = useRoute()
	const emailParam = (route?.params as any)?.email

	useEffect(() => {
		if (emailParam) {
			setEmail(emailParam)
		}
	}, [emailParam])

	const googleLogin = useGoogleLogin(({ res }) => {
		if (!res) {
			showMessage({
				message: t('error'),
				type: 'danger'
			})
			return
		}
		onSocialLogin('google', res)
	})

	const onAppleButtonPress = async () => {
		doAppleLogin().then((data: any) => {
			onSocialLogin('apple', data)
		}).catch((error) => {
			console.log(error)
			showMessage({
				message: t('error'),
				type: 'danger'
			})
		})
	}


	const setAuthTokens = useAuthStore((state) => state.setData)
	const authStore = useAuthStore()

	const loginWithMA = async () => {
		if (!email || !password) {
			showMessage({
				message: t('fill_all_fields'),
				type: 'danger'
			})
			return
		}
		postLoginAPI(authStore)(email, password).then((data: {
			accessToken: string,
			refreshToken: string
		}) => {
			setAuthTokens(data)
		}).catch((err) => {
			showMessage({
				message: err.message,
				type: 'danger'
			})
		})
	}

	const onSocialLogin = (provider: 'apple' | 'google', data?: any) => {
		if (!data) {
			return
		}

		const apiFunc = provider === 'apple' ? postAppleLoginAPI : postGoogleLoginAPI

		apiFunc(authStore)(data.code).then((data) => {
			setAuthTokens(data)
		}).catch((err) => {
			showMessage({
				message: err.message,
				type: 'danger'
			})
		})
	}

	const [isPasswordVisible, setIsPasswordVisible] = useState(false) // para manejar la visibilidad de la contraseña

	return (
		<SafeAreaView
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				flex: 1
			}}
		>
			<View
				style={{
					width: breakpoints.xs,
					maxWidth: '100%',
					paddingHorizontal: 20,
					paddingVertical: 40,
					backgroundColor:
						Platform.OS === 'web' ? theme.colors.card : 'transparent',
					borderRadius: 10,
					...(Platform.OS === 'web' ? theme.appShadows.card : {}),
					alignItems: 'center'
				}}
			>
				<Image
					source={require('../../../assets/icon.png')}
					style={{
						width: 50,
						height: 50,
						marginBottom: 20
					}}
				/>

				<CText
					style={{
						fontSize: 25,
						fontWeight: '800',
						marginBottom: 40
					}}
				>
					{t('login')}
				</CText>

				<CTextInput
					placeholder={t('email')}
					value={email}
					onChangeText={setEmail}
					style={{
					}}
					containerStyle={{
						width: '100%',
						marginBottom: 10,
						paddingHorizontal: 20
					}}
				/>
			<View style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%',
					marginBottom: 10,
					paddingHorizontal: 20,
				}}>
						<CTextInput
							placeholder={t('password')}
							value={password}
							onChangeText={setPassword}
							secureTextEntry={!isPasswordVisible}
							containerStyle={{
								width: '100%',
							}}
						/>
						<CPressable 
							onPress={() => setIsPasswordVisible(!isPasswordVisible)}
							disabled={password === ''}
							style={{ padding: 10, marginLeft: -40 }}
						>
							
							<Ionicons 
								name={isPasswordVisible ? 'eye' : 'eye-off-outline'} 
								size={18} 
								color={password === '' ? 'gray' : theme.colors.text} 
							/>
						</CPressable>
				</View>

				<View style={{
					alignItems: 'flex-end',
					paddingHorizontal: 20,
					width: '100%'
				}}>
					<CPressable
						onPress={() => {
							Linking.openURL('https://mipanel.measesoran.com/site/request-password-reset')
						}}
						style={{
							marginTop: 15,
						}}
					>
						<CText style={{
							fontWeight: '500',
							textDecorationLine: 'underline'
						}}>
							{t('forgot_password')}
						</CText>
					</CPressable>
				</View>

				<View style={{
					alignItems: 'flex-end',
					paddingHorizontal: 20,
					width: '100%'
				}}>
					<CPressable
						onPress={() => {
							Linking.openURL('https://mipanel.measesoran.com/site/renta2023-signup')
						}}
						style={{
							marginTop: 15,
						}}
					>
						<CText style={{
							fontWeight: '500',
							textDecorationLine: 'underline'
						}}>
							{t('create_account')}
						</CText>
					</CPressable>
				</View>

				<View
					style={{
						width: '90%',
						marginTop: 15
					}}
				>
					<CLoginButton
						type="measesoran"
						onPress={() => {
							loginWithMA()
						}}
					>
						{`${t('login_with')} Me Asesoran`}
					</CLoginButton>
				</View>
				<View
					style={{
						width: '90%',
						marginTop: 15,
						flexDirection: 'row',
						alignItems: 'center'
					}}
				>
					<View
						style={{
							flex:1 ,
							height: 1,
							backgroundColor: theme.colors.text
						}}
					/>
					<View
						style={{
							marginHorizontal: 10
						}}
					>
						<CText>
							{t('or')}
						</CText>
					</View>
					<View
						style={{
							flex:1 ,
							height: 1,
							backgroundColor: theme.colors.text
						}}
					/>
				</View>

				<View
					style={{
						width: '90%',
						marginTop: 15
					}}
				>
					<CLoginButton
						type="google"
						onPress={() => {
							googleLogin()
						}}
					>
						{`${t('login_with')} Google`}
					</CLoginButton>
				</View>
				{/* <View
					style={{
						width: '90%',
						marginTop: 15,
						display: Platform.OS === 'web' ? 'none' : 'flex'
					}}
				>
					<CLoginButton
						type="apple"
						onPress={() => {
							onAppleButtonPress()
						}}
					>
						{`${t('login_with')} Apple`}
					</CLoginButton>
				</View>
				<View
					style={{
						width: '90%',
						marginTop: 15,
						display: Platform.OS !== 'web' ? 'none' : 'flex'
					}}
				>
					<AppleLoginWeb
						clientId={APPLE_OAUTH_CLIENT_ID}
						redirectURI={APPLE_OAUTH_REDIRECT_URI}
						usePopup={true}
						onLogin={(res: object) => {
							onSocialLogin('apple', res)
						}}
						scope="name email"
						responseMode="query"
						button={(renderProps: any) => (
							<CLoginButton
								type="apple"
								onPress={renderProps.onClick}
							>
								{`${t('login_with')} Apple`}
							</CLoginButton>
						)}
					/>
				</View>
				*/}

			</View>
		</SafeAreaView>
	)
}

export default AuthLogin
