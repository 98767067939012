import { Text, type TextProps } from 'react-native'
import { useCTheme } from '../../hooks/useTheme'

export const CText = ({ children, style, ...rest }: TextProps) => {
	const theme = useCTheme()
	return (
		<Text {...rest} style={[{ color: theme.colors.text }, style]}>
			{children}
		</Text>
	)
}
