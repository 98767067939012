import { createElement, useMemo } from "react"
import { StyleSheet } from "react-native"
import { useCTheme } from "../../hooks/useTheme"
import { CDatePickerProps } from "./types"
import dayjs from "../../helpers/dayjs"
import { CText } from "../CText"

export const CDatePicker = ({ value, onChange, errors }: CDatePickerProps) => {

	const theme = useCTheme()

	const defaultValue = useMemo(() => {
		return dayjs(value).format('YYYY-MM-DD')
	}, [value])

	const onDateChange = (event: any) => {
		const date = dayjs(event.target.value).format('YYYY-MM-DD')
		onChange(date)
	}
  
	return (
		<>
			<input
				type="date"
				defaultValue={defaultValue}
				onInput={onDateChange}
				style={{
					outline: 'none',
					appearance: 'none',
					border: `1px solid ${errors?.length ? theme.appColors.error : theme.colors.border}`,
					borderRadius: 10,
					paddingHorizontal: 10,
					paddingVertical: 15,
					marginBottom: 10,
					backgroundColor: theme.colors.card,
					color: theme.colors.text,
					height: 40,
				}}
			/>

			{errors && errors.map((error) => (
					<CText key={error} style={{ color: theme.appColors.error }}>{error}</CText>
				))}
		</>
	)
  }
  