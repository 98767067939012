import React from 'react'
import * as Localization from 'expo-localization'
import i18n from '../helpers/i18n'

export interface LocalizationContextType {
	t: (scope: string, options?: object) => string
	locale: string
	setLocale: (locale: string) => void
}

export const LocalizationContext = React.createContext<LocalizationContextType>(
	{
		t: (scope, options) => (i18n as any).t(scope, options),
		locale: Localization.locale,
		setLocale: () => null
	}
)

export const LocalizationProvider = ({ children }: React.PropsWithChildren) => {
	const [locale, setLocale] = React.useState(Localization.locale)
	const localizationContext = React.useMemo(
		() => {
			const t: LocalizationContextType = {
				t: (scope, options) => (i18n as any).t(scope, { locale, ...options }),
				locale,
				setLocale
			}

			return t
		},
		[locale]
	)

	return (
		<LocalizationContext.Provider value={localizationContext}>
			{children}
		</LocalizationContext.Provider>
	)
}
