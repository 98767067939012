import React from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import type { AdminTabScreenProps, DeclarationTabScreenProps } from '../../routes/types'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { CText, CPressable, CLoadingSpinner } from '../../components'
import { useCTheme } from '../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSectionsAPI } from '../../api/sections'
import { useAuthStore } from '../../stores/auth'
import { showMessage } from 'react-native-flash-message'
import { Section, type SectionAPIResponse } from './types'
import { StackActions } from '@react-navigation/native'
import { getYearsXMLAPI } from '../../api/years'
import { getAdminSectionsAPI } from '../../api/admin'

const Admin = ({
	navigation,
	route
}: AdminTabScreenProps<'AdminIndexNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedId, setSelectedId] = React.useState<string | null>(null)
	const theme = useCTheme()

	const yearId = route.params?.yearId
	const queryClient = useQueryClient()

	const authStore = useAuthStore()

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('state', (e) => {
			const state = e.data.state
			if (state.routes[state.index].name !== 'AdminSectionNavigation') {
				setSelectedId(null)
				return
			}
			setSelectedId((state.routes[state.index]?.params as any)?.sectionId ?? null)
		})
		return unsubscribe
	}, [navigation])

	const { data, error, isLoading } = useQuery({
		queryKey: ['admin-sections'],
		queryFn: async () => await getAdminSectionsAPI(authStore)(yearId)
	})

	React.useEffect(() => {
		if (error) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [error])

	if (isLoading) {
		return <CLoadingSpinner/>
	}

	if (error) {
		return null
	}

	const sections: [Section] = data?.sections ?? []

	return (
		<ScrollView>
			<FlatList
			extraData={[theme, selectedId]}
			ListHeaderComponent={() => (
				<View
					style={{
						marginTop: 10
					}}
				/>
			)}
			data={sections}
			keyExtractor={(item) => item.id}
			renderItem={({ item }) => {
				return (
					<View
						style={{
							flexDirection: 'row',
							backgroundColor:
							selectedId === item.id
								? theme.colors.card
								: undefined,
							borderRadius: 10,
							marginHorizontal: 10
						}}
					>
						<CPressable
							onPress={() => {
								//queryClient.removeQueries(['admin-form'])
								navigation.dispatch(StackActions.replace('AdminSectionNavigation', { yearId, sectionId: item.id }))
							}}
							style={{
								padding: 10,
								paddingVertical: 15,
								flex: 1
							}}
						>
							<CText numberOfLines={1} style={{}}>
								{item.name}
							</CText>
						</CPressable>
						<View
							style={{
								flexDirection: 'row',
								alignSelf: 'center',
								justifyContent: 'center',
								marginHorizontal: 10
							}}
						>
							{selectedId === item.id
								? (
									<Ionicons
										name="chevron-forward"
										size={18}
										color={theme.colors.text}
									/>
								)
								: null}
						</View>
					</View>
				)
			}}
		/>

		
	</ScrollView>

	)
}

export default Admin
