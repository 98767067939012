import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'
import { downloadFileStorageAPI } from './files'

export const getReviewFormsAPI = (authStore: AuthStore) =>
	async (formId: string, yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/forms/${formId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const postReviewFormsAPI = (authStore: AuthStore) =>
	async (formId: string, yearId: string, yearDraftId: string, responses: any) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/forms/${formId}/drafts`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				responses
			})
		})
			.then(processResponse)
	}

export const deleteReviewFormsAPI = (authStore: AuthStore) =>
	async (formId: string, yearId: string, yearDraftId: string, draftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/forms/${formId}/drafts/${draftId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

	export const getReviewSectionsAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/sections`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const getReviewSectionsNextFormAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string, sectionId: string, lastFormId?: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/sections/${sectionId}/next-form?${
			(new URLSearchParams(lastFormId ? { lastFormId } : {})).toString()
		}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

	export const getYearsDraftReviewSummaryAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/summary`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`,
			}
		})
			.then(processResponse)
	}

	export const getYearsDraftReviewSummaryCsvAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()


		return downloadFileStorageAPI(authStore)(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/summary`, {
			Accept: 'text/csv'
		})
	}

	export const postReviewStatusAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string, status: 'started') => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/reviews/years/${yearId}/drafts/${yearDraftId}/status?status=${status}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`,
				'Content-Type': 'application/json'
			}
		})
			.then(processResponse)
	}