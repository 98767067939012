import React from 'react'
import {
	StyleSheet,
	type TextInputProps,
	View,
	type ViewStyle
} from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { useCTheme } from '../../hooks/useTheme'
import { CText } from '../CText'

export const CTextInput = React.forwardRef(
	(
		props: TextInputProps & { headerText?: string, containerStyle?: ViewStyle, errors?: string[] },
		ref: any
	) => {
		const { style, headerText, containerStyle, ...rest } = props
		const theme = useCTheme()

		const styles = StyleSheet.create({
			textInput: {
				borderWidth: StyleSheet.hairlineWidth,
				borderColor: theme.colors.border,
				borderRadius: 10,
				paddingHorizontal: 10,
				paddingVertical: 15,
				backgroundColor: theme.colors.card,
				color: theme.colors.text
			},
			textInputHeader: {
				color: theme.colors.text,
				marginBottom: 5,
				fontWeight: '600'
			},
		})

		return (
			<View style={containerStyle}>
				{headerText && (
					<CText style={styles.textInputHeader}>{headerText}</CText>
				)}
				<TextInput
					placeholderTextColor={theme.appColors.placeholderText}
					ref={ref}
					style={[styles.textInput, style, props.errors && props.errors.length > 0 && { borderColor: theme.appColors.error }]}
					{...rest}
				/>

				{props.errors && props.errors.map((error) => (
					<CText key={error} style={{ color: theme.appColors.error, marginTop: 10 }}>{error}</CText>
				))}
			</View>
		)
	}
)

CTextInput.displayName = 'CTextInput'
