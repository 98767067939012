import React, { useContext, useRef, useState } from 'react'
import { View } from 'react-native'
import { type DeclarationTabScreenProps } from '../../../routes/types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { type SectionAPIResponse } from '../types'
import { FormScreen } from './form'
import { FlatList, ScrollView } from 'react-native-gesture-handler'
import { useResponsive } from '../../../hooks/useResponsive'
import { getSectionsNextFormAPI } from '../../../api/sections'
import { useAuthStore } from '../../../stores/auth'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { showMessage } from 'react-native-flash-message'
import { getAdminFormsAPI, postAdminSectionFormAPI } from '../../../api/admin'
import { useCTheme } from '../../../hooks/useTheme'
import { CPressable, CText } from '../../../components'
import Ionicons from '@expo/vector-icons/Ionicons'  

const AdminSection = ({
	route,
	navigation
}: DeclarationTabScreenProps<'DeclarationSectionNavigation'>) => {
	const sectionId = route.params?.sectionId
	const yearId = route.params?.yearId
	const yearDraftId = route.params?.yearDraftId
	const { breakpoints } = useResponsive()
	const paginatorRef = React.useRef<FlatList>(null)
	const [lastFormId, setLastFormId] = React.useState<string | undefined>(undefined)
	const [itemsWidth, setItemsWidth] = useState<number>(breakpoints.sm)
	const theme = useCTheme()
	const [selectedId, setSelectedId] = useState<string | null>(null)

	const queryClient = useQueryClient()

	const authStore = useAuthStore()
	const { t } = useContext(LocalizationContext)

	const { data: formsData, error: formsError, isLoading: formsLoading } = useQuery({
		queryKey: ['admin-section-forms', sectionId],
		queryFn: async () => await getAdminFormsAPI(authStore)(yearId, sectionId),
	})

	React.useEffect(() => {
		if (formsError) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [formsError])




	if (!sectionId || !formsData) {
		return null
	}


	return (
		<View style={{
			flexDirection: 'row',
			flex: 1,
		}}>
			<View style={{
				maxWidth: 300,
				width: '100%',
			}}>
				<FlatList
					extraData={[theme, sectionId]}
					ListHeaderComponent={() => (
						<View
							style={{
								marginTop: 10
							}}
						/>
					)}
					ListFooterComponent={() => (
						<CPressable
							onPress={() => {
								postAdminSectionFormAPI(authStore)(yearId, sectionId).then((res) => {
									if (!res?.newForm) {
										showMessage({
											message: t('error'),
											type: 'danger',
											icon: 'danger'
										})
										return
									}
									queryClient.invalidateQueries(['admin-section-forms', sectionId])
								})
							}}
							style={{
								padding: 10,
								paddingVertical: 15,
								flex: 1,
								marginHorizontal: 10,
								marginVertical: 10,
								flexDirection: 'row',
								backgroundColor: theme.colors.card,
								borderRadius: 10
							}}
						>
							<View
								style={{
									flex: 1,
									flexDirection: 'row',
									alignSelf: 'center',
									justifyContent: 'center'
								}}
							>
								<Ionicons
									name="add"
									size={18}
									color={theme.colors.text}
								/>
								<CText style={{ marginLeft: 10 }}>
									{t('add_form')}
								</CText>
							</View>
						</CPressable>
					)}
					data={formsData?.forms ?? []}
					keyExtractor={(item) => item.id}
					renderItem={({ item }) => {
						return (
							<View
								style={{
									flexDirection: 'row',
									backgroundColor:
										selectedId === item.id
											? theme.colors.card
											: undefined,
									borderRadius: 10,
									marginHorizontal: 10 
								}}
							>
								<CPressable
									onPress={() => {
										setSelectedId(item.id)
									}}
									style={{
										padding: 10,
										paddingVertical: 15,
										flex: 1
									}}
								>
									<CText numberOfLines={1} style={{}}>
										{item.title}
									</CText>
								</CPressable>
								<View
									style={{
										flexDirection: 'row',
										alignSelf: 'center',
										justifyContent: 'center',
										marginHorizontal: 10
									}}
								>
									{selectedId === item.id
										? (
											<Ionicons
												name="chevron-forward"
												size={18}
												color={theme.colors.text}
											/>
										)
										: null}
								</View>
							</View>
						)
					}}
				/>
			</View>
			{(selectedId) && (
				<ScrollView onLayout={
					(event) => {
						setItemsWidth(event.nativeEvent.layout.width)
					}
				} style={{
					flex: 1,
					width: '100%',
					maxWidth: itemsWidth,
					maxHeight: '100%',
				}}>
					<FormScreen
						width={itemsWidth ?? 0}
						sectionId={sectionId}
						yearId={yearId}
						formId={selectedId}
						onDelete={() => {
							queryClient.invalidateQueries(['admin-section-forms', sectionId])
		
							setSelectedId(null)
							
						}}
					/>
				</ScrollView>
			)}
		</View>
	)
}

export default AdminSection
