import { useEffect, useState } from 'react'
import { AccessibilityInfo } from 'react-native'

export const useReduceMotion = () => {
	const [reduceMotionEnabled, setReduceMotionEnabled] = useState(false)

	useEffect(() => {
		const subscription = AccessibilityInfo.addEventListener(
			'reduceMotionChanged',
			setReduceMotionEnabled
		)

		AccessibilityInfo.isReduceMotionEnabled().then(setReduceMotionEnabled).catch(() => {})

		return () => {
			subscription.remove()
		}
	}, [])

	return { reduceMotionEnabled }
}
