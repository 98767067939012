import React from 'react'
import { FlatList, View } from 'react-native'
import type { HomeTabScreenProps } from '../../routes/types'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { CText, CPressable, CLoadingSpinner } from '../../components'
import { useCTheme } from '../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery } from '@tanstack/react-query'
import { getYearsAPI } from '../../api/years'
import { useAuthStore } from '../../stores/auth'
import { type YearAPIResponse } from './types'
import { showMessage } from 'react-native-flash-message'

const Home = ({
	navigation,
	route
}: HomeTabScreenProps<'HomeIndexNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedId, setSelectedId] = React.useState<string | null>(null)
	const theme = useCTheme()

	const authStore = useAuthStore(state => state)

	const { error, data, isLoading } = useQuery({
		queryKey: ['years'],
		queryFn: async () => await getYearsAPI(authStore)()
	})

	const yearsWithDrafts: [YearAPIResponse] = data

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('state', (e) => {
			const state = e.data.state
			if (state.routes[state.index].name !== 'HomeYearNavigation') {
				setSelectedId(null)
				return
			}
			setSelectedId(state.routes[state.index].params?.yearId ?? null)
		})
		return unsubscribe
	}, [navigation])

	React.useEffect(() => {
		if (error) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [error])


	if (isLoading) {
		return <CLoadingSpinner/>
	}

	if (error) {
		return null
	}

	return (
		<FlatList
			extraData={[theme, selectedId]}
			data={yearsWithDrafts}
			keyExtractor={(item) => item.year.id}
			renderItem={({ item }) => {
				return (
					<CPressable
						onPress={() => {
							navigation.navigate('HomeYearNavigation', {
								yearId: item.year.id
							})
						}}
						style={[{
							backgroundColor: theme.appColors.primary1,
							alignItems: 'center',
							justifyContent: 'center',
							alignSelf: 'center',
							width: '95%',
							borderRadius: 10,
							marginVertical: 10,
							...theme.appShadows.card,
							shadowColor: theme.appColors.primary1,
							opacity: selectedId === item.year.id ? 0.75 : 1
						}, selectedId === item.year.id && {
							borderRadius: 0,
							marginVertical: 0,
							paddingVertical: 10,
							width: '100%'
						}]}
					>
						<View
							style={{
								flexDirection: 'row',
								width: '100%',
								marginVertical: 20
							}}
						>
							<View style={{ flex: 1 }}>
								<CText
									numberOfLines={1}
									style={{
										opacity: 0.5,
										width: '80%',
										alignSelf: 'center',
										color: theme.appColors.textWithBackground,
										fontSize: 40,
										fontWeight: '600'
									}}
								>
									{item.year.year}
								</CText>
								{item.draft && (
									<CText
										numberOfLines={1}
										style={{
											opacity: 0.5,
											width: '80%',
											alignSelf: 'center',
											color: theme.appColors.textWithBackground,
											fontSize: 20,
											fontWeight: '500'
										}}
									>
										{t(`declaration_status_${item.draft?.status}`) }
									</CText>
								)}
							</View>
							<View
								style={{
									alignSelf: 'center',
									marginHorizontal: 10
								}}
							>
								{selectedId === item.year.id && (
									<Ionicons
										name="chevron-forward"
										size={24}
										color={theme.appColors.textWithBackground}
									/>
								)}
							</View>
						</View>
					</CPressable>
				)
			}}
		/>
	)
}

export default Home
