import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'

export const getSectionsAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string) => {
		await authStore.refreshTokenIfExpired()
		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/sections`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const getSectionsNextFormAPI = (authStore: AuthStore) =>
	async (yearId: string, yearDraftId: string, sectionId: string, lastFormId?: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/years/${yearId}/drafts/${yearDraftId}/sections/${sectionId}/next-form?${
			(new URLSearchParams(lastFormId ? { lastFormId } : {})).toString()
		}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}
