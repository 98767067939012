import { Platform } from 'react-native'
import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'


export const postLoginAPI = (authStore: AuthStore) =>
	async (email: string, password: string) => {
		return await fetch(`${API_DOMAIN}/auth/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email,
				password
			})
		})
			.then(processResponse)
	}


	export const postGoogleLoginAPI = (authStore: AuthStore) =>
	async (code: string) => {
		return await fetch(`${API_DOMAIN}/auth/login/google`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				code,
				client: Platform.OS	
			})
		})
			.then(processResponse)
	}

	export const postAppleLoginAPI = (authStore: AuthStore) =>
	async (code: string) => {
		return await fetch(`${API_DOMAIN}/auth/login/apple`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				code,
				client: Platform.OS	
			})
		})
			.then(processResponse)
	}
	



export const getAccessTokenAPI = (authStore: AuthStore) =>
	async () => {
		return await fetch(`${API_DOMAIN}/auth/access-token`, {
			method: 'GET',
			headers: {
				'x-refresh-token': authStore.getRefreshToken() ?? '',
				'Content-Type': 'application/json'
			}
		})
			.then(processResponse)
	}

