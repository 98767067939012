import React, { useContext } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { CButton, CText, CWebView } from '../../../components'
import { useResponsive } from '../../../hooks/useResponsive'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { sendEmail } from '../../../helpers/linking'
import { useCTheme } from '../../../hooks/useTheme'

const ProfileHelp = () => {
	const {t} = useContext(LocalizationContext)
	const theme = useCTheme()

	return (
		<View style={{
			maxWidth: 600,
			padding: 20,
			gap: 20,
			alignItems: 'flex-start'
		}}
		>
			<CText style={{
				fontSize: 16,
			}}>
				{t('help_details')}
			</CText>
			<CButton
				variant="filled"
				color={theme.appColors.primary1}
				textColor={theme.appColors.textWithBackground}
				onPress={() => {
					sendEmail('incidenciasrenta@measesoran.com')
				}}
			>{t('contact_us')}
			</CButton>
		</View>
	)
}

export default ProfileHelp
