import { processResponse } from '.'
import { type AuthStore } from '../stores/auth'
import { API_DOMAIN } from './constants'

export const getPlanPurchaseAPI = (authStore: AuthStore) =>
	async (planId: string, yearId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/purchase/${planId}?yearId=${yearId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}

export const checkPlanPurchaseAPI = (authStore: AuthStore) =>
	async (planId: string, yearId: string) => {
		await authStore.refreshTokenIfExpired()

		return await fetch(`${API_DOMAIN}/purchase/check/${planId}?yearId=${yearId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authStore.getAccessToken() ?? ''}`
			}
		})
			.then(processResponse)
	}