import AppleLogin from 'react-apple-login'

export const AppleLoginWeb = ({
	button,
	onLogin,
	clientId,
	redirectURI,
	usePopup,
	scope,
	responseMode

}:{
	button: (renderProps: { onClick: () => void }) => JSX.Element,
	onLogin: (res: object) => void,
	clientId: string,
	redirectURI: string,
	usePopup: boolean,
	scope: string,
	responseMode: string,
}) => {
	return (
		<AppleLogin
			clientId={clientId}
			redirectURI={redirectURI}
			usePopup={usePopup}
			callback={(res) => {
				if (res.error) {
					return
				}
				onLogin(res)
			}}
			scope={scope}
			responseMode={responseMode}
			render={button}
		/>
	)
}

export const doAppleLogin = async () => {
	return null
}
