import * as Linking from 'expo-linking';

export const sendEmail = (emailAddress: string) => {
	const emailAddressUri = `mailto:${emailAddress}`

	try {
		Linking.openURL(emailAddressUri)
	} catch (err) {
		console.error('Cannot open mail', err)
	}
}