import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, Switch, View } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import { deleteFormsAPI, getFormsAPI, postFormsAPI } from '../../../api/forms'
import { getSectionsNextFormAPI } from '../../../api/sections'
import { CButton, CDatePicker, CLoadingSpinner, CPressable, CText, CTextInput } from '../../../components'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { useResponsive } from '../../../hooks/useResponsive'
import { useCTheme } from '../../../hooks/useTheme'
import { useAuthStore } from '../../../stores/auth'
import { type Question, type FormAPIResponse, FormDraft, Form } from '../types'
import dayjs from '../../../helpers/dayjs'
import { StackActions, ThemeProvider, useNavigation } from '@react-navigation/native'
import z from 'zod'
import { ScrollView } from 'react-native-gesture-handler'
import AntDesign from '@expo/vector-icons/AntDesign'
import { deleteAdminFormAPI, getAdminFormAPI, postAdminFormAPI } from '../../../api/admin'
import {v4 as uuidv4} from 'uuid'
import {Picker} from '@react-native-picker/picker';
import EnumSectionLocks from './enum-locks'
import DropDownPicker from 'react-native-dropdown-picker';

const AddQuestionButton = ({onPress, hide = false}: {onPress: () => void, hide: boolean}) => {
	const theme = useCTheme()
	return (
		<CPressable
								style={{
									marginVertical: 5,
									padding: 5,
									borderRadius: 25,
									backgroundColor: theme.colors.card,
									alignSelf: 'center',
									display: hide ? 'none' : 'flex'
								}}
								onPress={onPress}
							>
								<View style={{
									flexDirection: 'row',
									alignItems: 'center',
								}}>
									<AntDesign name="plus" size={16} color={theme.colors.text} />
								</View>
							</CPressable>
	)
							}

interface FormScreenProps {
	sectionId: string
	yearId: string
	formId: string
	width: number
	onDelete: () => void
}

interface QuestionProps {
	question: Question
	updateForm: () => void
	yearId: string
}

const QuestionComponent = ({ question, updateForm, yearId }: QuestionProps) => {
	const theme = useCTheme()
	const {t} = React.useContext(LocalizationContext)
	const [editingLocks, setEditingLocks] = useState<string | null>(null)


	switch (question.datatype) {
	case 'text':
		return null
	case 'number':
		return null
	case 'integer':
		return null
	case 'currency':
		return null
	case 'date':
		return null
	case 'iban':
		return null
	case 'boolean':
		return null
	case 'textarea':
		return null
	case 'radio':
	case 'checkbox':
		return (
			<View style={{
			}}>
				{editingLocks && 
				<EnumSectionLocks 
					yearId={yearId} 
					onHide={() => {
						setEditingLocks(null)
					}}
					neededForms={question.responseEnums!.find((o) => o.id === editingLocks)?.needsForms ?? []}
					neededQuestions={question.responseEnums!.find((o) => o.id === editingLocks)?.needsQuestions ?? []}
					blockedForms={question.responseEnums!.find((o) => o.id === editingLocks)?.blocksForms ?? []}
					blockedQuestions={question.responseEnums!.find((o) => o.id === editingLocks)?.blocksQuestions ?? []}
					addItem={(itemType, logicType, itemId) => {
						if(!question.responseEnums) {
							return
						}
						if(logicType === 'blocks') {
							question.responseEnums.find((o) => o.id === editingLocks)![itemType === 'form' ? 'blocksForms' : 'blocksQuestions'].push(itemId)
						} else {
							question.responseEnums.find((o) => o.id === editingLocks)![itemType === 'form' ? 'needsForms' : 'needsQuestions'].push(itemId)
						}
						updateForm()
					}}

					removeItem={(itemType, logicType, itemId) => {
						if(!question.responseEnums) {
							return
						}
						if(logicType === 'blocks') {
							question.responseEnums.find((o) => o.id === editingLocks)![itemType === 'form' ? 'blocksForms' : 'blocksQuestions'] = question.responseEnums.find((o) => o.id === editingLocks)![itemType === 'form' ? 'blocksForms' : 'blocksQuestions'].filter((o) => o !== itemId)
						} else {
							question.responseEnums.find((o) => o.id === editingLocks)![itemType === 'form' ? 'needsForms' : 'needsQuestions'] = question.responseEnums.find((o) => o.id === editingLocks)![itemType === 'form' ? 'needsForms' : 'needsQuestions'].filter((o) => o !== itemId)
						}
						updateForm()
					}}
					/> 
					}
				
				{question.responseEnums!.map((option) => {
					const blocksOrNeedsCount = (option.blocksQuestions?.length ?? 0) + (option.needsQuestions?.length ?? 0) + (option.blocksForms?.length ?? 0) + (option.needsForms?.length ?? 0)
				return(
					<View key={option.id} style={{
						flex: 1,
						flexDirection: 'row',
						flexWrap: 'wrap',
						alignItems: 'center',
						marginTop: 10,
					}}>
						<CPressable style={{
							height: 20,
							width: 20,
						}} onPress={() => {
							if(!confirm('¿Estás seguro de que quieres eliminar esta opción?')) {
								return
							}
							question.responseEnums = question.responseEnums!.filter((o) => o.id !== option.id)
							updateForm()
						}}>
							<AntDesign name="close" size={18} color={theme.colors.text} />
						</CPressable>

						<CPressable onPress={() => {
					if(!question.responseEnums) {
						return
					}
					const index = question.responseEnums.findIndex((q) => q.id === option.id)
					if(index === 0) {
						return
					}
					const temp = question.responseEnums[index]
					question.responseEnums[index] = question.responseEnums[index - 1]
					question.responseEnums[index - 1] = temp
					updateForm()
				} }>
					<AntDesign name="arrowup" size={24} color={theme.colors.text} />
				</CPressable>
				<CPressable onPress={() => {
					if(!question.responseEnums) {
						return
					}
					const index = question.responseEnums.findIndex((q) => q.id === option.id)
					if(index === question.responseEnums.length - 1) {
						return
					}
					const temp = question.responseEnums[index]
					question.responseEnums[index] = question.responseEnums[index + 1]
					question.responseEnums[index + 1] = temp
					updateForm()
				} }>
					<AntDesign name="arrowdown" size={24} color={theme.colors.text} />
				</CPressable>

						<CTextInput
							style={{
								fontSize: 13,
								fontWeight: '200',
								marginLeft: 5,
								height: 20,
								alignSelf: 'center',
								maxWidth: 100
							}}
							placeholder={'ID AEAT'}
							value={option.xmlId}
							onChangeText={(text) => {
								option.xmlId = text
								updateForm()
							}}
						/>

						<CTextInput
							style={{
								fontSize: 13,
								fontWeight: '200',
								marginLeft: 5,
								height: 20,
								alignSelf: 'center',
								maxWidth: 100
							}}
							placeholder={'Valor XML'}
							value={option.value}
							onChangeText={(text) => {
								option.value = text
								updateForm()
							}}
						/>

						<CTextInput
							style={{
								fontSize: 13,
								fontWeight: '200',
								marginLeft: 5,
								height: 20
							}}
							placeholder={'Texto'}
							value={option.text}
							onChangeText={(text) => {
								option.text = text
								updateForm()
							}}
						/>
						<CPressable style={{marginLeft: 5, alignItems: 'center'}} onPress={() => {
							setEditingLocks(option.id)
						}}>
							<View style={{
								flexDirection: 'row',
							}}>
								<AntDesign name="lock" size={24} color={theme.colors.text} />
								<CText style={{
									minWidth: 10
								}}>{blocksOrNeedsCount || ''}</CText>
							</View>
						</CPressable>
						<CPressable style={{marginLeft: 5, alignItems: 'center'}} onPress={() => {
							option.deadEnd = !option.deadEnd
							updateForm()
						}}>
							<View style={{
								flexDirection: 'row',
							}}>
								<AntDesign name="creditcard" size={24} color={option.deadEnd ? 'tomato' : theme.colors.text} />
							</View>
						</CPressable>
					</View>
				)})}
				<CPressable style={{
					flex: 1,
					marginTop: 10,
					marginLeft: 40,
				}} onPress={() => {
					question.responseEnums!.push({
						id: uuidv4(),
						text: '',
						value: '',
						questionId: question.id,
						deadEnd: false,
						blocksQuestions: [],
						needsQuestions: [],
						blocksForms: [],
						needsForms: [],
					})
					updateForm()
				}}>
					<View style={{
						flexDirection: 'row',
						alignItems: 'center',
					}}>
						<AntDesign name="plus" size={24} color={theme.colors.text} />
						<CText style={{
							marginLeft: 10
						}}>Añadir opción seleccionable</CText>
					</View>
				</CPressable>
			</View>
		)
	case 'email':
		return null
	case 'phone':
		return null
	case 'idnum':
		return null
	case 'nifnum': 
		return null
	case 'idnifnum':
		return null
	case 'url':
		return null
	}
}

const QuestionDetails = ({
	item,
	setForm,
	form
}: {
	item: Question
	setForm: (form: Form) => void
	form: Form
}) => {
	const theme = useCTheme()
	const [isDetailsOpen, setIsDetailsOpen] = useState(false)

	return (
		<View style={{
			paddingTop: 10,
			backgroundColor: theme.colors.card,
			borderRadius: 10,
			paddingHorizontal: 10,
			paddingBottom: 10,
		}}>
			<View style={{
				flexDirection: 'row',
				alignItems: 'center',
				marginBottom: 10,
				gap: 10
			}}>
				<Picker 
						selectedValue={item.datatype}
						style={{
							height: 50, 
							width: 100,
							color: theme.colors.text,
							flex: 1
						}}
						onValueChange={(itemValue, itemIndex) => {
							item.datatype = itemValue

							if ((itemValue === 'radio' || itemValue === 'checkbox') && !item.responseEnums) {
								item.responseEnums = []
							}

							setForm({
								...form
							})
						}}
					>
						<Picker.Item label="Texto" value="text" />
						<Picker.Item label="Area de texto (multilinea)" value="textarea" />
						<Picker.Item label="Número (permite decimales)" value="number" />
						<Picker.Item label="Número (sin decimales)" value="integer" />
						<Picker.Item label="Importe (€)" value="currency" />
						<Picker.Item label="Fecha" value="date" />
						<Picker.Item label="Radio" value="radio" />
						<Picker.Item label="Checkbox" value="checkbox" />
						<Picker.Item label="Booleano" value="boolean" />
						<Picker.Item label="Email" value="email" />
						<Picker.Item label="Teléfono" value="phone" />
						<Picker.Item label="DNI/NIE" value="idnum" />
						<Picker.Item label="NIF" value="nifnum" />
						<Picker.Item label="DNI/NIE o NIF" value="idnifnum" />
						<Picker.Item label="URL" value="url" />
						<Picker.Item label="IBAN" value="iban" />
					</Picker>
					<CPressable onPress={() => {
					if(window.confirm('¿Estás seguro de que quieres eliminar esta pregunta?')){
						if(!form.questions) {
							return
						}
						form.questions = form.questions?.filter((q) => q.id !== item.id)
						setForm({
							...form
						})
					}

				}}>
					<AntDesign name="delete" size={24} color={theme.colors.text} />
				</CPressable>
				<CPressable onPress={() => {
					if(window.confirm('¿Estás seguro de que quieres clonar esta pregunta?')){
						if(!form.questions) {
							return
						}
						const index = form.questions.findIndex((q) => q.id === item.id)
						const newItem = {
							...item,
							responseEnums: item.responseEnums?.map((o) => ({...o, id: uuidv4()})) ?? null,
							id: uuidv4()
						}
						form.questions.splice(index + 1, 0, newItem)
						setForm({
							...form
						})
					}

				}}>
					<AntDesign name="copy1" size={24} color={theme.colors.text} />
				</CPressable>
				<CPressable onPress={() => {
					if(!form.questions) {
						return
					}
					const index = form.questions.findIndex((q) => q.id === item.id)
					if(index === 0) {
						return
					}
					const temp = form.questions[index]
					form.questions[index] = form.questions[index - 1]
					form.questions[index - 1] = temp
					setForm({
						...form
					})
				} }>
					<AntDesign name="arrowup" size={24} color={theme.colors.text} />
				</CPressable>
				<CPressable onPress={() => {
					if(!form.questions) {
						return
					}
					const index = form.questions.findIndex((q) => q.id === item.id)
					if(index === form.questions.length - 1) {
						return
					}
					const temp = form.questions[index]
					form.questions[index] = form.questions[index + 1]
					form.questions[index + 1] = temp
					setForm({
						...form
					})
				} }>
					<AntDesign name="arrowdown" size={24} color={theme.colors.text} />
				</CPressable>
			</View>
			<CTextInput 
					style={{
						fontSize: 18,
						fontWeight: '300',
						marginBottom: 10
					}}
					placeholder={'Pregunta'}
					value={item.title}
					onChangeText={(text) => {
						item.title = text
						setForm({
							...form
						})
					}}
					/>
			<CPressable onPress={() => {
				setIsDetailsOpen(!isDetailsOpen)
			} }>
				<View style={{
					flexDirection: 'row',
					alignItems: 'center',
					gap: 10,
					marginBottom: 10
				}}>
					<AntDesign name={isDetailsOpen ? 'up' : 'down'} size={20} color={theme.colors.text} />
					<CText style={{
						fontSize: 18,
						fontWeight: '300',
					}}>Más opciones</CText>
				</View>
			</CPressable>
			{
				isDetailsOpen &&
				<>
				<CTextInput 
					style={{
						fontSize: 13,
						fontWeight: '200',
						marginBottom: 10,
						marginLeft: 15,
						height: 30
					}}
					placeholder={'Pista (aparece en el campo de entrada cuando está vacío - opcional)'}
					value={item.hint}
					onChangeText={(text) => {
						item.hint = text
						setForm({
							...form
						})
					}}
					/>
					<CTextInput 
					style={{
						fontSize: 13,
						fontWeight: '200',
						marginBottom: 10,
						marginLeft: 15,
						height: 40
					}}
					placeholder={'Descripción (texto explicativo bajo el título - opcional)'}
					value={item.description}
					onChangeText={(text) => {
						item.description = text
						setForm({
							...form
						})
					}}
					/>
					<CTextInput 
					style={{
						fontSize: 13,
						fontWeight: '200',
						marginBottom: 10,
						marginLeft: 15,
						height: 40
					}}
					placeholder={'Valor por defecto (para checkbox/radio poner id - opcional)'}
					value={item.defaultValue}
					onChangeText={(text) => {
						item.defaultValue = text
						setForm({
							...form
						})
					}}
					/>
					<CTextInput 
					style={{
						fontSize: 13,
						fontWeight: '200',
						marginBottom: 10,
						marginLeft: 15,
						height: 40
					}}
					placeholder={'ID AEAT'}
					value={item.xmlId}
					onChangeText={(text) => {
						item.xmlId = text
						setForm({
							...form
						})
					}}
					/>
					<View style={{
						flexDirection: 'row',
						alignItems: 'center',
					}}>
						<Switch
							style={{
								marginTop: 10,
								marginRight: 20
							}}
							value={!item?.enabled}
							onValueChange={(value) => {
								item.enabled = !value
								setForm({
									...form
								})
							}}
						/>
						<CText style={{
							marginTop: 10,
						}}>Ocultar (solo se muestra si otra pregunta la desbloquea)</CText>
						<Switch
							style={{
								marginTop: 10,
								marginHorizontal: 20
							}}
							value={!item?.required}
							onValueChange={(value) => {
								item.required = !value
								setForm({
									...form
								})
							}}
						/>
						<CText style={{
							marginTop: 10,
						}}>Opcional</CText>
					</View>
				</>
			}
			<QuestionComponent 
			question={item}
			yearId={form.yearId}
			updateForm={() => {
				setForm({
					...form
				})
			}}
			/>
		</View>
	)
}		

export const FormPage = ({
	formData,
	width,
	isLoading,
	buttonsType = 'next',
	submitForm,
	deleteForm
}: {
	formData: Form
	width: number
	isLoading: boolean
	buttonsType: 'next' | 'done'
	submitForm: (form: Form) => void
	deleteForm: () => void
}) => {

	const { t } = React.useContext(LocalizationContext)
	const theme = useCTheme()
	const { breakpoints } = useResponsive()
	const [form, setForm] = useState<Form>(formData)
	const [openPreviewSelect, setOpenPreviewSelect] = useState(false);

	useEffect(() => {
		setForm(formData)
	}, [formData])


	if (!form || isLoading) {
		return <View style={{
			width,
			maxWidth: breakpoints.md
		}}><CLoadingSpinner/></View>
	}

	return (
		<View style={{
			width,
		}}>
			<CTextInput 
				style={{
					fontSize: 24,
					fontWeight: '600',
					marginTop: 10,
					marginHorizontal: 20,
					textAlign: 'center'
				}}
				value={form?.title}
				onChangeText={(text) => {
					form.title = text
					setForm({
						...form
					})
				}}
				/>
			<CTextInput 
				style={{
					fontSize: 20,
					fontWeight: '300',
					marginTop: 10,
					textAlign: 'center',
					marginHorizontal: 20
				}}
				value={form?.description}
				onChangeText={(text) => {
					form.description = text
					setForm({
						...form
					})
				}}
				/>
				<View style={{
					marginTop: 10,
					marginHorizontal: 20,
					flexDirection: 'row',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					alignItems: 'center',
				}}>
					<View style={{
						flexDirection: 'row',
						alignItems: 'center',
					}}>
						<Switch
							style={{
								marginTop: 10,
								marginHorizontal: 20,
								alignItems: 'center'
							}}
							value={form?.multiple}
							onValueChange={(value) => {
								form.multiple = value
								setForm({
									...form
								})
							}}
						/>
						<CText>Respuesta múltiple</CText>
					</View>

					<CPressable style={{
						marginTop: 10,
						marginHorizontal: 20,
						alignItems: 'center'
					}} onPress={() => {
						if(!confirm('¿Estás seguro de que quieres eliminar este formulario?')) {
							return
						}

						deleteForm()
					}}>
						<View style={{
							flexDirection: 'row',
							alignItems: 'center',
							gap: 10
						}}>
							<AntDesign name="delete" size={24} color={theme.colors.text} />
							<CText>Borrar formulario</CText>
						</View>
					</CPressable>
				</View>
				<CTextInput
					style={{
						fontSize: 18,
						fontWeight: '300',
						marginTop: 10,
						marginHorizontal: 20,
						textAlign: 'center'
					}}
					value={form.fiscalDataId}
					onChangeText={(text) => {
						form.fiscalDataId = text
						setForm({
							...form
						})
					}}
					placeholder={'ID datos fiscales'}
					/>

				<Picker
					selectedValue={form.type}
					onValueChange={(itemValue, itemIndex) => {
						form.type = itemValue
						setForm({
							...form
						})
					}} 
					style={{
						marginVertical: 10,
						marginHorizontal: 20,
						flex: 1
					}}
				>
					<Picker.Item label="Formulario" value="form" />
					<Picker.Item label="Pantalla de registro AEAT" value="aeat-login" />
					<Picker.Item label="Pantalla de resultados" value="results" />
				</Picker>
				<DropDownPicker
										closeOnBackPressed
										multiple
										open={openPreviewSelect}
										value={form.previewQuestions}
                    items={form.questions?.map((q) => ({label: q.title, value: q.id})) ?? []}
                    setOpen={setOpenPreviewSelect}
                    setValue={(value: any) => {
											setForm((tmpForm: Form) => {
												const newValue = value?.()?.[0];
												if (!newValue) {
													return;
												}
												if (tmpForm.previewQuestions.includes(newValue)) {
													tmpForm.previewQuestions = tmpForm.previewQuestions.filter((q) => q !== newValue);
												} else {
													tmpForm.previewQuestions = tmpForm.previewQuestions.concat(newValue);
												}
												return {
													...tmpForm,
												};
											});
										}}
                    placeholder={'Respuestas en previsualización'}
                />
				<AddQuestionButton hide={form.type !== 'form'} onPress={() => {
								if(!form.questions) {
									form.questions = []
								}
								
								const newQuestionIndex = 0
								form.questions.splice(newQuestionIndex, 0, {
									id: uuidv4(),
									title: '',
									description: '',
									datatype: 'text',
									defaultValue: '',
									hint: '',
									enabled: true,
									required: true,
									responseEnums: [],
									order: form.questions?.length ?? 0
								})

								setForm({
									...form
								})
							}}/>
			<View style={{
				marginTop: 20,
				marginHorizontal: 10,
				display: form.type === 'form' ? 'flex' : 'none'
			}}>
				<FlatList
					data={form.questions}
					keyExtractor={(item) => item.id}
					renderItem={({ item, index }) => (
						<>
							<QuestionDetails 
								item={item}
								setForm={(form) => {
									setForm(form)
								}}
								form={form}
							/>
							<AddQuestionButton onPress={() => {
								if(!form.questions) {
									form.questions = []
								}
								
								const newQuestionIndex = index + 1
								form.questions.splice(newQuestionIndex, 0, {
									id: uuidv4(),
									title: '',
									description: '',
									datatype: 'text',
									defaultValue: '',
									hint: '',
									enabled: true,
									required: true,
									responseEnums: [],
									order: form.questions?.length ?? 0
								})

								setForm({
									...form
								})
							}}/>
						</>
					)}
				/>

			</View>
			<View style={{
				flexDirection: 'row',
				marginTop: 20,
				marginHorizontal: 30,
				justifyContent: 'center',
				alignItems: 'flex-end',
				flex: 1,
				marginBottom: 20,

			}}>
				<View style={{
					flex: 1
				}}/>
				<View style={{
					width: 20
				}}/>
				<CButton disabled={false} onPress={() => {
					submitForm(form)
				}}>{t('save')}</CButton>

			</View>
		</View>
	)
}

export const FormScreen = ({ 
	sectionId, 
	yearId, 
	formId,
	width, 
	onDelete
	}: FormScreenProps) => {
	const { t } = React.useContext(LocalizationContext)
	const authStore = useAuthStore()
	const queryClient = useQueryClient()
	const navigation = useNavigation()

	const { data: formData, error: formError, isLoading: isFormLoading } = useQuery({
		queryKey: ['admin-form', formId],
		queryFn: async () => await getAdminFormAPI(authStore)(yearId, formId),
		enabled: !!formId,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	})

	useEffect(() => {
		if (formError) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}
	, [formError])


	if(isFormLoading) {
		return <CLoadingSpinner/>
	}



	return (
		<View style={{
			width,
		}}>
			<FormPage key={formData?.form.id} formData={formData?.form} width={width} submitForm={(form: Form) => {
				postAdminFormAPI(authStore)(yearId, formId, form).then((res) => {
					showMessage({
						message: t('success'),
						type: 'success',
						icon: 'success'
					})

					queryClient.invalidateQueries(['admin-form', formId])
					queryClient.invalidateQueries(['admin-section-forms', sectionId])

				})
			}}
			deleteForm={() => {
				deleteAdminFormAPI(authStore)(yearId, formId).then((res) => {
					showMessage({
						message: t('success'),
						type: 'success',
						icon: 'success'
					})

					onDelete()
				})
			}}
			isLoading={isFormLoading} buttonsType={'next'}/>
		</View>
	)
}
