
import { useGoogleLogin as useGoogleLoginDefault, GoogleOAuthProvider as DefaultProvider } from '@react-oauth/google'
import { GOOGLE_OAUTH_REDIRECT_URI } from '../../constants/keys'

export const GoogleOAuthProvider = ({
	webClientId,
	children
}: React.PropsWithChildren<{
	webClientId: string
	iosClientId: string
}>) => {
	return (
		<DefaultProvider clientId={webClientId}>
			{children}
		</DefaultProvider>
	)
}

export async function configureGoogleOAuth () {

}

export function useGoogleLogin (callback: ({ res, error }: {res?: object, error?: string}) => void) {
	const login = useGoogleLoginDefault({
		// eslint-disable-next-line n/no-callback-literal
		onSuccess: res => callback({ res }),
		flow: 'auth-code',
		redirect_uri: GOOGLE_OAUTH_REDIRECT_URI
	})

	return login
}
