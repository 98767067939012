import React, { useContext, useEffect, useState } from 'react'
import { Button, FlatList, Modal, ScrollView, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import type { AdminTabScreenProps, DeclarationTabScreenProps } from '../../../routes/types'
import { LocalizationContext } from '../../../contexts/LocalizationContext'
import { CText, CPressable, CLoadingSpinner, CButton } from '../../../components'
import { useCTheme } from '../../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSectionsAPI } from '../../../api/sections'
import { useAuthStore } from '../../../stores/auth'
import { showMessage } from 'react-native-flash-message'
import { Form, Section, type SectionAPIResponse } from '../types'
import { StackActions } from '@react-navigation/native'
import { getYearsXMLAPI } from '../../../api/years'
import { getAdminFormAPI, getAdminFormsAPI, getAdminSectionsAPI, postAdminSectionFormAPI } from '../../../api/admin'
import { useResponsive } from '../../../hooks/useResponsive'
import AntDesign from '@expo/vector-icons/AntDesign'
import { getYearsDraftReviewSummaryAPI } from '../../../api/reviews'

const ResponsesSummary = ({
	yearId,
	yearDraftId,
	onHide,
}: {
	yearId: string
	yearDraftId: string
	onHide: () => void
}) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedSectionId, setSelectedSectionId] = React.useState<string | null>(null)
	const theme = useCTheme()

	const queryClient = useQueryClient()

	const authStore = useAuthStore()

	const { data, error, isLoading } = useQuery({
		queryKey: ['review-summary', yearDraftId],
		queryFn: async () => await getYearsDraftReviewSummaryAPI(authStore)(yearId, yearDraftId),
	})

	React.useEffect(() => {
		if (error) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [error])

	if (isLoading) {
		return <CLoadingSpinner/>
	}

	if (error) {
		return null
	}

	const responses: Array<{
		id: string
		section: string
		forms: Array<{
			id: string
			form: string
			responses: Array<{
				id: string
				questions: Array<{
					id: string
					question: string
					answer: string
					deadEnd: boolean
				}>
			}>
		}>
	}> = data.responses

	return (
		<Modal 
			transparent
			animationType='slide'>
				<View style={[
					StyleSheet.absoluteFill,
					{
						backgroundColor: theme.colors.card,
						opacity: 0.9
					}
				]}/>
			<View style={{
				backgroundColor: theme.colors.background,
				borderRadius: 10,
				margin: 20,
				flex: 1,
			}}>
				<CPressable onPress={onHide} style={{
					alignSelf: 'flex-start',
					padding: 10,
					paddingVertical: 15,
				}}>
					<AntDesign name="close" size={24} color={theme.colors.text} />
				</CPressable>
				<View style={{
					flexDirection: 'row',
					flex: 1,
				}}>
					<ScrollView style={{
						width: 250,
						maxWidth: 250,
					}}>
						<FlatList
						extraData={[theme]}
						ListHeaderComponent={() => (
							<View
								style={{
									marginTop: 10
								}}
							/>
						)}
						data={responses}
						keyExtractor={(item) => item.id}
						renderItem={({ item }) => {
							return (
								<View
									style={{
										flexDirection: 'row',
										borderRadius: 10,
										marginHorizontal: 10
									}}
								>
									<CPressable
										style={{
											padding: 10,
											paddingVertical: 15,
											flex: 1
										}}
										onPress={() => {
											setSelectedSectionId(item.id)
										}}
									>
										<CText numberOfLines={1} style={{}}>
											{item.section}
										</CText>
									</CPressable>
								</View>
							)
						}}
					/>
				</ScrollView>
				{selectedSectionId && (
				<View style={{
					flex: 1,
					borderLeftWidth: 1,
					borderLeftColor: theme.colors.border,
				}}>
					<FlatList
						data={responses.find(response => response.id === selectedSectionId)?.forms}
						keyExtractor={(item) => item.id}
						style={{
							padding: 20,
							flex: 1,
						}}
						renderItem={({ item }) => {
							return (
								<View
									style={{
										borderRadius: 10,
									}}
								>
									<CText style={{
										fontSize: 20,
										marginTop: 10,
										fontWeight: '900',
										textDecorationLine: 'underline',
									}}>
										{item.form}
									</CText>
									<FlatList
										data={item.responses}
										ListEmptyComponent={() => (
											<CText style={{
												fontSize: 16,
												marginTop: 10,
												fontWeight: '300',
												marginLeft: 10
											}}>
												Sin respuestas
											</CText>
										)}
										keyExtractor={(item) => item.id}
										renderItem={({ item }) => {
											return (
												<FlatList
													data={item.questions}
													keyExtractor={(item) => item.id}
													style={{
														backgroundColor: theme.colors.card,
														...theme.appShadows.card,
														padding: 10,
														marginTop: 10,
														borderRadius: 10,
													}}
													renderItem={({ item }) => {
														return (
															<View
																style={{
																	marginHorizontal: 10,
																	flexDirection: 'row',
																}}
															>
																<CText style={{
																	fontSize: 16,
																	marginTop: 10,
																	fontWeight: 'bold',
																}}>
																	{item.question}: <CText style={{
																		fontWeight: 'normal',
																	}}>{item.answer}
																	</CText>
																</CText>
																{ item.deadEnd && (
																	<AntDesign name="warning" size={24} color={theme.appColors.alert} />
																)}
															</View>
														)
													}}
												/>
											)
										}}
									/>
								</View>
							)
						}}
					/>
				</View>
			)}
			</View>
		</View>
	</Modal>
	)
}

export default ResponsesSummary
