import React, { useEffect, useMemo, useRef } from 'react'
import {
	Animated,
	FlatList,
	Platform,
	ScrollView,
	StyleSheet,
	TouchableWithoutFeedback,
	View
} from 'react-native'
import type { PlansTabScreenProps } from '../../routes/types'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { CPressable, CText } from '../../components'
import { useCTheme } from '../../hooks/useTheme'
import { useResponsive } from '../../hooks/useResponsive'
import { LinearGradient } from 'expo-linear-gradient'
import tinycolor from 'tinycolor2'
import Ionicon from '@expo/vector-icons/Ionicons'
import AntDesign from '@expo/vector-icons/AntDesign'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

dayjs.locale('es')

export const PlansData = [
	{
		id: 'basic',
		title: 'Plan AVANZADO',
		description: 'Realiza la declaración de la Renta a través de nuestra avanzada App. Sigue los pasos y recibiremos tu información para que nuestros asesores los revisen, te notifiquen el resultado de tu devolución.',
		price: 29.90,
		period: 'year',
		color: '#59bee6',
		show: true,
		features: [
			{
				text: 'Revisión final de un Asesor Experto'
			},
			{
				text: 'Apoyo de tu Asesor vía Chat'
			},
			{
				text: 'Todas las deducciones incluidas'
			}
		]
	},
	{
		id: 'pro',
		title: 'Plan PREMIUM',
		description: 'Dejalo todo en manos de nuestro equipo de Asesores. Te despreocupas de todo, nosotros te llamamos para solicitar información y hacemos el resto. En pocos días tendrás tu devolución tramitada.',
		price: 49.90,
		period: 'year',
		color: '#77DD77',
		show: true,
		availableFrom: '2024-09-01',
		features: [
			{
				text: 'Un Asesor Experto se encarga de todo'
			},
			{
				text: 'Comunicación telefónica'
			},
			{
				text: 'Perfecto para rentas complejas'
			},
			{
				text: 'Declaraciones para residentes en País Vasco o Navarra'
			}
		]
	},
	{
		id: 'basic-to-pro',
		title: 'Cambiar a Plan PREMIUM',
		description: '',
		price: 24.20,
		period: 'year',
		color: '#77DD77',
		show: false,
		features: [

		]
	},
	/* {
		id: '3',
		title: 'Plan PREMIUM',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		price: 30,
		period: 'year',
		color: '#fbdd56',
		features: [
			{
				text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
			},
			{
				text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
			},
			{
				text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
			},
			{
				text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
			}
		]
	} */
] // TODO: move to a state store for production data

const Plans = ({
	navigation,
	route
}: PlansTabScreenProps<'PlansIndexNavigation'>) => {
	const { t, locale } = React.useContext(LocalizationContext)
	const [listWidth, setListWidth] = React.useState(0)
	const scrollRef = useRef<FlatList>(null)
	const theme = useCTheme()
	const { mainViewWidth, breakpoints } = useResponsive()
	const plans = useMemo(() => {
		return PlansData.filter((item) => item.show)
	}, [])
	const numColumns = Math.min(
		Math.floor((listWidth || mainViewWidth) / 300) || 1,
		plans.length
	)
	const itemWidth = (listWidth || mainViewWidth) / numColumns
	const yearId = route?.params?.yearId

	const decimalFormatter = new Intl.NumberFormat(locale, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

	const scrollX = new Animated.Value(0)
	const position = Animated.divide(scrollX, itemWidth)

	const ContainerComponent = numColumns === 1 ? ScrollView : View

	useEffect(() => {
		if (!yearId) {
			navigation.navigate('HomeNavigation' as any)
		}
	}, [yearId])

	return (
		<ContainerComponent
			style={{
				width: '100%',
				maxWidth: breakpoints.lg,
				alignSelf: 'center'
			}}
		>
			<View style={{
					marginHorizontal: 10,
					marginTop: 20,
					borderRadius: 20,
					padding: 15,
					gap: 10,
					alignItems: 'center',
					backgroundColor: theme.appColors.primary4,
					...theme.appShadows.card,
					flexDirection: 'row',
				}}>
					<AntDesign name="warning" size={24} color={theme.appColors.textWithBackground} />
					<CText style={{
						color: theme.appColors.textWithBackground,
						fontSize: 16,
					}}>{t('plans_warning_basque_country')}</CText>
				</View>
			<FlatList
				ref={scrollRef}
				data={plans}
				extraData={[theme, numColumns]}
				numColumns={numColumns}
				key={numColumns}
				keyExtractor={(item) => item.id.toString()}
				showsHorizontalScrollIndicator={false}
				horizontal={numColumns === 1}
				pagingEnabled={numColumns === 1}
				snapToInterval={itemWidth}
				disableIntervalMomentum={numColumns === 1}
				decelerationRate={0}
				bounces={numColumns !== 1}
				removeClippedSubviews={false}
				initialScrollIndex={0}
				style={{
					flexGrow: numColumns === 1 ? 0 : 1
				}}
				getItemLayout={(data, index) => ({
					length: itemWidth,
					offset: itemWidth * index,
					index
				})}
				contentContainerStyle={{
					flexDirection: numColumns === 1 ? 'row' : 'column',
					alignItems: numColumns === 1 ? 'flex-start' : 'center',
					paddingVertical: 20
				}}
				onLayout={(event) => {
					const { width } = event.nativeEvent.layout
					setListWidth(width)
				}}
				onScroll={Animated.event(
					[{ nativeEvent: { contentOffset: { x: scrollX } } }],
					{ useNativeDriver: false }
				)}
				renderItem={({
					item,
					index
				}: {
					item: (typeof plans)[0]
					index: number
				}) => {
					const itemMargin = numColumns === 1 ? 40 : 10
					const scale = position.interpolate({
						inputRange: [index - 1, index, index + 1],
						outputRange: [0.95, 1, 0.95],
						extrapolate: 'clamp'
					})

					const opacity = position.interpolate({
						inputRange: [index - 1, index, index + 1],
						outputRange: [0.6, 1, 0.6],
						extrapolate: 'clamp'
					})

					const translateX = position.interpolate({
						inputRange: [index - 1, index, index + 1],
						outputRange: [-(itemMargin * 1.8), 0, itemMargin * 1.8],
						extrapolate: 'clamp'
					})

					return (
						<Animated.View
							style={{
								width: itemWidth,
								alignSelf: 'center',
								opacity: numColumns === 1 ? opacity : 1,
								transform: numColumns === 1 ? [{ translateX }, { scale }] : [],
								flexDirection: 'row',
								justifyContent: 'center'
							}}
						>
							<TouchableWithoutFeedback
								style={{
									height: '100%'
								}}
								onPress={() => {
									if (index === 0 || numColumns !== 1) return
									scrollRef.current?.scrollToIndex({
										index: index - 1,
										animated: true
									})
								}}
							>
								<View
									style={{
										width: itemMargin
									}}
								/>
							</TouchableWithoutFeedback>
							<TouchableWithoutFeedback
								onPress={() => {
									if (index === PlansData.length || numColumns !== 1) return
									scrollRef.current?.scrollToIndex({ index, animated: true })
								}}
							>
								<LinearGradient
									colors={[
										item.color,
										tinycolor(item.color).lighten(15).toString()
									]}
									style={[
										{
											borderRadius: 20,
											padding: 10,
											flex: 1,
											marginRight: itemMargin,
											marginTop: 20,
											alignItems: 'center'
										}
									]}
									start={{ x: 1, y: 1 }}
									end={{ x: 0, y: 0 }}
								>
									<CText
										style={{
											fontSize: 18,
											fontWeight: '900',
											textAlign: 'center',
											marginTop: 20,
											color: theme.appColors.unthemedText
										}}
									>
										{item.title}
									</CText>
									<CText
										style={{
											fontSize: 16,
											textAlign: 'center',
											marginTop: 10,
											paddingHorizontal: 5,
											fontWeight: '500',
											color: theme.appColors.unthemedText
										}}
									>
										{item.description}
									</CText>
									<View
										style={{
											backgroundColor: theme.colors.card,
											marginTop: 20,
											marginBottom: 10,
											width: '95%',
											borderRadius: 20,
											alignItems: 'center',
											paddingVertical: 15,
											paddingHorizontal: 20,
											borderWidth: StyleSheet.hairlineWidth,
											borderColor: theme.colors.border,
											gap: 5
										}}
									>
										<CText
											style={{
												color: theme.colors.text,
												fontSize: 30,
												fontWeight: '900',
												fontFamily: Platform.select({
													ios: 'American Typewriter',
													android: 'monospace',
													web: 'monospace'
												})
											}}
										>
											{decimalFormatter.format(item.price)}€ + IVA
										</CText>
										<CText
											style={{
												color: theme.colors.text,
												alignSelf: 'flex-end',
												width: '40%',
												transform: [{ translateY: -5 }],
												fontWeight: '400',
												fontFamily: Platform.select({
													ios: 'American Typewriter',
													android: 'monospace',
													web: 'monospace'
												})
											}}
										>
											/{t(item.period).toLowerCase()}
										</CText>

										{item.features.map((feature, index) => (
											<View
												key={index}
												style={{
													flexDirection: 'row',
													alignItems: 'center',
													marginTop: 10,
													width: '100%'
												}}
											>
												<View
													style={{
														padding: 3,
														backgroundColor: item.color,
														borderRadius: 20,
														aspectRatio: 1,
														marginRight: 10,
														alignSelf: 'flex-start'
													}}
												>
													<Ionicon
														name={'checkmark'}
														size={14}
														color={theme.appColors.unthemedText}
														style={{
															transform: [{ translateY: -1 }],
															fontWeight: '900'
														}}
													/>
												</View>
												<CText
													style={{
														color: theme.colors.text,
														fontSize: 16,
														fontWeight: '400',
														fontFamily: Platform.select({
															ios: 'American Typewriter',
															android: 'monospace',
															web: 'monospace'
														}),
														flex: 1
													}}
												>
													{feature.text}
												</CText>
											</View>
										))}

										{
											item.availableFrom != null && dayjs().isBefore(item.availableFrom) && (
												<CText style={{
													marginTop: 20,
													fontSize: 14,
													fontWeight: '500',
												}}>
													{t('available_from', {date: dayjs(item.availableFrom).format('D [de] MMMM')})}
												</CText>
											)
										}

										<CPressable
											onPress={() => {
												navigation.navigate('PlansCheckoutNavigation', {
													id: item.id,
													yearId: yearId
												})
											}}
											disabled={item.availableFrom != null && dayjs().isBefore(item.availableFrom)}
											style={{
												borderRadius: 20,
												width: '80%',
												marginTop: 30,
												marginBottom: 10,
												...theme.appShadows.card,
												shadowColor: item.color,
												opacity: item.availableFrom != null && dayjs().isBefore(item.availableFrom) ? 0.5 : 1
											}}
										>
											<LinearGradient
												colors={[
													item.color,
													tinycolor(item.color).lighten(15).toString()
												]}
												style={[
													StyleSheet.absoluteFill,
													{
														borderRadius: 20,
													}
												]}
												start={{ x: 1, y: 1 }}
												end={{ x: 1, y: 0 }}
											></LinearGradient>
											<CText
												style={{
													color: theme.appColors.unthemedText,
													fontSize: 16,
													fontWeight: '900',
													paddingVertical: 10,
													paddingHorizontal: 20,
													textAlign: 'center',
													zIndex: 1 // web
												}}
											>
												{t('continue')}
											</CText>
										</CPressable>
									</View>
								</LinearGradient>
							</TouchableWithoutFeedback>
						</Animated.View>
					)
				}}
			/>

			{numColumns === 1 && (
				<View
					style={{
						flexDirection: 'row',
						alignSelf: 'center',
						justifyContent: 'center',
						marginVertical: 10
					}}
				>
					{PlansData.map((item, index) => {
						const opacity = position.interpolate({
							inputRange: [index - 1, index, index + 1],
							outputRange: [0.3, 1, 0.3],
							extrapolate: 'clamp'
						})

						const width = position.interpolate({
							inputRange: [index - 1, index, index + 1],
							outputRange: [7.5, 17.5, 7.5],
							extrapolate: 'clamp'
						})

						return (
							<TouchableWithoutFeedback
								key={item.id}
								onPress={() => {
									scrollRef.current?.scrollToIndex({ index, animated: true })
								}}
							>
								<Animated.View
									style={[
										{
											opacity,
											height: 7.5,
											width,
											backgroundColor: '#595959',
											marginTop: 5,
											marginHorizontal: 5,
											borderRadius: 5
										}
									]}
								/>
							</TouchableWithoutFeedback>
						)
					})}
				</View>
			)}
		</ContainerComponent>
	)
}

export default Plans
