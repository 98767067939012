import AntDesign from '@expo/vector-icons/AntDesign'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import * as FileSystem from 'expo-file-system'

interface FileIcon {
	component: typeof MaterialCommunityIcons | typeof AntDesign
	name: string
	color: string
}

export const getFileIcon = (mimetype?: string): FileIcon => {
	switch (mimetype) {
	case 'application/pdf':
		return { component: AntDesign, name: 'pdffile1', color: 'rgb(241,8,78)' }
	case 'image/jpeg':
	case 'image/png':
		return { component: AntDesign, name: 'picture', color: 'rgb(166,0,246)' }
	case 'application/msword':
	case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		return {
			component: AntDesign,
			name: 'wordfile1',
			color: 'rgb(61,114,203)'
		}
	case 'application/vnd.ms-excel':
	case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
		return {
			component: AntDesign,
			name: 'exclefile1',
			color: 'rgb(65,156,100)'
		}
	case 'application/vnd.ms-powerpoint':
	case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
		return { component: AntDesign, name: 'pptfile1', color: 'rgb(220,113,75)' }
	case 'application/zip':
	case 'application/x-7z-compressed':
	case 'application/x-rar-compressed':
	case 'application/x-tar':
	case 'application/x-gzip':
		return {
			component: MaterialCommunityIcons,
			name: 'zip-box-outline',
			color: 'rgb(187,38,85)'
		}
	case 'text/markdown':
		return {
			component: AntDesign,
			name: 'file-markdown',
			color: 'rgb(149,179,211)'
		}
	default:
		return { component: AntDesign, name: 'file1', color: 'rgb(55,142,190)' }
	}
}

interface MakerDirProps {
	dir: string
	downloadFolderType: 'cache' | 'document'
}

interface DownloadFileProps {
	uri: string
	fileName: string
	downloadFolderName: string
	downloadFolderType: 'cache' | 'document'
	onProgress?: (update: { downloadProgress: number }) => void
	onEnd?: (data: { uri?: string, md5?: string }) => void
	onError?: (error: any) => void
	headers?: object
}

export const makeDir = async ({ dir, downloadFolderType }: MakerDirProps) => {
	try {
		if (downloadFolderType === 'cache') {
			await FileSystem.makeDirectoryAsync(`${FileSystem.cacheDirectory ?? ''}${dir}`, {
				intermediates: true
			})
		} else {
			await FileSystem.makeDirectoryAsync(`${FileSystem.cacheDirectory ?? ''}${dir}`, {
				intermediates: true
			})
		}
	} catch (e) {
		console.log(e)
	}
}

export const downloadFile = async ({
	uri,
	onProgress,
	onEnd,
	onError,
	headers,
	downloadFolderName,
	downloadFolderType,
	fileName
}: DownloadFileProps) => {
	const callback = (downloadProgress: any) => {
		const progress =
			downloadProgress.totalBytesWritten /
			downloadProgress.totalBytesExpectedToWrite
		onProgress?.({
			downloadProgress: progress
		})
	}

	makeDir({ dir: downloadFolderName, downloadFolderType }).catch(() => {})

	const downloadResumable = FileSystem.createDownloadResumable(
		uri,
		`${FileSystem.documentDirectory ?? ''}${fileName}`,
		{
			headers
		},
		callback
	)

	try {
		const res = await downloadResumable.downloadAsync()
		onEnd?.({
			uri: res?.uri,
			md5: res?.md5
		})
	} catch (e) {
		console.log(e)
		onError?.(e)
	}
}
