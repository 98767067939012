import { I18n } from 'i18n-js'
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/es'

import es from '../locales/es.json'

const i18n = new I18n({
	es
}, {
	defaultLocale: 'es',
	enableFallback: true
})

export default i18n
