import { CText } from "../../../components"

export const AeatLoginScreen = ({

}: {
	hasBackButton: boolean,
	onNext: () => void,
	onBack: () => void,
	yearId: string,
	yearDraftId: string,
}) => {

	return (
		<CText>El usuario ha descargado sus datos fiscales de la AEAT</CText>
	)
}