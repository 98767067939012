import React from 'react';
import { StripeProvider as StripeProviderType } from './types';
import {loadStripe} from '@stripe/stripe-js';

export const StripeContext = React.createContext<{
	stripePromise: Promise<any> | null,
}>({
	stripePromise: null,
});

export const StripeProvider = ({
	publishableKey,
	urlScheme,
	merchantIdentifier,
	children,
}: React.PropsWithChildren<StripeProviderType>) => {
	const [promise, setPromise] = React.useState<Promise<any> | null>(null);

	React.useEffect(() => {
		const p = loadStripe(publishableKey);
		setPromise(p);
	}, [publishableKey]);
	return (
		<StripeContext.Provider
			value={{
				stripePromise: promise,
			}}
		>
			{children}
		</StripeContext.Provider>
	);

}

export const useStripePromise = () => {
	const { stripePromise } = React.useContext(StripeContext);
	return stripePromise;
}

export const useStripeCallbackHandler = () => {
}