import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { jwtDecode } from "jwt-decode";
import { getAccessTokenAPI } from '../api/auth';


export interface AuthData {
	accessToken: string | null
	refreshToken: string | null
	user: {
		id: string
		email: string
		roles: Array<'form-editor' | 'draft-reviewer' | 'tester'>
	} | null
}

export interface AuthStore extends AuthData {
	_hasHydrated: boolean
	issuedAt: number | null
	expiresAt: number | null
	loggedIn: boolean
	getAccessToken: () => string | null
	getRefreshToken: () => string | null
	setData: (data: AuthData) => void
	clearData: () => void
	setHasHydrated: (state: boolean) => void
	refreshTokenIfExpired: () => Promise<void>
}

export const useAuthStore = create<AuthStore>()(
	persist(
		(set, get) => ({
			_hasHydrated: false,
			accessToken: null,
			refreshToken: null,
			expiresAt: null,
			issuedAt: null,
			loggedIn: false,
			user: null,
			setData: (data) => {
				if (data.accessToken) {
					const { exp, iat } : { exp: number, iat: number } = jwtDecode(data.accessToken)
					set(() => ({
						...data,
						issuedAt: iat,
						expiresAt: exp,
						user: data.user,
						loggedIn: data.refreshToken !== null,
					}))
				}

				set(() => ({
					...data,
					loggedIn: data.refreshToken !== null
				}))
			},
			setHasHydrated: (state) => {
				set({
				  _hasHydrated: state
				})
			},
			getAccessToken: () => {
				const { accessToken } = get()
				return accessToken
			},
			getRefreshToken: () => {
				const { refreshToken } = get()
				return refreshToken
			},
			clearData: () => {
				set(() => ({
					accessTokens: null,
					expiresIn: null,
					refreshToken: null,
					idToken: null,
					issuedAt: null,
					scope: null,
					state: null,
					tokenType: null,
					loggedIn: false,
					user: null,
				}))
			},
			refreshTokenIfExpired: async () => {
				const user = get().user
				const { refreshToken, expiresAt, issuedAt, setData, clearData } = get()

				if (refreshToken && expiresAt && issuedAt) {
					const now = new Date().getTime()
					const expiresAtMs = expiresAt * 1000
					if (now + 30000 > expiresAtMs) {
						await getAccessTokenAPI(get())().then((data: {
							accessToken: string,
							refreshToken: string
						}) => {
							setData({
								...data,
								user
							})
						}).catch((err) => {
							if (err.code === 'invalid_grant') {
								clearData()
							}
						})
					}
				}
			}

		}),
		{
			name: 'auth',
			storage: createJSONStorage(() => AsyncStorage),
			onRehydrateStorage: () => (state) => {
				state?.setHasHydrated(true)
			},
			partialize: (state) => (
				Object.fromEntries(
					Object.entries(state).filter(([key]) => key !== '_hasHydrated')
				)
			)
		}
	)
)
