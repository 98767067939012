import type React from 'react'
import { useRef } from 'react'
import {
	Platform,
	type PressableProps,
	TouchableOpacity,
	View,
	type ViewProps
} from 'react-native'
import { useHover } from 'react-native-web-hooks'
import { useCTheme } from '../../hooks/useTheme'

export const CPressable = ({
	children,
	style,
	...rest
}: PressableProps & { children: React.ReactNode, href?: string }) => {
	const ref = useRef(null)

	const isHovered = useHover(ref)
	const theme = useCTheme()

	const Pressable = Platform.select({
		ios: TouchableOpacity as any,
		android: TouchableOpacity as any,
		web: TouchableOpacity as any,
		default: TouchableOpacity as any
	})

	return (
		<Pressable
			style={[
				{
					backgroundColor: isHovered
						? theme.appColors.hoverBackground
						: 'transparent'
				},
				style
			]}
			{...(rest as ViewProps)}
			ref={ref}
			activeOpacity={0.75}
		>
			<View
				style={{
					width: '100%',
					justifyContent: 'center'
				}}
			>
				{children}
			</View>
		</Pressable>
	)
}
