import React, { useEffect, useRef } from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import type { DeclarationTabScreenProps } from '../../routes/types'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { CText, CPressable, CLoadingSpinner } from '../../components'
import { useCTheme } from '../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSectionsAPI } from '../../api/sections'
import { useAuthStore } from '../../stores/auth'
import { showMessage } from 'react-native-flash-message'
import { type SectionAPIResponse } from './types'
import { StackActions } from '@react-navigation/native'
import { getYearsXMLAPI } from '../../api/years'
import { usePrevious } from '../../hooks/usePrevious'
import { AntDesign } from '@expo/vector-icons'
import { useResponsive } from '../../hooks/useResponsive'

const Declaration = ({
	navigation,
	route
}: DeclarationTabScreenProps<'DeclarationIndexNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedId, setSelectedId] = React.useState<string | null>(null)
	const theme = useCTheme()
	const prevSectionId = route.params?.prevSectionId
	const lastPrevSectionId = usePrevious(prevSectionId)
	const yearId = route.params?.yearId
	const yearDraftId = route.params?.yearDraftId
	const {isMasterDetailDrawerEnabled} = useResponsive()
	const prevYearDraftId = usePrevious(yearDraftId)

	const queryClient = useQueryClient()

	const authStore = useAuthStore()

	const { data, error, isLoading } = useQuery({
		queryKey: ['sections', yearDraftId],
		queryFn: async () => await getSectionsAPI(authStore)(yearId, yearDraftId)
	})
	const sectionsWithDrafts: [SectionAPIResponse] = data

	React.useEffect(() => {
		if (error) {
			showMessage({
				message: t('error'),
				type: 'danger',
				icon: 'danger'
			})
		}
	}, [error])

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('state', (e) => {
			const state = e.data.state
			if (state.routes[state.index].name === 'DeclarationSectionNavigation' && state.routes[state.index].params?.sectionId) {
				setSelectedId(state.routes[state.index]?.params?.sectionId ?? null)
				return
			}

			if (state.routes[state.index].name === 'DeclarationIndexNavigation') {
				setSelectedId(null)
				return
			}
		})
		return unsubscribe
	}, [navigation, sectionsWithDrafts])

	React.useEffect(() => {
		if(!selectedId && sectionsWithDrafts?.length && isMasterDetailDrawerEnabled) {
			const firstUncompletedSection = sectionsWithDrafts.find((s) => s.draft?.completionPercentage !== 100 || !s.draft)
			if(!firstUncompletedSection) {
				return
			}
			navigation.dispatch(StackActions.replace('DeclarationSectionNavigation', { yearId, yearDraftId, sectionId: firstUncompletedSection.section.id }))
		}
	}, [sectionsWithDrafts])

	React.useEffect(() => {
		if( prevSectionId && lastPrevSectionId !== prevSectionId) {
			queryClient.removeQueries(['next-form'])
			const prevSectionIndex = sectionsWithDrafts?.findIndex((s) => s.section.id === prevSectionId)
			const nextSection = sectionsWithDrafts?.[prevSectionIndex + 1]
			if (!nextSection) {
				return
			}
			navigation.dispatch(StackActions.replace('DeclarationSectionNavigation', { yearId, yearDraftId, sectionId: nextSection.section.id }))
			navigation.setParams({ prevSectionId: undefined })
		}
	}, [prevSectionId])

	React.useEffect(() => {
		if(prevYearDraftId && prevYearDraftId !== yearDraftId) {
			setSelectedId(null)
			queryClient.removeQueries(['sections', prevYearDraftId])
		}
	}, [yearDraftId])


	if (isLoading) {
		return <CLoadingSpinner/>
	}

	if (error) {
		return null
	}


	return (
		<ScrollView>
			<FlatList
			extraData={[theme, selectedId, yearDraftId]}
			ListHeaderComponent={() => (
				<View
					style={{
						marginTop: 30
					}}
				/>
			)}
			contentContainerStyle={{
				gap: 5,
			}}
			data={sectionsWithDrafts}
			keyExtractor={(item) => `${yearDraftId}-${item.section.id}`}
			renderItem={({ item }) => {
				return (
					<View
						style={{
							flexDirection: 'row',
							backgroundColor:
							selectedId === item.section.id
								? theme.appColors.primary1
								: undefined,
							borderRadius: 10,
							paddingHorizontal: selectedId === item.section.id ? 0: 5,
							marginHorizontal: 10,
							overflow: 'hidden',
							opacity: item.enabled ? 1 : 0.4,
						}}
					>
						<View style={{
							position: 'absolute',
							left: 0,
							top: 0,
							bottom: 0,
							width: `${item.draft?.completionPercentage ?? 0}%`,
							backgroundColor: item.draft?.completionPercentage === 100 && selectedId !== item.section.id ? theme.appColors.primary3 : 'rgba(0, 0, 0, 0.1)',
						}}/>
						<CPressable
							disabled={!item.enabled}
							onPress={() => {
								if (selectedId === item.section.id) {
									return
								}
								queryClient.removeQueries(['next-form'])
								navigation.dispatch(StackActions.replace('DeclarationSectionNavigation', { yearId, yearDraftId, sectionId: item.section.id }))
							}}
							style={{
								padding: 10,
								paddingVertical: selectedId === item.section.id ? 20 : 10,
								flex: 1,
							}}
						>
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}>
								<CText numberOfLines={1} style={{
									fontWeight: selectedId === item.section.id ? 'bold' : 'normal',
									color: selectedId === item.section.id || item.draft?.completionPercentage === 100 ? theme.appColors.textWithBackground : theme.appColors.text,
								}}>
									{item.section.name}
								</CText>
								<View
									style={{
										flexDirection: 'row',
										alignSelf: 'center',
										justifyContent: 'center',
										marginHorizontal: 10,
										display: item.draft?.completionPercentage !== 100 ? 'none' : 'flex'
									}}
								>
									<AntDesign
										name="check"
										size={18}
										color={theme.appColors.textWithBackground}
									/>
								</View>
								<View
									style={{
										flexDirection: 'row',
										alignSelf: 'center',
										justifyContent: 'center',
										marginHorizontal: 10,
										display: item.enabled ? 'none' : 'flex'
									}}
								>
									<AntDesign
										name="lock"
										size={18}
										color={theme.colors.text}
									/>
								</View>
								<View
									style={{
										flexDirection: 'row',
										alignSelf: 'center',
										justifyContent: 'center',
										marginHorizontal: 10,
										display: item.enabled && item.draft?.completionPercentage !== 100 ? 'flex' : 'none'
									}}
								>
									<AntDesign
										name="right"
										size={18}
										color={selectedId === item.section.id ? theme.appColors.textWithBackground : theme.colors.text}
									/>
								</View>
							</View>
						</CPressable>
					</View>
				)
			}}
		/>

		{
			authStore.user?.roles.includes('tester') && (
			<>
			<View style={{
				flexDirection: 'row',
				gap: 10,
				marginTop: 10,
				alignSelf: 'center',
			}}>
				<CText style={{
						alignSelf: 'center',
					}}>Declarante:</CText>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'declarante', true, 'aeat')
				}}>
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>XML AEAT</CText>
				</CPressable>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'declarante', true, 'measesoran')
				}}>
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>XML MA</CText>
				</CPressable>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'declarante', false, 'measesoran')
				}}>
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>JSON</CText>
				</CPressable>
			</View>
			<View style={{
				flexDirection: 'row',
				alignItems: 'center',
				gap: 10,
				marginTop: 10,
				alignSelf: 'center',
			}}>
				<CText style={{
						alignSelf: 'center',
					}}>Conyuge:</CText>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conyuge', true, 'aeat')
				}}>
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>XML AEAT</CText>
				</CPressable>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conyuge', true, 'measesoran')
				}}>
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>XML MA</CText>
				</CPressable>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conyuge', false, 'measesoran')
				}}>
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>JSON</CText>
				</CPressable>
			</View>
			<View style={{
				flexDirection: 'row',
				alignItems: 'center',
				gap: 10,
				marginTop: 10,
				alignSelf: 'center',
			}}>
				<CText style={{
					}}>Conjunta:</CText>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conjunta', true, 'aeat')
				}}>
					<CText style={{
						color: 'red',
						textDecorationLine: 'underline'
					}}>XML AEAT</CText>
				</CPressable>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conjunta', true, 'measesoran')
				}}>
					<CText style={{
						color: 'red',
						textDecorationLine: 'underline'
					}}>XML MA</CText>
				</CPressable>
				<CPressable style={{
					alignSelf: 'center',
				}} onPress={() => {
					getYearsXMLAPI(authStore)(yearId, yearDraftId, 'conjunta', false, 'measesoran')
				}}> 
					<CText style={{
						color: 'red',
						alignSelf: 'center',
						textDecorationLine: 'underline'
					}}>JSON</CText>
				</CPressable>
			</View>
		</>
		)}

		
	</ScrollView>

	)
}

export default Declaration
