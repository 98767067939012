import Constants from 'expo-constants'

const extraConfigManifest = Constants.manifest?.extra as Record<string, string> | undefined
const extraConfig = Constants.expoConfig?.extra as Record<string, string> | undefined

const env = extraConfigManifest?.ENVIRONMENT || extraConfig?.ENVIRONMENT || 'development'


export const GOOGLE_OAUTH_CLIENT_ID_IOS = '4997171342-kitup5ddgbt5698j5k3hqhuov6fdppit.apps.googleusercontent.com'
export const GOOGLE_OAUTH_CLIENT_ID_ANDROID = '4997171342-nm88gtvaulcjoohs6t8ugvd2fkgvgj7a.apps.googleusercontent.com'
export const GOOGLE_OAUTH_CLIENT_ID_WEB = '4997171342-nopa60guojuoqvamndc0f6pktmp1koui.apps.googleusercontent.com'
export const APPLE_OAUTH_CLIENT_ID = 'com.measesoran.applesignin'

export const APPLE_OAUTH_REDIRECT_URI = 'https://rentas.measesoran.com'
export const GOOGLE_OAUTH_REDIRECT_URI = 'postmessage'


const PRODUCTION_STRIPE_PUBLISHABLE_KEY = 'pk_live_DsrbE0W52lCXSSomVDbCALtO'
const DEVELOPMENT_STRIPE_PUBLISHABLE_KEY = 'pk_test_mKxSRu7gcWn7nahkOJwWfA8E'

export const STRIPE_PUBLISHABLE_KEY = env === 'production' ? PRODUCTION_STRIPE_PUBLISHABLE_KEY : DEVELOPMENT_STRIPE_PUBLISHABLE_KEY
export const IOS_MERCHANT_ID = 'merchant.com.measesoran.rentas'
