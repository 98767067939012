// This import must be at the top of the file
import 'react-native-gesture-handler'

import { StatusBar } from 'expo-status-bar'
import {
	NavigationContainer,
	DefaultTheme,
	DarkTheme,
	type LinkingOptions,
	type ParamListBase,
	getStateFromPath
} from '@react-navigation/native'
import { MainRoutes, AuthRoutes, linkingConfig } from './src/routes'
import { LocalizationProvider } from './src/contexts/LocalizationContext'
import { useColorScheme } from 'react-native'
import { getTheme } from './src/hooks/useTheme'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import FlashMessage from 'react-native-flash-message'
import { useAuthStore } from './src/stores/auth'
import { shallow } from 'zustand/shallow'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GoogleOAuthProvider } from './src/services/googleOauth'
import { GOOGLE_OAUTH_CLIENT_ID_IOS, GOOGLE_OAUTH_CLIENT_ID_WEB, IOS_MERCHANT_ID, STRIPE_PUBLISHABLE_KEY } from './src/constants/keys'
import { StripeProvider, useStripeCallbackHandler } from './src/services/stripe'
import * as ExpoLinking from 'expo-linking'
import Constants from 'expo-constants'


const queryClient = new QueryClient()

const linking: LinkingOptions<ParamListBase> = {
	// TODO: Add linking for missing screens and handle login
	prefixes: [
		'measesoran://',
		'measesoran-rentas://',
		'https://measesoran.com',
		'https://rentas.measesoran.com'
	],
	config: linkingConfig,
	getStateFromPath: (path, options) => {
		// If initial route is set, also set params to that route
		const state = getStateFromPath(path, options)
		if (state) {
			const routes = state.routes
			const firstRoute = routes[0]
			const firstSubNavigator = firstRoute.state
			if (firstSubNavigator) {
				const firstSubNavigatorRoutes = firstSubNavigator.routes
				const lastRoute = firstSubNavigatorRoutes[
					firstSubNavigatorRoutes.length - 1
				]
				const params = lastRoute.params
				if (params) {
					firstSubNavigator.routes[0] = {
						...firstSubNavigator.routes[0],
						params
					}
				}
			}
		}
		return state
	}

}

export default function App () {
	// TODO: Add Splash Screen (dont show app until _hasHydrated is true)
	const colorScheme = useColorScheme()
	const customTheme = getTheme(
		colorScheme === 'dark' ? DarkTheme : DefaultTheme
	)

	const _hasHydrated = useAuthStore((state) => state._hasHydrated)
	const loggedIn = useAuthStore((state) => state.loggedIn, shallow)
	useStripeCallbackHandler()

	if (!_hasHydrated) {
		return null
	}

	return (
		<SafeAreaProvider>
			<GoogleOAuthProvider webClientId={GOOGLE_OAUTH_CLIENT_ID_WEB} iosClientId={GOOGLE_OAUTH_CLIENT_ID_IOS}>
				<QueryClientProvider client={queryClient}>
					<LocalizationProvider>
					<StripeProvider publishableKey={STRIPE_PUBLISHABLE_KEY} urlScheme={ Constants.appOwnership === 'expo'
						? ExpoLinking.createURL('/--/')
						: ExpoLinking.createURL('')} merchantIdentifier={IOS_MERCHANT_ID}>
						<NavigationContainer linking={linking} theme={customTheme}>
							{loggedIn ? <MainRoutes /> : <AuthRoutes />}
							<StatusBar style="auto" />
							<FlashMessage position="top" />
						</NavigationContainer>
					</StripeProvider>
					</LocalizationProvider>
				</QueryClientProvider>
			</GoogleOAuthProvider>
		</SafeAreaProvider>
	)
}
