import React, { useMemo } from 'react'
import {
	FlatList,
	Linking,
	Platform,
	Share,
	View
} from 'react-native'
import type { ProfileTabScreenProps } from '../../routes/types'
import { LocalizationContext } from '../../contexts/LocalizationContext'
import { CPressable, CText } from '../../components'

import { showMessage } from 'react-native-flash-message'
import { StackActions } from '@react-navigation/native'
import { useCTheme } from '../../hooks/useTheme'
import Ionicons from '@expo/vector-icons/Ionicons'
import * as Clipboard from 'expo-clipboard'
import { revokeAsync } from 'expo-auth-session'
import { useAuthStore } from '../../stores/auth'
import { AUTH_CLIENT_ID, AUTH_ENDPOINT_AUTHORIZATION, AUTH_ENDPOINT_REVOKE, AUTH_ENDPOINT_TOKEN } from '../../api/constants'

const Profile = ({
	navigation
}: ProfileTabScreenProps<'ProfileIndexNavigation'>) => {
	const { t } = React.useContext(LocalizationContext)
	const [selectedNameNavigation, setSelectedNameNavigation] = React.useState<
		string | null
	>(null)
	const theme = useCTheme()
	const refreshToken = useAuthStore((state) => state.refreshToken)
	const clearAuthTokens = useAuthStore((state) => state.clearData)
	const discovery = useMemo(
		() => ({
			authorizationEndpoint: AUTH_ENDPOINT_AUTHORIZATION,
			tokenEndpoint: AUTH_ENDPOINT_TOKEN,
			revocationEndpoint: AUTH_ENDPOINT_REVOKE
		}),
		[]
	)

	const profileOptions = [
	 	{
			title: t('ProfileAccountNavigation'),
			onPress: () => {
				selectedNameNavigation && navigation.goBack()
				navigation.dispatch(StackActions.replace('ProfileAccountNavigation'))
			},
			name: 'ProfileAccountNavigation'
		},
		{
			title: t('ProfileHelpNavigation'),
			onPress: () => {
				selectedNameNavigation && navigation.goBack()
				navigation.dispatch(StackActions.replace('ProfileHelpNavigation'))
			},
			name: 'ProfileHelpNavigation'
		},
		/* {
			title: t('ProfileFeedbackNavigation'),
			onPress: () => {
				if (Platform.OS === 'web') {
					selectedNameNavigation && navigation.goBack()
					navigation.dispatch(
						StackActions.replace('ProfileFeedbackNavigation')
					)
				} else if (Platform.OS === 'android') {
					Linking.openURL(`market://details?id=${''}&showAllReviews=true`).catch(() => {}) // TODO: add id when the app is published
				} else {
					Linking.openURL(
						`itms-apps://itunes.apple.com/app/viewContentsUserReviews/id${'null'}}?action=write-review` // TODO: add id when the app is published
					).catch(() => {})
				}
			},
			name: 'ProfileFeedbackNavigation'
		}, */
		{
			title: t('ProfileShareNavigation'),
			onPress: () => {
				if (Platform.OS === 'web') {
					Clipboard.setStringAsync(t('web_share_text')).then(() => {
						showMessage({
							message: t('copied_to_clipboard'),
							type: 'success',
							icon: 'success'
						})
					}).catch(() => {})
				} else {
					Share.share({
						message: t('web_share_text')
					}).catch(() => {})
				}
			},
			name: 'ProfileShareNavigation'
		},
		{
			title: t('ProfileLogoutNavigation'),
			onPress: () => {
				clearAuthTokens()
			},
			name: 'ProfileLogoutNavigation'
		}
	]

	React.useEffect(() => {
		const unsubscribe = navigation.addListener('state', (e) => {
			const state = e.data.state
			if (
				profileOptions
					.map((po) => po.name)
					.includes(state.routes[state.index].name)
			) {
				setSelectedNameNavigation(state.routes[state.index].name)
			} else {
				setSelectedNameNavigation(null)
			}
		})
		return unsubscribe
	}, [navigation])

	return (
		<FlatList
			extraData={[selectedNameNavigation, theme]}
			data={profileOptions}
			ListHeaderComponent={() => (
				<View
					style={{
						marginTop: 10
					}}
				/>
			)}
			ItemSeparatorComponent={() => (
				<View
					style={{
						height: 1,
						backgroundColor: theme.colors.border,
						marginHorizontal: 10,
						marginVertical: 3
					}}
				/>
			)}
			renderItem={({ item }) => (
				<View
					style={{
						flexDirection: 'row',
						backgroundColor:
							selectedNameNavigation === item.name
								? theme.colors.card
								: undefined,
						borderRadius: 10,
						marginHorizontal: 10
					}}
				>
					<CPressable
						onPress={item.onPress}
						style={{
							padding: 10,
							paddingVertical: 15,
							flex: 1
						}}
					>
						<CText numberOfLines={1} style={{}}>
							{item.title}
						</CText>
					</CPressable>
					<View
						style={{
							alignSelf: 'center',
							marginHorizontal: 10
						}}
					>
						{selectedNameNavigation === item.name && (
							<Ionicons
								name="chevron-forward"
								size={18}
								color={theme.colors.text}
							/>
						)}
					</View>
				</View>
			)}
			keyExtractor={(item) => item.title}
		/>
	)
}

export default Profile
